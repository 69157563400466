import { ParseUtils } from "@bryxinc/lunch";
import { apiFailure, apiSuccess } from "../utils/bryxApi";
import { SCUEvent } from "./scuEvent";
export class SpecificSCUUtils {
    static parseScuServerUpdate(o) {
        try {
            const type = ParseUtils.getString(o, "type");
            switch (type) {
                case "newEvent":
                    return ParseUtils.parseSuccess({
                        key: "newEvent",
                        event: ParseUtils.getSubobject(o, 'event', SCUEvent.parse),
                    });
                case "videoFeedFrame":
                    return ParseUtils.parseSuccess({
                        key: "videoFeedFrame",
                        frame: ParseUtils.getString(o, 'frame'),
                    });
                case "videoFeedStart":
                    return ParseUtils.parseSuccess({
                        key: "videoFeedStop",
                        scuId: ParseUtils.getString(o, 'scuId'),
                    });
                case "videoFeedStop":
                    return ParseUtils.parseSuccess({
                        key: "videoFeedStop",
                        scuId: ParseUtils.getString(o, 'scuId'),
                    });
                default:
                    return ParseUtils.parseFailure(`Invalid ScuServerUpdate type: ${type}`);
            }
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid ScuLogsServerUpdate Model: ${e.message}`);
        }
    }
    static parseScuUpdate(message) {
        try {
            switch (message.key) {
                case "serverUpdate":
                    return apiSuccess({
                        key: "update",
                        update: ParseUtils.getSubobject({ data: message.data }, "data", SpecificSCUUtils.parseScuServerUpdate),
                    });
                case "subscribeResponse":
                    if (message.successful == false) {
                        return apiFailure(message.errorReason, message.errorReason);
                    }
                    return apiSuccess({
                        key: "replace",
                        events: ParseUtils.getArrayOfSubobjects(message.initialData, 'events', SCUEvent.parse, 'warn'),
                    });
                default:
                    return apiSuccess(null);
            }
        }
        catch (e) {
            return apiFailure(null, `Invalid ScuLogsUpdate Model: ${e.message}`);
        }
    }
}
