// import * as i18n from "i18next";
import * as i18n from "i18next";
import * as React from "react";
import { Table } from "semantic-ui-react";
import { SCUEventAlertingCanceled, SCUEventAudioFailure, SCUEventAudioRecorderFailure, SCUEventBackupNetwork, SCUEventBattery, SCUEventBooted, SCUEventDeviceConnectionLost, SCUEventDeviceConnectionRestored, SCUEventDeviceType, SCUEventDispatch, SCUEventDispatchAckType, SCUEventDoorbell, SCUEventGenericError, SCUEventGenericInfo, SCUEventGPIO, SCUEventHueAuthFailure, SCUEventLight, SCUEventLowDiskSpace, SCUEventNetworkDown, SCUEventNetworkUp, SCUEventPrimaryNetwork, SCUEventPrint, SCUEventPrintingFailure, SCUEventTimeOutOfSync, SCUEventTone, SCUEventTts, SCUEventTtsFailure, SCULogLevel, SCUNetworkType, } from "../../models/scuEvent";
import { safelyUnescapeTTS } from "../../utils/functions";
export class ScuEventsTable extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            events: [],
        };
    }
    static translateEvent(event) {
        if (event instanceof SCUEventDispatch) {
            const job = event.job.incidentId || event.job.locationOfIncident;
            switch (event.status) {
                case SCUEventDispatchAckType.ack:
                    return i18n.t('stations.events.acked', { unit: event.unit.name, job: job });
                case SCUEventDispatchAckType.nack:
                    return i18n.t('stations.events.nacked', { unit: event.unit.name, job: job });
                default:
                    return i18n.t('stations.events.ackPending', { unit: event.unit.name, job: job });
            }
        }
        else if (event instanceof SCUEventLight) {
            return i18n.t('stations.events.light', { color: event.description });
        }
        else if (event instanceof SCUEventTone) {
            return i18n.t('stations.events.tone', { tone: event.tone });
        }
        else if (event instanceof SCUEventTts) {
            // it gets escaped twice over the wire, so we actually need to do it twice
            return safelyUnescapeTTS(i18n.t('stations.events.tts', { tts: safelyUnescapeTTS(event.text) }));
        }
        else if (event instanceof SCUEventGPIO) {
            if (event.on) {
                return i18n.t('stations.events.gpioClosed', { device: event.device, relay: event.relay });
            }
            else {
                return i18n.t('stations.events.gpioOpened', { device: event.device, relay: event.relay });
            }
        }
        else if (event instanceof SCUEventPrint) {
            const job = event.job.incidentId || event.job.locationOfIncident;
            return i18n.t('stations.events.printed', { copies: event.copies, job: job, printer: event.printer });
        }
        else if (event instanceof SCUEventGenericInfo) {
            return event.message;
        }
        else if (event instanceof SCUEventBooted) {
            return i18n.t('stations.events.booted');
        }
        else if (event instanceof SCUEventPrimaryNetwork) {
            return i18n.t('stations.events.primaryNetwork');
        }
        else if (event instanceof SCUEventBackupNetwork) {
            return i18n.t('stations.events.backupNetwork');
        }
        else if (event instanceof SCUEventDeviceConnectionRestored) {
            return i18n.t('stations.events.dcr', { device: i18n.t(`stations.devices.${SCUEventDeviceType[event.deviceType]}`) });
        }
        else if (event instanceof SCUEventGenericError) {
            return i18n.t('stations.events.genericError', { message: event.message });
        }
        else if (event instanceof SCUEventAudioFailure) {
            return i18n.t('stations.events.audioFailure', { message: event.message });
        }
        else if (event instanceof SCUEventTimeOutOfSync) {
            return i18n.t('stations.events.timeOutOfSync');
        }
        else if (event instanceof SCUEventHueAuthFailure) {
            return i18n.t('stations.events.hueAuthError', { message: event.message });
        }
        else if (event instanceof SCUEventAudioRecorderFailure) {
            return i18n.t('stations.events.recorderFailure', { message: event.message });
        }
        else if (event instanceof SCUEventTtsFailure) {
            return i18n.t('stations.events.ttsFailure', { message: event.message });
        }
        else if (event instanceof SCUEventLowDiskSpace) {
            return i18n.t('stations.events.lowDiskSpace');
        }
        else if (event instanceof SCUEventPrintingFailure) {
            return i18n.t('stations.events.printingFailure', { printer: event.printer });
        }
        else if (event instanceof SCUEventDeviceConnectionLost) {
            return i18n.t('stations.events.dcl', { device: i18n.t(`stations.devices.${SCUEventDeviceType[event.deviceType]}`) });
        }
        else if (event instanceof SCUEventNetworkUp) {
            return i18n.t('stations.events.networkUp', { networkType: i18n.t(`stations.networkTypes.${SCUNetworkType[event.networkType]}`) });
        }
        else if (event instanceof SCUEventNetworkDown) {
            return i18n.t('stations.events.networkDown', { networkType: i18n.t(`stations.networkTypes.${SCUNetworkType[event.networkType]}`) });
        }
        else if (event instanceof SCUEventAlertingCanceled) {
            return i18n.t('stations.events.alertingCanceled');
        }
        else if (event instanceof SCUEventBattery) {
            return i18n.t('stations.events.batteryLevel', { level: i18n.t(`stations.events.batteryLevels.${SCUNetworkType[event.batteryLevel]}`), charge: event.charge });
        }
        else if (event instanceof SCUEventDoorbell) {
            return i18n.t('stations.events.doorbellPressed', { doorbell: event.doorbell });
        }
        return i18n.t('stations.events.unknown');
    }
    render() {
        const { events } = this.props;
        const rows = events.map(e => {
            return (React.createElement(Table.Row, { key: e.id, className: `event-${SCULogLevel[e.level]}` },
                React.createElement(Table.Cell, null, e.ts.toLocaleString()),
                React.createElement(Table.Cell, null, ScuEventsTable.translateEvent(e))));
        });
        return (React.createElement("div", { style: { paddingTop: "15px", flex: 1, height: "100%", overflowY: "scroll", padding: "5px 1rem" } },
            React.createElement(Table, { compact: true },
                React.createElement(Table.Header, null,
                    React.createElement(Table.Row, null,
                        React.createElement(Table.HeaderCell, { width: 3 }, i18n.t("stations.events.time")),
                        React.createElement(Table.HeaderCell, { width: 13 }, i18n.t("stations.events.event")))),
                rows ? React.createElement(Table.Body, null, rows) : null)));
    }
}
