import 'semantic-ui-css/semantic.min.css';
import i18n from "i18next";
import * as React from 'react';
import * as NotificationSystem from "react-notification-system";
import { Redirect } from "react-router";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Divider, Icon, Loader, Menu, Popup } from "semantic-ui-react";
import { AboutUsModal } from "./components/aboutUsModal";
import { ApparatusSignOutModal } from "./components/apparatusSignoutModal";
import { ContactSupportModal } from "./components/contactSupportModal";
import { DispatchJobModal } from "./components/dispatchJobModal";
import { NotificationMenu } from "./components/notificationMenu";
import { PermissionsModal } from "./components/permissionsModal";
import { ReleaseNotesModal } from "./components/releaseNotesModal";
import { config } from "./config";
import Login from "./login";
import { BryxNotificationType } from "./models/bryxNotification";
import { ThemeUtils } from "./models/theme";
import { BadBrowser } from "./pages/badBrowser";
import { EulaPage } from "./pages/eulaPage";
import { SettingsModal } from "./pages/settings/settingsModal";
import { SCUActionsPage } from "./pages/stations/scuActionsPage";
import { StationsPage } from "./pages/stations/stationsPage";
import { RouterMenuItem } from "./routedItems";
import { BryxApi } from "./utils/bryxApi";
import { BryxColors } from "./utils/bryxColors";
import { BryxLocal } from "./utils/bryxLocal";
import { BryxWebSocket, BryxWebSocketState } from "./utils/bryxWebSocket";
import { DeviceUtils } from "./utils/deviceUtils";
import { NotificationUtils } from "./utils/functions";
import { NotificationManager } from "./utils/notificationManager";
import { PreferenceManager } from "./utils/preferenceManager";
import { ReleaseNotesUtils } from "./utils/releaseNotesUtils";
import { SCUManager } from "./utils/scuManager";
import { SessionManager } from "./utils/sessionManager";
import { UpdateManager } from "./utils/updateManager";
export class Main extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.notificationSystem = null;
        BryxApi.onUnauthenticated = () => {
            config.info("User is being force signed out");
            Main.resetManagers();
            this.setState({
                isLoading: false,
                status: { key: "signedOut", type: "forced" },
            });
        };
        this.state = {
            isLoading: BryxLocal.isSignedIn(),
            status: BryxLocal.isSignedIn() ? { key: "signedIn" } : { key: "signedOut", type: "standard" },
            notificationStatus: NotificationManager.shared.status,
            overlayContent: Main.defaultOverlayContent(),
            settingsTabType: SettingsModal.allTabs()[0],
            webSocketState: BryxWebSocket.shared.state,
            updateStatus: UpdateManager.shared.updateStatus,
            preferences: PreferenceManager.shared.preferences,
            agencies: [],
            dispatcherAgencies: [],
            scuManagerAgencies: [],
            dispatchPreselect: { key: "none" },
            selectedAgencyId: PreferenceManager.shared.preferences.preferredAgencyId,
            agencyRegions: {},
        };
    }
    static defaultOverlayContent() {
        if (PermissionsModal.anyIncompleteStages()) {
            return "permissions";
        }
        else if (ReleaseNotesUtils.getStatus() == "show") {
            return "releaseNotes";
        }
        else {
            return "none";
        }
    }
    componentDidMount() {
        NotificationManager.shared.registerObserver(this);
        BryxWebSocket.shared.registerStateObserver(this);
        UpdateManager.shared.registerObserver(this);
        PreferenceManager.shared.registerObserver(this);
        NotificationUtils.notify = notification => {
            if (this.notificationSystem != null) {
                this.notificationSystem.addNotification(notification);
            }
        };
        this.loadSession();
    }
    componentDidUpdate(prevProps, prevState, prevContext) {
        if (prevState.agencies != this.state.agencies) {
            this.state.agencies.forEach(agency => {
                this.loadAgencyRegion(agency.id);
            });
        }
    }
    componentWillUnmount() {
        NotificationManager.shared.unregisterObserver(this);
        BryxWebSocket.shared.unregisterStateObserver(this);
        UpdateManager.shared.unregisterObserver(this);
        PreferenceManager.shared.unregisterObserver(this);
    }
    loadSession() {
        if (BryxLocal.isSignedIn()) {
            const preSessionTime = Date.now();
            BryxApi.session((result) => {
                const postSessionTime = Date.now();
                // If session fails on a 401, `BryxApi.onUnauthenticated` will be called and will force sign out.
                // Any other errors are non-recoverable.
                if (result.success == true) {
                    config.info("Successfully updated session");
                    if (BryxLocal.getShowEula() != true) {
                        // TODO: Uncomment when displaying jobs
                        // JobManager.shared.startLoadingJobs();
                        SCUManager.shared.startLoadingScus();
                        NotificationManager.shared.startLoadingNotifications();
                        UpdateManager.shared.start();
                        SessionManager.shared.start();
                        setTimeout(() => {
                            const sortedAgencies = result.value.agencies.slice().sort((a, b) => a.name.localeCompare(b.name));
                            this.setState({
                                isLoading: false,
                                status: { key: "signedIn" },
                                agencies: sortedAgencies,
                            });
                            if (sortedAgencies.length == 1) {
                                this.setState({
                                    selectedAgencyId: sortedAgencies[0].id,
                                });
                            }
                            else {
                                // check to see if a preferred agency is set, and if it is that it is still in our auth
                                // payload. if it's not, remove it and reset to a default state to prevent issues
                                const preferredAgencyId = PreferenceManager.shared.preferences.preferredAgencyId;
                                if (preferredAgencyId != null && result.value.agencies.filter(agency => agency.id == preferredAgencyId).length == 0) {
                                    PreferenceManager.shared.updatePreferences({ preferredAgencyId: null });
                                    this.setState({
                                        selectedAgencyId: null,
                                    });
                                }
                            }
                            this.setState({
                                isLoading: false,
                                status: { key: "signedIn" },
                            });
                        }, Math.max(Main.minimumSplashTime - (postSessionTime - preSessionTime), 0));
                    }
                    else {
                        this.props.history.push("/eula", {
                            from: this.props.location,
                        });
                    }
                }
                else {
                    config.warn(`Failed to update session: ${result.debugMessage}`);
                }
            });
        }
    }
    signoutComplete(type) {
        Main.resetManagers();
        this.setState({
            status: { key: "signedOut", type: type },
        });
    }
    openOverlay(key) {
        if (this.state.overlayContent == "none") {
            this.setState({ overlayContent: key });
        }
        else if (this.state.overlayContent != key) {
            // If a popup is already open, we need close the existing one before opening the new one.
            // Otherwise, `onClose` will immediately be called for the new overlay.
            this.setState({ overlayContent: "none" }, () => this.setState({ overlayContent: key }));
        }
    }
    closeOverlay() {
        this.setState({
            overlayContent: Main.defaultOverlayContent(),
            dispatchPreselect: { key: "none" },
        });
    }
    static resetManagers() {
        // TODO: Uncomment when displaying jobs
        // JobManager.shared.reset(false);
        SCUManager.shared.reset();
        NotificationManager.shared.reset();
        BryxLocal.clear();
        PreferenceManager.shared.reset();
        UpdateManager.shared.reset();
        SessionManager.shared.reset();
    }
    signOut() {
        if (BryxLocal.isApparatus()) {
            this.setState({
                overlayContent: "apparatusSignOut",
            });
        }
        else {
            BryxApi.signOut(result => {
                if (result.success == true) {
                    config.info("User successfully signed out");
                    this.signoutComplete("manual");
                }
                else {
                    config.warn(`Failed to sign out: ${result.debugMessage}`);
                }
            });
        }
    }
    onStationDispatch(scuAgencies, scuUnits) {
        const dispatcherAgencyIds = this.state.agencies.filter(a => a.dispatcher).map(a => a.id);
        // Below doesn't handle case in which a SCU has multiple agencies. Will always take the first one
        // because if there's multiple agencies it's hard to determine which location to auto populate
        const agencyId = scuAgencies.length != 0 ? scuAgencies[0] : null;
        this.setState({
            dispatchPreselect: { key: "selected", agencyIds: scuAgencies.filter(a => dispatcherAgencyIds.indexOf(a) > -1), units: scuUnits },
            overlayContent: "dispatch",
            selectedAgencyId: agencyId,
        });
    }
    onChangeCurrentAgency(newAgencyId) {
        this.setState({
            selectedAgencyId: newAgencyId,
        });
        this.closeOverlay();
    }
    loadAgencyRegion(agencyId) {
        const regionData = { city: "", state: "" };
        const temp = this.state.agencyRegions;
        if (agencyId != null) {
            BryxApi.getAgencyRegion(agencyId, result => {
                if (result.success) {
                    regionData.state = result.value.state;
                    regionData.city = result.value.city;
                    temp[agencyId] = regionData;
                    this.setState({ agencyRegions: temp });
                }
                else {
                    console.warn(`Error getting agency region: ${this.state.selectedAgencyId}: ${result.debugMessage}`);
                }
            });
        }
    }
    // NotificationManagerObserver Functions
    notificationManagerDidUpdateStatus(status) {
        this.setState({ notificationStatus: status });
    }
    notificationManagerDidReceiveClick(notification) {
        window.focus();
        switch (notification.content.type) {
            case BryxNotificationType.job:
                this.props.history.push(`/jobs/${notification.content.jobId}`);
                break;
            case BryxNotificationType.supplemental:
                this.props.history.push(`/jobs/${notification.content.jobId}`);
                break;
            case BryxNotificationType.scu:
                this.props.history.push(`/stations/${notification.content.scuId}`);
                break;
            case BryxNotificationType.message:
                // no-op for now
                break;
        }
    }
    // BryxWebSocketStateObserver Functions
    websocketStateDidChange(state) {
        this.setState({ webSocketState: state });
    }
    // UpdateManagerObserver Functions
    updateManagerDidChangeUpdateStatus(updateStatus) {
        this.setState({ updateStatus: updateStatus });
    }
    // PreferenceManagerObserver Functions
    preferencesManagerDidUpdatePreferences(newPrefs) {
        this.setState({
            preferences: newPrefs,
            selectedAgencyId: newPrefs.preferredAgencyId,
        });
    }
    render() {
        const { isLoading, status, agencies, selectedAgencyId, dispatchPreselect } = this.state;
        if (isLoading) {
            return (React.createElement("div", { style: { backgroundColor: BryxColors.brandRed, height: "100%", width: "100%" } },
                React.createElement("img", { id: "loadingIcon", src: "/resources/assets/logo_white.png", className: "centerVertically" })));
        }
        if (status.key == "signedOut") {
            return (React.createElement(Redirect, { to: {
                    pathname: "/login",
                    state: {
                        type: status.type,
                        from: status.type != "manual" ? this.props.location : null,
                    },
                } }));
        }
        let selectedAgency;
        if (agencies.length == 1) {
            selectedAgency = agencies[0];
        }
        else {
            selectedAgency = selectedAgencyId ? agencies.filter(agency => agency.id == selectedAgencyId)[0] : null;
        }
        const rightMenu = (React.createElement(Menu.Menu, { position: "right" },
            this.state.webSocketState == BryxWebSocketState.reconnecting ? (React.createElement(Menu.Item, null,
                React.createElement(Popup, { trigger: React.createElement("div", { style: { display: "flex", flexDirection: "row", alignItems: "center" } },
                        React.createElement(Loader, { active: true, inline: true, inverted: true, size: "small", style: { marginRight: "10px" } }),
                        i18n.t("general.reconnecting")) }, i18n.t("general.reconnectingToBryx")))) : null,
            this.state.selectedAgencyId != null ? (React.createElement(Menu.Item, { onClick: () => this.openOverlay('dispatch') },
                React.createElement(Icon, { size: 'big', name: 'plus', style: { margin: 0 } }))) : null,
            React.createElement(NotificationMenu, { open: this.state.overlayContent == "notifications", onOpen: () => this.openOverlay("notifications"), onClose: this.closeOverlay.bind(this), status: this.state.notificationStatus, onClickSettings: () => this.openOverlay("settings") }),
            React.createElement(Popup, { id: "settingsMenu", on: "click", position: "bottom right", open: this.state.overlayContent == "menu", onOpen: () => this.openOverlay("menu"), onClose: this.closeOverlay.bind(this), trigger: React.createElement(Menu.Item, null,
                    React.createElement(Icon.Group, { className: "link", size: 'big', style: { opacity: this.state.updateStatus.key == "updateAvailable" ? 1 : undefined } },
                        React.createElement(Icon, { name: 'setting', style: { margin: 0 } }),
                        this.state.updateStatus.key == "updateAvailable" ?
                            React.createElement(Icon, { corner: true, name: 'circle', color: "orange", style: { fontSize: "10px" } }) : null)) },
                React.createElement(Popup.Content, null,
                    this.state.updateStatus.key == "updateAvailable" ? (React.createElement("div", null,
                        React.createElement("div", { className: "popupItem", onClick: () => {
                                if (ReleaseNotesUtils.getStatus() == "hide") {
                                    ReleaseNotesUtils.setStatus("show");
                                }
                                UpdateManager.shared.update();
                            }, style: {
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                paddingLeft: "0.97rem !important",
                            } },
                            React.createElement(Icon, { corner: true, name: 'circle', color: "orange" }),
                            React.createElement("span", { style: { fontWeight: "bold" } }, i18n.t("updates.updateAvailable"))),
                        React.createElement(Divider, { style: { margin: 0 } }))) : null,
                    React.createElement("div", { className: "popupItem", onClick: () => this.openOverlay("settings") }, i18n.t("nav.settings.settings")),
                    React.createElement("div", { className: "popupItem", onClick: () => this.openOverlay("contactSupport") }, i18n.t("contactSupport.header")),
                    React.createElement("div", { className: "popupItem", onClick: () => this.openOverlay("about") }, i18n.t("about.settingsItem")),
                    React.createElement("div", { className: "popupItem", onClick: this.signOut.bind(this) }, i18n.t("nav.settings.signOut"))))));
        return (React.createElement("div", { id: "mainRoot", className: ThemeUtils.getClassName(this.state.preferences.theme) },
            React.createElement(Menu, { attached: "top", color: "red", inverted: true, id: "nav-menu" },
                React.createElement(Menu.Item, null,
                    React.createElement("img", { src: "/resources/assets/logo_white.png", style: { width: "26px" } }),
                    " ",
                    React.createElement("span", { className: "siteName" }, i18n.t("branding.siteName"))),
                React.createElement(RouterMenuItem, { to: "/stations" }, i18n.t("nav.stations")),
                selectedAgencyId != null ? (React.createElement(RouterMenuItem, { to: "/actions" }, i18n.t("nav.actions"))) : null,
                agencies.length > 1 ? (React.createElement(Popup, { id: "agenciesMenu", on: "click", position: "bottom center", open: this.state.overlayContent == "agencyMenu", onOpen: () => this.openOverlay("agencyMenu"), onClose: this.closeOverlay.bind(this), trigger: React.createElement(Menu.Item, { style: { margin: "0 auto" } },
                        React.createElement(React.Fragment, null,
                            React.createElement(Icon, { name: "caret down" }),
                            "\u00A0",
                            selectedAgency ? selectedAgency.name : i18n.t("general.allAgencies"))), style: { overflowY: "scroll", bottom: "50%" } },
                    React.createElement(Popup.Content, null,
                        React.createElement("div", { className: "popupItem", onClick: () => this.onChangeCurrentAgency(null) }, i18n.t("general.allAgencies")),
                        this.state.agencies.map(a => (React.createElement("div", { key: a.id, className: "popupItem", onClick: () => this.onChangeCurrentAgency(a.id) }, a.name)))))) : null,
                agencies.length > 1 ? (React.createElement("div", { style: { marginLeft: "0 !important" } }, rightMenu)) : rightMenu),
            React.createElement(ApparatusSignOutModal, { open: this.state.overlayContent == "apparatusSignOut", onSignOut: () => this.signoutComplete("manual"), onCancel: this.closeOverlay.bind(this) }),
            React.createElement(AboutUsModal, { open: this.state.overlayContent == "about", onClose: this.closeOverlay.bind(this), onClickReleaseNotes: () => this.openOverlay("releaseNotes") }),
            React.createElement(ContactSupportModal, { open: this.state.overlayContent == "contactSupport", onClose: this.closeOverlay.bind(this) }),
            React.createElement(SettingsModal, { open: this.state.overlayContent == "settings", currentTab: this.state.settingsTabType, onTabChange: t => this.setState({ settingsTabType: t }), onPasswordChanged: () => this.signoutComplete("passwordChanged"), onClose: this.closeOverlay.bind(this), agencies: this.state.agencies }),
            React.createElement(PermissionsModal, { open: this.state.overlayContent == "permissions", onClose: this.closeOverlay.bind(this) }),
            React.createElement(ReleaseNotesModal, { open: this.state.overlayContent == "releaseNotes", onClose: this.closeOverlay.bind(this) }),
            React.createElement(DispatchJobModal, { open: this.state.overlayContent == "dispatch", onClose: this.closeOverlay.bind(this), preselectStatus: this.state.dispatchPreselect, agencies: this.state.agencies, selectedAgencyId: this.state.selectedAgencyId || (dispatchPreselect.key == "selected" ? dispatchPreselect.agencyIds[0] : 'undefined'), city: this.state.selectedAgencyId != null ? this.state.agencyRegions[this.state.selectedAgencyId]?.city ?? '' : '', state: this.state.selectedAgencyId != null ? this.state.agencyRegions[this.state.selectedAgencyId]?.state ?? '' : '', loadAgencyRegion: this.loadAgencyRegion }),
            React.createElement("div", { id: "contentWrapper" },
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: "/stations/:stationId?", render: (props) => (React.createElement(StationsPage, { ...props, selectedAgencyId: selectedAgencyId, dispatcherAgencies: agencies.filter(a => a.dispatcher), onStationDispatch: this.onStationDispatch.bind(this) })) }),
                    selectedAgencyId != null ? (React.createElement(Route, { exact: true, path: "/actions", render: (props) => (React.createElement(SCUActionsPage, { ...props, selectedAgencyId: selectedAgencyId })) })) : null,
                    React.createElement(Redirect, { to: "/stations" }))),
            React.createElement(NotificationSystem, { ref: (r) => this.notificationSystem = r })));
    }
}
Main.minimumSplashTime = 2.0 * 1000;
export class MainRouter extends React.Component {
    render() {
        console.log(this.props);
        return (React.createElement(BrowserRouter, null,
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/unsupported-browser", component: BadBrowser }),
                React.createElement(Route, { path: "/eula", component: EulaPage }),
                React.createElement(Route, { render: () => {
                        if (!DeviceUtils.deviceIsSupported) {
                            return React.createElement(Redirect, { to: "/unsupported-browser" });
                        }
                        else {
                            return (React.createElement(Switch, null,
                                React.createElement(Route, { path: "/login", component: Login }),
                                React.createElement(Route, { component: Main })));
                        }
                    } }))));
    }
}
window.onerror = (message, filename, lineno, colno, error) => {
    config.error(message, filename, lineno, colno, error);
};
