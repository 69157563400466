import { ParseUtils } from "@bryxinc/lunch";
export class MinimalHospital {
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new MinimalHospital(ParseUtils.getString(o, "id"), ParseUtils.getString(o, "name")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid MinimalHospital Model: ${e.message}`);
        }
    }
    static compare(h1, h2) {
        return h1.name.localeCompare(h2.name) || h1.id.localeCompare(h2.id);
    }
}
export class Hospital extends MinimalHospital {
    constructor(id, name, phone, location) {
        super(id, name);
        this.id = id;
        this.name = name;
        this.phone = phone;
        this.location = location;
    }
    static parse(o) {
        try {
            const minimalHospital = MinimalHospital.parse(o);
            if (minimalHospital.success == false) {
                return ParseUtils.parseFailure(`Invalid Hospital: ${minimalHospital.justification}`);
            }
            const minimal = minimalHospital.value;
            return ParseUtils.parseSuccess(new Hospital(minimal.id, minimal.name, ParseUtils.getString(o, "phone"), o["location"]));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Hospital Model: ${e.message}`);
        }
    }
}
