import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { BryxColors } from "../utils/bryxColors";
export class ZeroStateView extends React.Component {
    render() {
        return (React.createElement("div", { style: { display: "flex", flexDirection: "column", alignItems: "center", maxWidth: this.props.maxWidth, ...this.props.style } },
            this.props.icon != null ? (React.createElement(Icon, { circular: true, size: "big", name: this.props.icon, style: { color: BryxColors.lightGray } })) : null,
            React.createElement("span", { style: {
                    display: "block",
                    color: BryxColors.gray,
                    fontSize: "25px",
                    margin: "15px 10px 12px",
                    textAlign: "center",
                } }, this.props.header),
            this.props.subheader != null ? (React.createElement("span", { style: {
                    display: "block",
                    color: BryxColors.lightGray,
                    fontSize: "16px",
                    margin: "0 10px",
                    textAlign: "center",
                } }, this.props.subheader)) : null));
    }
}
