import { GeoJSON } from "leaflet";
import * as React from 'react';
import { LayerGroup, Marker, Polygon as LeafletPolygon, Polyline } from "react-leaflet";
import { BryxMap } from "./bryxMap";
export class BryxGeoJSONLayer extends React.Component {
    render() {
        function geoJSONToLayer(geojson, icon) {
            switch (geojson.type) {
                case 'Point':
                    const point = geojson;
                    return (React.createElement(Marker, { key: `Point+${point.coordinates}`, position: GeoJSON.coordsToLatLng(point.coordinates), icon: icon || BryxMap.genericIcon }));
                case 'MultiPoint':
                    const multipoint = geojson;
                    return (React.createElement(LayerGroup, { key: `MultiPoint+${multipoint.coordinates}` }, multipoint.coordinates.map((c) => {
                        return (React.createElement(Marker, { key: c.toString(), position: GeoJSON.coordsToLatLng(c), icon: icon || BryxMap.genericIcon }));
                    })));
                case 'LineString':
                    const linestring = geojson;
                    return (React.createElement(Polyline, { key: `LineString+${linestring.coordinates}`, positions: GeoJSON.coordsToLatLngs(linestring.coordinates, 0, GeoJSON.coordsToLatLng) }));
                case 'MultiLineString':
                    const multilinestring = geojson;
                    return (React.createElement(Polyline, { key: `MultiLineString+${multilinestring.coordinates.toString()}`, positions: GeoJSON.coordsToLatLngs(multilinestring.coordinates, 1, GeoJSON.coordsToLatLng) }));
                case 'Polygon':
                    const polygon = geojson;
                    return (React.createElement(LeafletPolygon, { key: `Polygon+${polygon.coordinates}`, positions: GeoJSON.coordsToLatLngs(polygon.coordinates, 1, GeoJSON.coordsToLatLng) }));
                case 'MultiPolygon':
                    const multipolygon = geojson;
                    return (React.createElement(LeafletPolygon, { key: `MultiPolygon+${multipolygon.coordinates.toString()}`, positions: GeoJSON.coordsToLatLngs(multipolygon.coordinates, 2, GeoJSON.coordsToLatLng) }));
                case 'GeometryCollection':
                    const geometrycollection = geojson;
                    return (React.createElement(LayerGroup, null, geometrycollection.geometries.map((g) => {
                        return geoJSONToLayer({
                            geometry: g,
                            type: 'Feature',
                            properties: null,
                        }, icon);
                    }).filter(Boolean)));
                case 'Feature':
                    const feature = geojson;
                    return geoJSONToLayer(feature.geometry, icon);
                case 'FeatureCollection':
                    const featurecollection = geojson;
                    return (React.createElement(LayerGroup, null, featurecollection.features.map((f) => {
                        return geoJSONToLayer(f, icon);
                    }).filter(Boolean)));
                default:
                    return null;
            }
        }
        return (React.createElement(LayerGroup, null, geoJSONToLayer(this.props.geojson, this.props.icon || null)));
    }
}
