import { ParseUtils } from "@bryxinc/lunch";
export class Hydrant {
    constructor(mainSize, color, inService, location) {
        this.mainSize = mainSize;
        this.color = color;
        this.inService = inService;
        this.location = location;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new Hydrant(ParseUtils.getNumberOrNull(o, "mainSize"), ParseUtils.getString(o, "color"), ParseUtils.getBoolean(o, "inService"), o["location"]));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Hydrant Model: ${e.message}`);
        }
    }
}
