import { ParseUtils } from "@bryxinc/lunch";
export var JobType;
(function (JobType) {
    JobType[JobType["fire"] = 0] = "fire";
    JobType[JobType["ems"] = 1] = "ems";
    JobType[JobType["water"] = 2] = "water";
    JobType[JobType["info"] = 3] = "info";
    JobType[JobType["police"] = 4] = "police";
})(JobType || (JobType = {}));
export class JobTypeInformation {
    constructor(type, description, code, section) {
        this.type = type;
        this.description = description;
        this.code = code;
        this.section = section;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new JobTypeInformation(ParseUtils.getEnum(o, "type", JobType, JobType.info), ParseUtils.getStringOrNull(o, "description"), ParseUtils.getString(o, "code"), ParseUtils.getStringOrNull(o, "section")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid JobTypeInformation Model: ${e.message}`);
        }
    }
}
