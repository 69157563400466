import * as i18n from "i18next";
import * as React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
export class ConfirmationModal extends React.Component {
    render() {
        return (React.createElement(Modal, { size: "small", open: this.props.open, onClose: this.props.onCancel },
            React.createElement(Header, { icon: "warning", content: this.props.title }),
            React.createElement(Modal.Content, { style: { overflowY: "auto" } },
                React.createElement("div", { style: { display: "flex", flexDirection: "column", alignItems: "center", padding: "10px 22px" } }, this.props.body)),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { content: i18n.t("general.cancel"), onClick: this.props.onCancel }),
                React.createElement(Button, { content: i18n.t("general.confirm"), onClick: this.props.onConfirm }))));
    }
}
