import { ParseUtils } from "@bryxinc/lunch";
import { apiFailure, apiSuccess } from "../utils/bryxApi";
import { BryxNotification } from "./bryxNotification";
export function parseNotificationUpdate(message) {
    try {
        switch (message.key) {
            case "subscribeResponse":
                if (message.successful == false) {
                    return apiFailure(message.errorReason, message.errorReason);
                }
                return apiSuccess({
                    key: "replace",
                    notifications: ParseUtils.getArrayOfSubobjects({ initialData: message.initialData }, "initialData", BryxNotification.parse, "warn"),
                });
            case "serverUpdate":
                return apiSuccess({
                    key: "new",
                    notification: ParseUtils.getSubobject({ data: message.data }, "data", BryxNotification.parse),
                });
            default:
                return apiSuccess(null);
        }
    }
    catch (e) {
        return apiFailure(null, `Invalid NotificationUpdate Model: ${e.message}`);
    }
}
