import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import * as moment from "moment/moment";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { MainRouter } from "./index";
import * as React from "react";
import * as ReactDom from 'react-dom';
i18n
    .use(XHR)
    .use(initReactI18next) // bind react-i18next to the instance
    .use({
    name: "locale",
    type: "postProcessor",
    process: (value, key, options) => {
        if (!value.includes("$l")) {
            return value;
        }
        const values = value
            .match(/\$l\(([^\)]*)\)/g)
            .map((v) => v.match(/\$l\(([^\)]*)\)/));
        for (const v of values) {
            if (v && v.length > 0) {
                const match = v[0];
                let val = v[1]
                    .split(",")
                    .find((e, i) => i != 0 && e && !e.includes(".name"));
                if (!val) {
                    val = v[1];
                }
                val = val.trim();
                if (val.length < 4) {
                    val = val.toUpperCase();
                }
                else {
                    val = val
                        .toLowerCase()
                        .split(" ")
                        .map((s) => s[0].toUpperCase() + s.slice(1))
                        .join(" ");
                }
                value = value.replace(match, val);
            }
        }
        return value;
    },
    overloadTranslationOptionHandler: (args) => {
        console.log("overloadTranslationOptionHandler", args);
        return { defaultValue: args[1] };
    },
})
    .init({
    lng: "en",
    ns: ["default", "country"],
    defaultNS: "default",
    backend: {
        loadPath: "/resources/locales/{{lng}}.json",
    },
    fallbackLng: "en",
    postProcess: ["locale"],
    interpolation: {
        escapeValue: false,
        format: (value, format, lng) => {
            if (!value) {
                return value;
            }
            if (value instanceof Date) {
                return moment(value).format(format);
            }
            if (Array.isArray(value)) {
                return value.join(", ");
            }
            if (typeof value === "object") {
                if (value.name) {
                    value = value.name;
                }
                else {
                    value = JSON.stringify(value);
                }
            }
            if (format === "lowercase") {
                return value.toLowerCase();
            }
            if (format === "uppercase") {
                return value.toUpperCase();
            }
            if (format === "titlecase") {
                return value
                    .toLowerCase()
                    .split(" ")
                    .map((s) => s[0].toUpperCase() + s.slice(1))
                    .join(" ");
            }
            return value;
        },
    },
    debug: true,
    react: {
        useSuspense: false,
    },
    returnEmptyString: false,
}).then(() => {
    const i18nProviderElement = React.createElement(I18nextProvider, { i18n: i18n }, React.createElement(MainRouter));
    ReactDom.render(i18nProviderElement, document.getElementById("content"));
});
