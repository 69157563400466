import * as i18n from "i18next";
import * as React from 'react';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import { config } from "../config";
import { BryxApi } from "../utils/bryxApi";
import { BryxLocal } from "../utils/bryxLocal";
import { nullIfBlank } from "../utils/functions";
export class ContactSupportModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = ContactSupportModal.getInitialState(props);
    }
    static localStorageEmail() {
        return !BryxLocal.isApparatus() ? BryxLocal.getEmail() : null;
    }
    static getInitialState(props) {
        return {
            status: {
                key: "ready",
            },
            from: props.email || ContactSupportModal.localStorageEmail(),
            subject: null,
            type: "feedback",
            image: null,
            body: null,
        };
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            from: nextProps.email || ContactSupportModal.localStorageEmail(),
        });
    }
    onFromFieldChange(e, inputProps) {
        this.setState({
            from: nullIfBlank(inputProps.value),
        });
    }
    onSubjectChange(e, inputProps) {
        this.setState({
            subject: nullIfBlank(inputProps.value),
        });
    }
    onTypeChange(e, dropdownProps) {
        this.setState({
            type: dropdownProps.value,
        });
    }
    onBodyChange(e, bodyProps) {
        this.setState({
            body: nullIfBlank(bodyProps.value || null),
        });
    }
    onSubmitSupportTicket() {
        this.setState({
            status: { key: "loading" },
        }, () => {
            if (this.state.from == null || this.state.body == null) {
                this.setState({
                    status: { key: "error", description: i18n.t("general.genericError") },
                });
                return;
            }
            BryxApi.sendSupportTicket(this.state.from, this.state.subject, this.state.type, this.state.body, this.state.image, (result) => {
                if (result.success == true) {
                    config.info("User successfully sent support ticket");
                    this.setState({
                        status: { key: "ready" },
                        from: null,
                        body: null,
                    }, () => this.resetAndClose());
                }
                else {
                    config.warn(`User failed to send support ticket: ${result.debugMessage}`);
                    this.setState({
                        status: { key: "error", description: result.message },
                    });
                }
            });
        });
    }
    resetAndClose() {
        this.setState(ContactSupportModal.getInitialState(this.props));
        this.props.onClose();
    }
    render() {
        const ticketTypes = ContactSupportModal.ticketTypes.map((option) => ({
            key: option,
            text: i18n.t(`contactSupport.supportTypes.${option}`),
            value: option,
        }));
        const errorMessage = this.state.status.key == "error" ? (React.createElement(Message, { negative: true, content: this.state.status.description })) : null;
        return (React.createElement(Modal, { open: this.props.open, size: "small", onClose: this.resetAndClose.bind(this) },
            React.createElement(Modal.Header, null, i18n.t("contactSupport.header")),
            React.createElement(Modal.Content, null,
                React.createElement(Form, null,
                    React.createElement(Form.Input, { label: i18n.t("contactSupport.fromLabel"), placeholder: i18n.t("contactSupport.fromPlaceholder"), autoComplete: false, autoCorrect: false, autoCapitalize: false, spellCheck: false, value: this.state.from || "", onChange: this.onFromFieldChange.bind(this) }),
                    React.createElement(Form.Input, { label: i18n.t("contactSupport.subjectLabel"), placeholder: i18n.t("contactSupport.subjectPlaceholder"), value: this.state.subject || "", onChange: this.onSubjectChange.bind(this) }),
                    React.createElement(Form.Select, { label: i18n.t("contactSupport.ticketTypeLabel"), options: ticketTypes, value: this.state.type, onChange: this.onTypeChange.bind(this) }),
                    React.createElement(Form.Input, { label: i18n.t("contactSupport.imageLabel"), type: "file", control: "input", accept: ".png,.jpg", onChange: e => {
                            const target = e.target;
                            this.setState({ image: target.files != null ? target.files[0] : null });
                        } }),
                    React.createElement(Form.TextArea, { label: i18n.t("contactSupport.bodyLabel"), value: this.state.body || "", placeholder: i18n.t("contactSupport.bodyPlaceholder"), onChange: this.onBodyChange.bind(this) })),
                errorMessage),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { content: i18n.t("general.cancel"), onClick: this.resetAndClose.bind(this) }),
                React.createElement(Button, { primary: true, loading: this.state.status.key == "loading", disabled: this.state.from == null || this.state.body == null || this.state.status.key == "loading", content: i18n.t("general.send"), onClick: this.onSubmitSupportTicket.bind(this) }))));
    }
}
ContactSupportModal.ticketTypes = ["feedback", "question", "bug", "featureRequest"];
