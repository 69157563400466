export class BryxColors {
}
BryxColors.red = "#c61a1b";
BryxColors.brandRed = "#8f0808";
BryxColors.darkRed = "#ab1a1c";
BryxColors.green = "#30ae33";
BryxColors.darkGray = "#595453";
BryxColors.gray = "#828282";
BryxColors.lightGray = "#b0b0b0";
BryxColors.extraLightGray = "#cacaca";
BryxColors.offWhite = "#f1f1f1";
BryxColors.emsBlue = "#1d2ec2";
BryxColors.infoOrange = "#f5a623";
BryxColors.policeBlue = "#001d5f";
BryxColors.waterBlue = "#54bdec";
BryxColors.mutedRed = "#c14444";
BryxColors.mutedGreen = "#36a650";
BryxColors.mutedYellow = "#d6ac2e";
BryxColors.mutedBlue = "#3852d6";
