import * as i18n from "i18next";
import * as React from "react";
import { Button, Form, Icon, Input, Message, Popup } from "semantic-ui-react";
import { config } from "../../config";
import { BryxApi } from "../../utils/bryxApi";
export class PasswordTab extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            status: { key: "ready" },
        };
    }
    onChangeCurrentPassword(event) {
        this.setState({
            currentPassword: event.currentTarget.value,
        });
    }
    onChangeNewPassword(event) {
        this.setState({
            newPassword: event.currentTarget.value,
        });
    }
    onChangeConfirmPassword(event) {
        this.setState({
            confirmPassword: event.currentTarget.value,
        });
    }
    onSubmit(e) {
        e.preventDefault();
        this.setState({ status: { key: "loading" } });
        BryxApi.changePassword(this.state.currentPassword, this.state.newPassword, result => {
            if (result.success == true) {
                this.setState({ status: { key: "ready" } });
                this.props.onPasswordChanged();
            }
            else {
                config.warn(`Failed to change password: ${result.debugMessage}`);
                this.setState({ status: { key: "error", message: result.message } });
            }
        });
    }
    render() {
        const passwordRulesPopup = (React.createElement(Popup, { trigger: React.createElement(Icon, { name: "help circle", style: { marginLeft: "5px" } }), header: i18n.t("settings.password.passwordRulesHeader"), content: i18n.t("settings.password.passwordRules"), position: "right center" }));
        const errorMessage = this.state.status.key == "error" ? (React.createElement(Message, { error: true, content: this.state.status.message })) : null;
        return (React.createElement("div", { style: { display: "flex", flexDirection: "column", padding: "2rem 3rem" } },
            React.createElement(Form, { className: "changePasswordForm", onSubmit: this.onSubmit.bind(this), style: { flex: 1 } },
                React.createElement(Form.Field, null,
                    React.createElement("label", null, i18n.t("settings.password.currentPassword")),
                    React.createElement(Input, { required: true, type: "password", placeholder: i18n.t("settings.password.currentPassword"), value: this.state.currentPassword || "", disabled: this.state.status.key == "loading", onChange: this.onChangeCurrentPassword.bind(this) })),
                React.createElement(Form.Field, null,
                    React.createElement("label", null,
                        i18n.t("settings.password.newPassword"),
                        passwordRulesPopup),
                    React.createElement(Input, { required: true, type: "password", error: this.state.newPassword != this.state.confirmPassword, placeholder: i18n.t("settings.password.newPassword"), value: this.state.newPassword || "", disabled: this.state.status.key == "loading", onChange: this.onChangeNewPassword.bind(this) })),
                React.createElement(Form.Field, null,
                    React.createElement("label", null,
                        i18n.t("settings.password.confirmPassword"),
                        passwordRulesPopup),
                    React.createElement(Input, { required: true, type: "password", error: this.state.newPassword != this.state.confirmPassword, placeholder: i18n.t("settings.password.confirmPassword"), value: this.state.confirmPassword || "", disabled: this.state.status.key == "loading", onChange: this.onChangeConfirmPassword.bind(this) })),
                React.createElement(Form.Field, null,
                    React.createElement(Button, { positive: true, style: { float: "right", marginRight: 0 }, loading: this.state.status.key == "loading", disabled: this.state.confirmPassword == "" || this.state.newPassword == "" || this.state.currentPassword == "" || this.state.newPassword != this.state.confirmPassword, content: i18n.t("general.submit") }))),
            errorMessage));
    }
}
