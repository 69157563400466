import { ParseUtils } from "@bryxinc/lunch";
import { config } from "../config";
import { BryxApi } from "../utils/bryxApi";
import { arraysEqual } from "../utils/functions";
import { Address } from "./address";
import { Complainant } from "./complainant";
import { MinimalHospital } from "./hospital";
import { Hydrant } from "./hydrant";
import { JobTypeInformation } from "./jobTypeInformation";
import { Responder, ResponseOption } from "./responder";
import { Supplemental } from "./supplemental";
export var Disposition;
(function (Disposition) {
    Disposition[Disposition["open"] = 0] = "open";
    Disposition[Disposition["old"] = 1] = "old";
    Disposition[Disposition["closed"] = 2] = "closed";
})(Disposition || (Disposition = {}));
export var RespondPermission;
(function (RespondPermission) {
    RespondPermission[RespondPermission["on"] = 0] = "on";
    RespondPermission[RespondPermission["off"] = 1] = "off";
    RespondPermission[RespondPermission["forced"] = 2] = "forced";
})(RespondPermission || (RespondPermission = {}));
export class MinimalJob {
    constructor(id, creationTime, typeInformation, unitShortNames, synopsis, centroid, address, disposition) {
        this.id = id;
        this.creationTime = creationTime;
        this.typeInformation = typeInformation;
        this.unitShortNames = unitShortNames;
        this.synopsis = synopsis;
        this.centroid = centroid;
        this.address = address;
        this.disposition = disposition;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new MinimalJob(ParseUtils.getString(o, "id"), ParseUtils.getUNIXTimestampDate(o, "ts"), ParseUtils.getSubobject(o, "type", JobTypeInformation.parse), ParseUtils.getArray(o, "unitShortNames"), ParseUtils.getString(o, "synopsis"), o["centroid"] || null, ParseUtils.getSubobject(o, "address", Address.parse), ParseUtils.getEnum(o, "disposition", Disposition)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Minimal Job Model: ${e.message}`);
        }
    }
    get shortDepartment() { return this.unitShortNames.join(', '); }
    get isOpen() { return this.disposition != Disposition.closed; }
}
export class ListJob extends MinimalJob {
    constructor(id, creationTime, typeInformation, unitShortNames, synopsis, centroid, address, disposition, hasResponded) {
        super(id, creationTime, typeInformation, unitShortNames, synopsis, centroid, address, disposition);
        this.id = id;
        this.creationTime = creationTime;
        this.typeInformation = typeInformation;
        this.unitShortNames = unitShortNames;
        this.synopsis = synopsis;
        this.centroid = centroid;
        this.address = address;
        this.disposition = disposition;
        this.hasResponded = hasResponded;
    }
    static parse(o) {
        try {
            const minimalParse = MinimalJob.parse(o);
            if (minimalParse.success == false) {
                return ParseUtils.parseFailure(`Invalid List Job: ${minimalParse.justification}`);
            }
            const minimal = minimalParse.value;
            return ParseUtils.parseSuccess(new ListJob(minimal.id, minimal.creationTime, minimal.typeInformation, minimal.unitShortNames, minimal.synopsis, minimal.centroid, minimal.address, minimal.disposition, ParseUtils.getBoolean(o, "hasResponded")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid ListJob Model: ${e.message}`);
        }
    }
    static compare(j1, j2) {
        return j2.creationTime.getTime() - j1.creationTime.getTime() || j1.id.localeCompare(j2.id);
    }
}
export class FullJob extends MinimalJob {
    constructor(id, creationTime, typeInformation, unitShortNames, synopsis, centroid, address, disposition, complainant, supplementals, box, canHospitalAlert, crossStreets, hasHistorical, hospitals, hydrants, incidentId, location, priority, responders, responseOptions, respondPermission) {
        super(id, creationTime, typeInformation, unitShortNames, synopsis, centroid, address, disposition);
        this.id = id;
        this.creationTime = creationTime;
        this.typeInformation = typeInformation;
        this.unitShortNames = unitShortNames;
        this.synopsis = synopsis;
        this.centroid = centroid;
        this.address = address;
        this.disposition = disposition;
        this.complainant = complainant;
        this.supplementals = supplementals;
        this.box = box;
        this.canHospitalAlert = canHospitalAlert;
        this.crossStreets = crossStreets;
        this.hasHistorical = hasHistorical;
        this.hospitals = hospitals;
        this.hydrants = hydrants;
        this.incidentId = incidentId;
        this.location = location;
        this.priority = priority;
        this.responders = responders;
        this.responseOptions = responseOptions;
        this.respondPermission = respondPermission;
        this.historicalJobs = null;
        this.siteSurvey = null;
    }
    static parse(o) {
        try {
            const minimalParse = MinimalJob.parse(o);
            if (minimalParse.success == false) {
                return ParseUtils.parseFailure(`Invalid FullJob Model: ${minimalParse.justification}`);
            }
            const minimal = minimalParse.value;
            return ParseUtils.parseSuccess(new FullJob(minimal.id, minimal.creationTime, minimal.typeInformation, minimal.unitShortNames, minimal.synopsis, minimal.centroid, minimal.address, minimal.disposition, ParseUtils.getSubobjectOrNull(o, "comp", Complainant.parse), ParseUtils.getArrayOfSubobjects(o, "supplementals", Supplemental.parse, "warn"), ParseUtils.getStringOrNull(o, "box"), ParseUtils.getBoolean(o, "canHospitalAlert"), ParseUtils.getStringOrNull(o, "crossStreets"), ParseUtils.getBoolean(o, "hasHistorical"), ParseUtils.getArrayOfSubobjects(o, "hospitals", MinimalHospital.parse, "warn"), ParseUtils.getArrayOfSubobjectsOrNull(o, "hydrants", Hydrant.parse, "warn"), ParseUtils.getStringOrNull(o, "incidentId"), o["location"], ParseUtils.getStringOrNull(o, "priority"), ParseUtils.getArrayOfSubobjects(o, "responders", Responder.parse, "warn"), ParseUtils.getArrayOfSubobjects(o, "responseOptions", ResponseOption.parse, "warn").sort(ResponseOption.compare), ParseUtils.getEnum(o, "respondPermission", RespondPermission)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid FullJob Model: ${e.message}`);
        }
    }
    updateResponders(responders) {
        if (arraysEqual(this.responders, responders, (a, b) => a.isEqual(b))) {
            config.info("Not updating identical responders");
            return;
        }
        this.responders = responders;
    }
    updateSupplementals(supplementals) {
        if (arraysEqual(this.supplementals, supplementals, (a, b) => a.isEqual(b))) {
            config.info("Not updating identical supplementals");
            return;
        }
        this.supplementals = supplementals;
    }
    updateUnitShortNames(unitShortNames) {
        if (arraysEqual(this.unitShortNames, unitShortNames, (a, b) => a == b)) {
            config.info("Not updating identical unitShortNames");
            return;
        }
        this.unitShortNames = unitShortNames;
    }
    updateHydrants(hydrants) {
        // FIXME: Update hydrants; Waiting on API implementation
    }
    updateWithJob(job) {
        if (job.id != this.id) {
            config.error("Attempted to update job with wrong job");
            return;
        }
        this.updateResponders(job.responders);
        const newSupplementals = job.supplementals;
        if (newSupplementals != null) {
            this.updateSupplementals(newSupplementals);
        }
        this.updateUnitShortNames(job.unitShortNames);
        const newHydrants = job.hydrants;
        if (newHydrants != null) {
            this.updateHydrants(newHydrants);
        }
    }
    loadSiteSurvey(callback) {
        BryxApi.loadSiteSurvey(this.id, result => {
            if (result.success == true) {
                this.siteSurvey = result.value;
            }
            callback(result);
        });
    }
    loadHistoricalJobs(callback) {
        BryxApi.loadHistoricalJobs(this.id, result => {
            if (result.success == true) {
                this.historicalJobs = result.value;
            }
            callback(result);
        });
    }
    get userResponder() { return this.responders.filter(r => r.isMe).shift() || null; }
    get hasResponded() { return this.userResponder != null; }
}
