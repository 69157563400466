export var Theme;
(function (Theme) {
    Theme[Theme["light"] = 0] = "light";
    Theme[Theme["dark"] = 1] = "dark";
})(Theme || (Theme = {}));
export class ThemeUtils {
    static getClassName(theme) {
        return theme == Theme.light ? undefined : Theme[theme];
    }
}
