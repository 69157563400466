import { Control, DomUtil } from "leaflet";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { MapControl } from "react-leaflet";
import { Icon as SemanticIcon } from "semantic-ui-react";
export class LeafletControl extends MapControl {
    createLeafletElement(props) {
        const control = new Control(this.props);
        const controlContent = (React.createElement("a", { className: "leaflet-touch", style: { height: "30px", width: "30px" }, onClick: this.props.onClick },
            React.createElement(SemanticIcon, { name: this.props.icon, style: { height: "100%", width: "100%", fontSize: "1.4em", margin: 0 } })));
        control.onAdd = () => {
            const div = DomUtil.create('div', 'leaflet-bar');
            ReactDOM.render(controlContent, div);
            return div;
        };
        return control;
    }
}
