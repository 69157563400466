import * as i18n from "i18next";
import * as React from 'react';
import { Button, Form, Header, Input, Message, Modal } from 'semantic-ui-react';
import Recaptcha from "react-recaptcha";
import { config } from "../config";
import { BryxApi } from "../utils/bryxApi";
import { nullIfBlank } from "../utils/functions";
export class ForgotPasswordModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.recaptchaInstance = null;
        this.state = {
            status: { key: "ready" },
            captchaResponse: null,
        };
    }
    onEmailChange(e, inputProps) {
        this.props.onEmailChange(inputProps.value);
    }
    sendForgotPassword() {
        if (this.props.email == null || this.state.captchaResponse == null) {
            return;
        }
        this.setState({
            status: { key: "loading" },
        });
        BryxApi.forgotPassword(this.props.email, this.state.captchaResponse, (result) => {
            if (result.success == true) {
                config.info("User successfully sent forgot password email");
                this.setState({
                    status: { key: "ready" },
                });
                this.props.onSuccess();
            }
            else {
                config.warn(`Failed to send forgot password email: ${result.debugMessage}`);
                if (this.recaptchaInstance != null) {
                    this.recaptchaInstance.reset();
                }
                this.setState({
                    status: {
                        key: "error",
                        message: result.message,
                    },
                });
            }
        });
    }
    render() {
        return (React.createElement(Modal, { size: "tiny", open: this.props.open, onClose: this.props.onCancel },
            React.createElement(Header, { icon: "question circle", content: i18n.t("login.forgotPassword") }),
            React.createElement(Modal.Content, null,
                React.createElement(Form, { onSubmit: e => {
                        e.preventDefault();
                        this.sendForgotPassword();
                    } },
                    React.createElement(Form.Field, null,
                        React.createElement(Input, { autoFocus: true, type: "email", placeholder: i18n.t("login.email"), disabled: this.state.status.key == "loading", value: this.props.email, onChange: this.onEmailChange.bind(this) }))),
                React.createElement("div", { style: { marginTop: "20px", display: "flex", justifyContent: "center" } },
                    React.createElement(Recaptcha, { ref: e => this.recaptchaInstance = e, sitekey: ForgotPasswordModal.CAPTCHA_SITE_KEY, verifyCallback: response => this.setState({
                            captchaResponse: response,
                        }) })),
                this.state.status.key == "error" && (React.createElement(Message, { error: true }, this.state.status.message))),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { disabled: this.state.status.key == "loading", type: "button", onClick: this.props.onCancel }, i18n.t("general.cancel")),
                React.createElement(Button, { primary: true, type: "button", onClick: this.sendForgotPassword.bind(this), disabled: nullIfBlank(this.props.email) == null || this.state.captchaResponse == null, loading: this.state.status.key == "loading" }, i18n.t("general.submit")))));
    }
}
ForgotPasswordModal.CAPTCHA_SITE_KEY = "6LeX7jQUAAAAAD_MbdOuRz7zCesILqiKLWQOkgus";
