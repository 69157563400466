import { ParseUtils } from "@bryxinc/lunch";
import { GeoUtils } from "../utils/geoUtils";
export class Responder {
    constructor(id, isMe, location, name, phone, currentResponse, distance) {
        this.id = id;
        this.isMe = isMe;
        this.location = location;
        this.name = name;
        this.phone = phone;
        this.currentResponse = currentResponse;
        this.distance = distance;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new Responder(ParseUtils.getString(o, "id"), ParseUtils.getBoolean(o, "isMe"), o["location"] || null, ParseUtils.getString(o, "name"), ParseUtils.getStringOrNull(o, "phone"), ParseUtils.getSubobject(o, "currentResponse", Response.parse), ParseUtils.getNumberOrNull(o, "distance")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Responder Model: ${e.message}`);
        }
    }
    static compare(r1, r2) {
        return r1.name.localeCompare(r2.name);
    }
    isEqual(responder) {
        return this.name == responder.name &&
            this.id == responder.id &&
            this.phone == responder.phone &&
            this.isMe == responder.isMe &&
            this.distance == responder.distance &&
            this.currentResponse.isEqual(responder.currentResponse) &&
            GeoUtils.geoJsonPointIsEqual(this.location, responder.location);
    }
}
export class Response {
    constructor(responseTime, responseOption) {
        this.responseTime = responseTime;
        this.responseOption = responseOption;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new Response(ParseUtils.getUNIXTimestampDate(o, "ts"), ParseUtils.getSubobject(o, "responseOption", ResponseOption.parse)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Response Model: ${e.message}`);
        }
    }
    isEqual(response) {
        return this.responseTime.getTime() == response.responseTime.getTime() &&
            this.responseOption.isEqual(response.responseOption);
    }
    static compare(r1, r2) {
        return r2.responseTime.getTime() - r1.responseTime.getTime();
    }
}
export var ResponseOptionType;
(function (ResponseOptionType) {
    ResponseOptionType[ResponseOptionType["positive"] = 0] = "positive";
    ResponseOptionType[ResponseOptionType["negative"] = 1] = "negative";
})(ResponseOptionType || (ResponseOptionType = {}));
export class ResponseOption {
    constructor(id, text, type) {
        this.id = id;
        this.text = text;
        this.type = type;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new ResponseOption(ParseUtils.getString(o, "id"), ParseUtils.getString(o, "text"), ParseUtils.getEnum(o, "type", ResponseOptionType)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid ResponseOption Model: ${e.message}`);
        }
    }
    isEqual(responseOption) {
        // TODO: Use ID only; when actually API-unique
        return this.text == responseOption.text &&
            this.type == responseOption.type;
    }
    static compare(r1, r2) {
        const r1TypePoint = r1.type == ResponseOptionType.positive ? 0 : 1;
        const r2TypePoint = r2.type == ResponseOptionType.positive ? 0 : 1;
        return r1TypePoint - r2TypePoint || r2.text.localeCompare(r2.text) || r1.id.localeCompare(r2.id);
    }
}
