import { ParseUtils } from "@bryxinc/lunch";
import { AuthUnit } from "./auth";
export class SCUUnitOptions {
    constructor(units, unitOptions, zones) {
        this.units = units;
        this.unitOptions = unitOptions;
        this.zones = zones;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new SCUUnitOptions(ParseUtils.getArrayOfSubobjects(o, "units", AuthUnit.parse, "throw"), ParseUtils.getArrayOfSubobjects(o, "unitOptions", AuthUnit.parse, "throw"), ParseUtils.getArrayOfSubobjects(o, "zones", SCUZone.parse, "throw")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUUnitOptions Model: ${e.message}`);
        }
    }
}
export class SCUZone {
    constructor(id, name, units) {
        this.id = id;
        this.name = name;
        this.units = units;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new SCUZone(ParseUtils.getString(o, "id"), ParseUtils.getString(o, "name"), ParseUtils.getArray(o, "units")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUZone: ${e.message}`);
        }
    }
}
