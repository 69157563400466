import * as i18n from "i18next";
import * as React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import { config } from "../config";
import { BryxColors } from "../utils/bryxColors";
import { KeyValueView } from "./keyValueView";
export class AboutUsModal extends React.Component {
    render() {
        const versionItem = (React.createElement(KeyValueView, { keyNode: i18n.t("about.version"), keyWidth: "60px", valueNode: (React.createElement("div", null,
                config.version,
                " ",
                React.createElement("a", { className: "lightBackgroundLink", onClick: this.props.onClickReleaseNotes }, i18n.t("about.releaseNotes")))) }));
        const licensesItem = (React.createElement(KeyValueView, { keyNode: i18n.t("about.licenses"), keyWidth: "60px", valueNode: (React.createElement("a", { href: "/licenses.txt", target: "_blank", style: { textDecoration: "underline" }, className: "lightBackgroundLink" }, i18n.t("about.viewLicenses"))) }));
        return (React.createElement(Modal, { size: "small", open: this.props.open, onClose: this.props.onClose },
            React.createElement(Header, { icon: "file text outline", content: i18n.t("about.header") }),
            React.createElement(Modal.Content, { style: { height: "300px", overflowY: "auto" } },
                React.createElement("div", { style: { display: "flex", flexDirection: "column", alignItems: "center", padding: "10px 22px" } },
                    React.createElement("img", { style: { height: "54px" }, src: "/resources/assets/letterHead_compact.png" }),
                    React.createElement("div", { style: { width: "80%", marginTop: "20px", color: BryxColors.gray } }, i18n.t("about.mission")),
                    React.createElement("div", { style: { marginTop: "40px" } },
                        versionItem,
                        licensesItem))),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { content: i18n.t("general.close"), onClick: this.props.onClose }))));
    }
}
