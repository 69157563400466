import { DivIcon, Icon, latLngBounds } from "leaflet";
import * as React from 'react';
import { LayerGroup, LayersControl, Map, TileLayer } from "react-leaflet";
import { JobType } from "../models/jobTypeInformation";
import { MapClientType } from "../models/mapClient";
import { BryxLocal } from "../utils/bryxLocal";
import { LeafletControl } from "./leafletControl";
import i18n from "i18next";
export class BryxMap extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            viewportStatus: { key: "pending" },
        };
        this.mapRef = null;
    }
    static jobTypeToIcon(type) {
        switch (type) {
            case JobType.fire:
                return BryxMap.fireIcon;
            case JobType.ems:
                return BryxMap.emsIcon;
            case JobType.info:
                return BryxMap.infoIcon;
            case JobType.water:
                return BryxMap.waterIcon;
            case JobType.police:
                return BryxMap.policeIcon;
        }
    }
    static hydrantIcon(hydrant) {
        const cleanedSize = (hydrant.mainSize && hydrant.mainSize.toString() || "").slice(0, 2);
        return new DivIcon({
            iconUrl: "/resources/assets/hydrant_pin.svg",
            iconSize: [20, 30],
            iconAnchor: [10, 30],
            className: "hydrantIcon",
            html: `<img src='/resources/assets/hydrant_pin.svg' height="30px" width="20px"/><span style='position: absolute; bottom: 2px; left: 0; width: 20px; text-align: center; color: white;'>${cleanedSize}</span>`,
        });
    }
    static mapClientIcon(mapClient) {
        const cachedIcon = BryxMap.clientIconCache[mapClient.id];
        if (cachedIcon != null) {
            return cachedIcon;
        }
        const topOffset = mapClient.initials.length > 3 ? "20px" : "19px";
        const textSize = mapClient.initials.length > 3 ? "8px" : "10px";
        if (mapClient.info.type == MapClientType.user) {
            const icon = new DivIcon({
                iconAnchor: [11.5, 45],
                className: "userIcon",
                html: `<img src='/resources/assets/client_map_pins/user_pin.png' height="45px" width="24px"/><span style='position: absolute; top: ${topOffset}; left: 0; width: 24px; text-align: center; font-size: ${textSize}; font-weight: bold;'>${mapClient.initials}</span>`,
            });
            BryxMap.clientIconCache[mapClient.id] = icon;
            return icon;
        }
        else {
            const icon = new DivIcon({
                iconAnchor: [11.5, 45],
                className: "truckIcon",
                html: `<img src='/resources/assets/client_map_pins/truck_pin.png' height="45px" width="24px"/><span style='position: absolute; top: ${topOffset}; left: 0; width: 24px; text-align: center; font-size: ${textSize}; font-weight: bold;'>${mapClient.initials}</span>`,
            });
            BryxMap.clientIconCache[mapClient.id] = icon;
            return icon;
        }
    }
    render() {
        const mapLayerTypes = [
            {
                i18nKey: "streets",
                id: "streets-v9",
            },
            {
                i18nKey: "satellite",
                id: "satellite-v9",
            },
            {
                i18nKey: "hybrid",
                id: "satellite-streets-v9",
            },
            {
                i18nKey: "dark",
                id: "dark-v9",
            },
        ];
        const selectedLayerId = BryxLocal.getCurrentBaseLayerId() || mapLayerTypes[0].id;
        const baseLayers = mapLayerTypes.map(type => (React.createElement(LayersControl.BaseLayer, { key: `mapLayer:${type.id}`, checked: type.id == selectedLayerId, name: i18n.t(`map.${type.i18nKey}`) },
            React.createElement(TileLayer, { url: BryxMap.MB_STYLE_URL, accessToken: BryxMap.ACCESS_TOKEN, user: "mapbox", id: type.id, attribution: BryxMap.MB_ATTRIBUTION }))));
        return (React.createElement(Map, { zoom: 13, zoomAnimation: false, className: "bryxMap", ...this.props, onViewportChange: viewport => {
                if (this.state.viewportStatus.key != "pending") {
                    this.setState({ viewportStatus: { key: "userControlled", viewport: viewport } });
                    if (this.props.onUpdateBounds && this.mapRef != null) {
                        this.props.onUpdateBounds(this.mapRef.leafletElement.getBounds());
                    }
                }
            }, onViewportChanged: () => {
                if (this.state.viewportStatus.key == "pending") {
                    this.setState({ viewportStatus: { key: "systemControlled" } });
                }
            }, viewport: this.state.viewportStatus.key == "userControlled" ? this.state.viewportStatus.viewport : undefined, bounds: this.state.viewportStatus.key == "userControlled" ? undefined : (this.props.bounds || this.props.defaultBounds || BryxMap.unitedStatesBounds), whenReady: () => {
                this.setState({ viewportStatus: { key: "pending" } });
            }, ref: r => {
                if (r != null && r.leafletElement && !r.leafletElement.hasEventListeners("baselayerchange")) {
                    r.leafletElement.addEventListener("baselayerchange", (e) => {
                        BryxLocal.setCurrentBaseLayerId(e.layer.options.id);
                    });
                    r.leafletElement.addEventListener("overlayadd", (e) => {
                        const { mapLayers, onAddLayer } = this.props;
                        const matchingLayer = mapLayers != null ? mapLayers.filter(layer => e.name == layer.label)[0] : null;
                        if (matchingLayer != null && onAddLayer) {
                            onAddLayer(matchingLayer.name);
                        }
                    });
                    r.leafletElement.addEventListener("overlayremove", (e) => {
                        const { mapLayers, onRemoveLayer } = this.props;
                        const matchingLayer = mapLayers != null ? mapLayers.filter(layer => e.name == layer.label)[0] : null;
                        if (matchingLayer != null && onRemoveLayer) {
                            onRemoveLayer(matchingLayer.name);
                        }
                    });
                }
                this.mapRef = r;
            } },
            React.createElement(LayersControl, { position: "topright" },
                baseLayers,
                (this.props.mapLayers || []).map(layer => (React.createElement(LayersControl.Overlay, { key: layer.name, name: layer.label, checked: layer.defaultChecked },
                    React.createElement(LayerGroup, { key: layer.name }, layer.elements))))),
            this.state.viewportStatus.key == "userControlled" ? (React.createElement(LeafletControl, { position: "topleft", icon: "crosshairs", onClick: () => {
                    if (this.state.viewportStatus.key == "userControlled") {
                        this.setState({ viewportStatus: { key: "pending" } }, () => {
                            if (this.props.onUpdateBounds && this.mapRef != null) {
                                this.props.onUpdateBounds(this.mapRef.leafletElement.getBounds());
                            }
                        });
                    }
                } })) : null,
            this.props.onClickFullscreen != null ? (React.createElement(LeafletControl, { position: "topleft", icon: "expand", onClick: this.props.onClickFullscreen })) : null,
            this.props.children));
    }
}
BryxMap.genericIcon = new Icon({
    iconUrl: "/resources/assets/generic_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
});
BryxMap.stationIcon = new Icon({
    iconUrl: "/resources/assets/station_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
});
BryxMap.fireIcon = new Icon({
    iconUrl: "/resources/assets/job_map_pins/fire_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
});
BryxMap.emsIcon = new Icon({
    iconUrl: "/resources/assets/job_map_pins/ems_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
});
BryxMap.infoIcon = new Icon({
    iconUrl: "/resources/assets/job_map_pins/info_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
});
BryxMap.waterIcon = new Icon({
    iconUrl: "/resources/assets/job_map_pins/water_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
});
BryxMap.policeIcon = new Icon({
    iconUrl: "/resources/assets/job_map_pins/police_pin.svg",
    iconSize: [23, 45],
    iconAnchor: [11.5, 45],
});
BryxMap.myLocationIcon = new Icon({
    iconUrl: "/resources/assets/my_location.gif",
    iconSize: [15, 15],
    iconAnchor: [7.5, 7.5],
});
BryxMap.unitedStatesBounds = latLngBounds([24.7433195, -124.7844079], [49.3457868, -66.9513812]);
BryxMap.ACCESS_TOKEN = "pk.eyJ1IjoidGVicm93biIsImEiOiIzb0xlTjlzIn0.qRGtH1Q-ZAViez0fTPX9fg";
BryxMap.MB_ATTRIBUTION = 'Data &copy; <a href="http://bryx.com">Bryx, Inc.</a> | Imagery from <a href="http://mapbox.com/about/maps/">MapBox</a> | Map &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
BryxMap.MB_STYLE_URL = "https://api.mapbox.com/styles/v1/{user}/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}";
BryxMap.clientIconCache = {};
