import { Theme } from "../models/theme";
import { BryxLocal } from "./bryxLocal";
import { DeviceUtils } from "./deviceUtils";
export var BryxNavigationApp;
(function (BryxNavigationApp) {
    BryxNavigationApp[BryxNavigationApp["googleMaps"] = 0] = "googleMaps";
    BryxNavigationApp[BryxNavigationApp["bingMaps"] = 1] = "bingMaps";
    BryxNavigationApp[BryxNavigationApp["appleMaps"] = 2] = "appleMaps";
    BryxNavigationApp[BryxNavigationApp["windowsMaps"] = 3] = "windowsMaps";
})(BryxNavigationApp || (BryxNavigationApp = {}));
export var BryxDateFormat;
(function (BryxDateFormat) {
    BryxDateFormat[BryxDateFormat["yearMonthDay"] = 0] = "yearMonthDay";
    BryxDateFormat[BryxDateFormat["monthDayYear"] = 1] = "monthDayYear";
    BryxDateFormat[BryxDateFormat["dayMonthYear"] = 2] = "dayMonthYear";
})(BryxDateFormat || (BryxDateFormat = {}));
export class PreferenceManager {
    constructor() {
        this.observers = [];
        this.prefCache = null;
    }
    get preferences() {
        const existingPreferences = this.prefCache;
        if (existingPreferences == null) {
            const newPreferences = { ...PreferenceManager.defaultPreferences, ...(BryxLocal.getItem("preferences") || {}) };
            // TODO: Remove after migration critical mass
            if (typeof newPreferences.theme == "string") {
                newPreferences.theme = Theme[newPreferences.theme];
            }
            this.prefCache = newPreferences;
            BryxLocal.setItem("preferences", newPreferences);
            return newPreferences;
        }
        else {
            return existingPreferences;
        }
    }
    updatePreferences(preferences) {
        const newPreferences = this.preferences;
        Object.assign(newPreferences, preferences);
        this.prefCache = newPreferences;
        BryxLocal.setItem("preferences", newPreferences);
        this.observers.forEach(o => o.preferencesManagerDidUpdatePreferences(newPreferences));
    }
    reset() {
        this.updatePreferences(PreferenceManager.defaultPreferences);
    }
    // PreferenceManagerObservers
    registerObserver(observer) {
        if (this.observers.filter(o => o === observer).length == 0) {
            this.observers.push(observer);
        }
    }
    unregisterObserver(observer) {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex != -1) {
            this.observers.splice(observerIndex, 1);
        }
    }
}
PreferenceManager.shared = new PreferenceManager();
PreferenceManager.navigationAppOptions = Object.keys(BryxNavigationApp)
    .map(k => BryxNavigationApp[k])
    .filter(v => typeof v == "number")
    .filter(o => {
    if (o == BryxNavigationApp.appleMaps) {
        return DeviceUtils.deviceInfo.os.family == "OS X";
    }
    else if (o == BryxNavigationApp.windowsMaps) {
        return DeviceUtils.deviceInfo.os.family == "Windows";
    }
    return true;
});
PreferenceManager.dateFormatOptions = Object.keys(BryxDateFormat).map(k => BryxDateFormat[k]).filter(v => typeof v == "number");
PreferenceManager.defaultPreferences = {
    useJobShortCode: true,
    useMetricUnits: false,
    use24HourTime: false,
    useWebNotifications: true,
    preferredNavigation: BryxNavigationApp.googleMaps,
    preferredDateFormat: BryxDateFormat.monthDayYear,
    preferredAgencyId: null,
    theme: Theme.dark,
};
