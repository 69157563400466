import { latLng, LatLng, LatLngBounds } from "leaflet";
import { BryxNavigationApp } from "./preferenceManager";
export class GeoUtils {
    static geoJsonToLatLng(point) {
        const flippedCoords = point.coordinates.slice(0, 2).reverse();
        return latLng(flippedCoords);
    }
    static geoJsonPointIsEqual(a, b) {
        if (a == null || b == null) {
            return false;
        }
        return latLng(a.coordinates).distanceTo(latLng(b.coordinates)) < 0.5;
    }
    static directionsUrl(point, preferences) {
        const latLong = point.coordinates.slice(0, 2).reverse();
        switch (preferences.preferredNavigation) {
            case BryxNavigationApp.googleMaps:
                return {
                    url: `https://www.google.com/maps/dir/?api=1&destination=${latLong.join(",")}`,
                    newWindow: true,
                };
            case BryxNavigationApp.bingMaps:
                return {
                    url: `http://bing.com/maps/default.aspx?rtp=~pos.${latLong.join("_")}`,
                    newWindow: true,
                };
            case BryxNavigationApp.appleMaps:
                return {
                    url: `http://maps.apple.com/?q=${latLong.join(",")}&dirflg=d`,
                    newWindow: false,
                };
            case BryxNavigationApp.windowsMaps:
                return {
                    url: `bingmaps:?rtp=~pos.${latLong[0]}_${latLong[1]}`,
                    newWindow: false,
                };
        }
    }
    static boundsFromLatLngs(latLngs) {
        const uniqueLatLngs = [];
        latLngs.forEach(original => {
            const matchs = uniqueLatLngs.filter(searchLatLng => latLng(searchLatLng).distanceTo(latLng(original)) < 0.5);
            if (matchs.length == 0) {
                uniqueLatLngs.push(original);
            }
        });
        if (uniqueLatLngs.length == 0) {
            return null;
        }
        else if (uniqueLatLngs.length == 1) {
            const coord = uniqueLatLngs[0];
            return new LatLng(coord[0], coord[1]).toBounds(100);
        }
        else {
            const swLat = Math.min(...uniqueLatLngs.map(ll => ll[0]));
            const swLng = Math.min(...uniqueLatLngs.map(ll => ll[1]));
            const neLat = Math.max(...uniqueLatLngs.map(ll => ll[0]));
            const neLng = Math.max(...uniqueLatLngs.map(ll => ll[1]));
            return new LatLngBounds(new LatLng(swLat, swLng), new LatLng(neLat, neLng));
        }
    }
}
