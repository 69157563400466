import { HttpClient, ResponseStatus } from "../utils/spoonClient";
import * as i18n from "i18next";
import * as React from 'react';
import { Button, Header, Loader, Modal } from 'semantic-ui-react';
import { config } from "../config";
import ReactMarkdown from "react-markdown";
import { ReleaseNotesUtils } from "../utils/releaseNotesUtils";
export class ReleaseNotesModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            status: { key: "loading" },
        };
    }
    componentDidMount() {
        const client = new HttpClient({});
        client.get(`/release-notes-${config.version}.txt`, null, (request, response) => {
            if (response.status == ResponseStatus.Success && response.responseText != null) {
                this.setState({
                    status: { key: "ready", releaseNotes: response.responseText },
                });
            }
            else {
                config.warn(`Unable to load release notes for version ${config.version}: ${response.responseText}`);
                this.setState({
                    status: { key: "failed" },
                });
            }
        });
    }
    render() {
        const releaseNotesStatus = ReleaseNotesUtils.getStatus();
        let modalContent;
        switch (this.state.status.key) {
            case "loading":
                modalContent = React.createElement(Loader, null);
                break;
            case "ready":
                modalContent = React.createElement(ReactMarkdown, { className: "react-markdown", source: this.state.status.releaseNotes });
                break;
            case "failed":
                modalContent = React.createElement(Header, { as: "h2", content: i18n.t("releaseNotes.noReleaseNotes") });
                break;
        }
        return (React.createElement(Modal, { size: "small", open: this.props.open, onClose: this.props.onClose },
            React.createElement(Header, { content: i18n.t("releaseNotes.versionX", { version: config.version }) }),
            React.createElement(Modal.Content, { style: { height: "300px", overflowY: "auto" } }, modalContent),
            React.createElement(Modal.Actions, null,
                releaseNotesStatus == "show" ? (React.createElement(Button, { negative: true, content: i18n.t("releaseNotes.neverShow"), onClick: () => {
                        ReleaseNotesUtils.setStatus("never");
                        this.props.onClose();
                    } })) : null,
                React.createElement(Button, { content: i18n.t("general.close"), onClick: () => {
                        if (releaseNotesStatus == "show") {
                            ReleaseNotesUtils.setStatus("hide");
                        }
                        this.props.onClose();
                    } }))));
    }
}
