import * as React from 'react';
import { Popup } from 'semantic-ui-react';
export class KeyValueView extends React.Component {
    render() {
        const keyWidth = this.props.keyWidth || "100px";
        const key = React.createElement("div", { style: { color: "#b0b0b0", minWidth: keyWidth, width: keyWidth, marginRight: "8px", textAlign: "right" } }, this.props.keyNode);
        return (React.createElement("div", { style: { paddingBottom: "8px", display: "flex", flexDirection: "row" } },
            this.props.keyTooltip == null ?
                key : (React.createElement(Popup, { trigger: key, content: this.props.keyTooltip, position: "top center" })),
            React.createElement("div", null, this.props.valueNode)));
    }
}
