import { ParseUtils } from "@bryxinc/lunch";
export class ApiError {
    constructor(realCause, message) {
        this.realCause = realCause;
        this.message = message;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new ApiError(ParseUtils.getStringOrNull(o, "realCause"), ParseUtils.getString(o, "message")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Api Error: ${e.message}`);
        }
    }
}
export class PageResult {
    constructor(count, items) {
        this.count = count;
        this.items = items;
    }
    static parse(o, itemsKey, itemParser) {
        try {
            return ParseUtils.parseSuccess(new PageResult(ParseUtils.getNumber(o, "count"), ParseUtils.getArrayOfSubobjects(o, itemsKey, itemParser, "throw")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid PageResult Model: ${e.message}`);
        }
    }
}
