import { BryxLocal } from "./bryxLocal";
export class ReleaseNotesUtils {
    static getStatus() {
        switch (BryxLocal.getShowReleaseNotes()) {
            case "show":
                return "show";
            case "never":
                return "never";
            default:
                return "hide";
        }
    }
    static setStatus(status) {
        BryxLocal.setShowReleaseNotes(status);
    }
}
