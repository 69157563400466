export class AudioUtils {
    static rawResourceFromId(id) {
        return new Audio(`/resources/audio/${id}.mp3`);
    }
    static resourceFromSoundId(id) {
        const matchedSounds = AudioUtils.supportedTones.filter(tone => tone.soundId == id);
        if (matchedSounds.length != 0) {
            return matchedSounds[0].element;
        }
        else {
            return AudioUtils.supportedTones[0].element;
        }
    }
}
AudioUtils.supportedSoundIds = [
    "standard",
    "standard1k",
    "continuous",
    "station51",
    "dtmf",
    "henf",
    "251",
    "254",
    "068",
    "1465_950",
    "standard_long",
    "sd_10_long",
];
AudioUtils.supportedTones = AudioUtils.supportedSoundIds.map(id => ({ soundId: id, element: AudioUtils.rawResourceFromId(id) }));
AudioUtils.clickSound = AudioUtils.rawResourceFromId("click");
