import * as i18n from "i18next";
import * as React from 'react';
import { Button, Header, Icon, Loader, Menu, Popup } from "semantic-ui-react";
import { config } from "../config";
import { ThemeUtils } from "../models/theme";
import { BryxColors } from "../utils/bryxColors";
import { DateUtils } from "../utils/dateUtils";
import { NotificationManager } from "../utils/notificationManager";
import { PreferenceManager } from "../utils/preferenceManager";
import { UpdateManager } from "../utils/updateManager";
import { NotificationItem } from "./notificationItem";
import { ZeroStateView } from "./zeroStateView";
export class NotificationMenu extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.timerId = null;
        this.state = {
            currentTime: DateUtils.bryxNow(),
            preferences: PreferenceManager.shared.preferences,
        };
    }
    componentDidMount() {
        this.timerId = window.setInterval(() => this.setState({ currentTime: DateUtils.bryxNow() }), 1000);
        PreferenceManager.shared.registerObserver(this);
    }
    componentWillUnmount() {
        if (this.timerId != null) {
            clearInterval(this.timerId);
        }
        PreferenceManager.shared.unregisterObserver(this);
    }
    preferencesManagerDidUpdatePreferences(newPrefs) {
        this.setState({ preferences: newPrefs });
    }
    render() {
        const { status } = this.props;
        const hasNotifications = status.key == "active" && status.notifications.length != 0;
        let content;
        if (status.key == "loading") {
            content = (React.createElement("div", { className: "flexCenteredContainer" },
                React.createElement(Loader, { inline: true, active: true })));
        }
        else if (status.key == "failed") {
            content = (React.createElement("div", { className: "flexCenteredContainer", style: { height: "100%" } },
                React.createElement(ZeroStateView, { header: i18n.t("notifications.failedToLoad"), subheader: React.createElement("div", { style: { display: "flex", flexDirection: "column", alignItems: "center" } },
                        React.createElement("span", { style: { marginBottom: "10px" } }, status.message),
                        React.createElement(Button, { onClick: UpdateManager.shared.update }, i18n.t("general.reload"))), icon: "warning sign" })));
        }
        else if (status.notifications.length == 0) {
            content = (React.createElement("div", { className: "flexCenteredContainer" },
                React.createElement("span", { style: { display: "block", color: BryxColors.gray, fontSize: "23px" } }, i18n.t("notifications.noNotifications"))));
        }
        else {
            content = (React.createElement("div", { style: { flex: 1 } }, status.notifications.map(n => (React.createElement(NotificationItem, { key: n.id, notification: n, currentTime: this.state.currentTime, onClick: () => {
                    this.props.onClose();
                    NotificationManager.shared.notifyClickNotification(n);
                }, onClear: () => NotificationManager.shared.notifyClearNotification(n) })))));
        }
        return (React.createElement(Popup, { id: "notificationsMenu", on: "click", position: "bottom right", className: ThemeUtils.getClassName(this.state.preferences.theme), open: this.props.open, onOpen: () => {
                this.props.onOpen();
                config.debug("notificationMenu: onOpen");
            }, onClose: () => {
                this.props.onClose();
                config.debug("notificationMenu: onClose");
            }, trigger: React.createElement(Menu.Item, { id: "notification-group", className: hasNotifications && !this.props.open ? "active" : undefined },
                React.createElement(Icon, { link: true, size: "large", name: "bell", style: { margin: 0 } })) },
            React.createElement(Popup.Header, { style: {
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 10px 5px 16px",
                    minHeight: "45px",
                } },
                React.createElement(Header, { as: "h3", color: "grey", style: { margin: 0 } }, i18n.t("notifications.title")),
                React.createElement("div", { style: { display: "flex", flexDirection: "row", alignItems: "center" } },
                    status.key == "active" && status.notifications.length != 0 ? (React.createElement(Button, { negative: true, compact: true, content: i18n.t("notifications.clear"), onMouseDown: () => NotificationManager.shared.notifyClearAll(), style: { marginRight: "10px", minWidth: "85px" } })) : React.createElement("div", null),
                    React.createElement(Button, { compact: true, content: i18n.t("nav.settings.settings"), onMouseDown: this.props.onClickSettings, style: { minWidth: "85px" } }))),
            React.createElement(Popup.Content, { style: { flex: 1, overflow: "auto", display: "flex" } }, content)));
    }
}
