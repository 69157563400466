/** @module models */
import { ParseUtils } from "@bryxinc/lunch";
export var SCUEventType;
(function (SCUEventType) {
    SCUEventType[SCUEventType["dispatch"] = 0] = "dispatch";
    SCUEventType[SCUEventType["light"] = 1] = "light";
    SCUEventType[SCUEventType["tone"] = 2] = "tone";
    SCUEventType[SCUEventType["tts"] = 3] = "tts";
    SCUEventType[SCUEventType["gpio"] = 4] = "gpio";
    SCUEventType[SCUEventType["print"] = 5] = "print";
    SCUEventType[SCUEventType["genericInfo"] = 6] = "genericInfo";
    SCUEventType[SCUEventType["booted"] = 7] = "booted";
    SCUEventType[SCUEventType["backupNetwork"] = 8] = "backupNetwork";
    SCUEventType[SCUEventType["primaryNetwork"] = 9] = "primaryNetwork";
    SCUEventType[SCUEventType["networkUp"] = 10] = "networkUp";
    SCUEventType[SCUEventType["networkDown"] = 11] = "networkDown";
    SCUEventType[SCUEventType["deviceConnectionRestored"] = 12] = "deviceConnectionRestored";
    SCUEventType[SCUEventType["genericError"] = 13] = "genericError";
    SCUEventType[SCUEventType["audioFailure"] = 14] = "audioFailure";
    SCUEventType[SCUEventType["timeOutOfSync"] = 15] = "timeOutOfSync";
    SCUEventType[SCUEventType["lowDiskSpace"] = 16] = "lowDiskSpace";
    SCUEventType[SCUEventType["printingFailure"] = 17] = "printingFailure";
    SCUEventType[SCUEventType["deviceConnectionLost"] = 18] = "deviceConnectionLost";
    SCUEventType[SCUEventType["hueAuthFailure"] = 19] = "hueAuthFailure";
    SCUEventType[SCUEventType["audioRecorderFailure"] = 20] = "audioRecorderFailure";
    SCUEventType[SCUEventType["ttsFailure"] = 21] = "ttsFailure";
    SCUEventType[SCUEventType["alertingCanceled"] = 22] = "alertingCanceled";
    SCUEventType[SCUEventType["battery"] = 23] = "battery";
    SCUEventType[SCUEventType["doorbellPressed"] = 24] = "doorbellPressed";
})(SCUEventType || (SCUEventType = {}));
export var SCULogLevel;
(function (SCULogLevel) {
    SCULogLevel[SCULogLevel["action"] = 0] = "action";
    SCULogLevel[SCULogLevel["info"] = 1] = "info";
    SCULogLevel[SCULogLevel["error"] = 2] = "error";
})(SCULogLevel || (SCULogLevel = {}));
export var SCUNetworkType;
(function (SCUNetworkType) {
    SCUNetworkType[SCUNetworkType["primary"] = 0] = "primary";
    SCUNetworkType[SCUNetworkType["backup"] = 1] = "backup";
})(SCUNetworkType || (SCUNetworkType = {}));
export class SCUEvent {
    constructor(id, scuId, ts, level, type) {
        this.id = id;
        this.scuId = scuId;
        this.ts = ts;
        this.level = level;
        this.type = type;
    }
    static compare(l1, l2) {
        return l2.ts.getTime() - l1.ts.getTime() || l2.id.localeCompare(l1.id);
    }
    static parse(o) {
        try {
            const id = ParseUtils.getString(o, 'id');
            const scuId = ParseUtils.getString(o, 'scuId');
            const type = ParseUtils.getEnum(o, 'type', SCUEventType);
            const ts = ParseUtils.getUNIXTimestampDate(o, 'ts');
            const level = ParseUtils.getEnum(o, 'level', SCULogLevel);
            switch (type) {
                case SCUEventType.dispatch:
                    return SCUEventDispatch.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.light:
                    return SCUEventLight.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.tone:
                    return SCUEventTone.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.tts:
                    return SCUEventTts.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.gpio:
                    return SCUEventGPIO.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.print:
                    return SCUEventPrint.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.genericInfo:
                    return SCUEventGenericInfo.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.booted:
                    return SCUEventBooted.parseEvent(id, scuId, ts, level);
                case SCUEventType.primaryNetwork:
                    return SCUEventPrimaryNetwork.parseEvent(id, scuId, ts, level);
                case SCUEventType.backupNetwork:
                    return SCUEventBackupNetwork.parseEvent(id, scuId, ts, level);
                case SCUEventType.networkUp:
                    return SCUEventNetworkUp.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.networkDown:
                    return SCUEventNetworkDown.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.deviceConnectionRestored:
                    return SCUEventDeviceConnectionRestored.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.genericError:
                    return SCUEventGenericError.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.audioFailure:
                    return SCUEventAudioFailure.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.timeOutOfSync:
                    return SCUEventTimeOutOfSync.parseEvent(id, scuId, ts, level);
                case SCUEventType.lowDiskSpace:
                    return SCUEventLowDiskSpace.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.printingFailure:
                    return SCUEventPrintingFailure.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.deviceConnectionLost:
                    return SCUEventDeviceConnectionLost.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.hueAuthFailure:
                    return SCUEventHueAuthFailure.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.audioRecorderFailure:
                    return SCUEventAudioRecorderFailure.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.ttsFailure:
                    return SCUEventTtsFailure.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.battery:
                    return SCUEventBattery.parseEvent(id, scuId, ts, level, o);
                case SCUEventType.doorbellPressed:
                    return SCUEventDoorbell.parseEvent(id, scuId, ts, level, o);
                default:
                    return ParseUtils.parseFailure(`Invalid SCUEvent Model: Bad Type ${type}`);
            }
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEvent Model: ${e.message}`);
        }
    }
}
export class SCUEventDispatchJob {
    constructor(id, incidentId, locationOfIncident, ts) {
        this.id = id;
        this.incidentId = incidentId;
        this.locationOfIncident = locationOfIncident;
        this.ts = ts;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventDispatchJob(ParseUtils.getString(o, 'id'), ParseUtils.getStringOrNull(o, 'incidentId'), ParseUtils.getString(o, 'locationOfIncident'), ParseUtils.getUNIXTimestampDate(o, 'ts')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventDispatchJob model ${e.message}`);
        }
    }
}
export var SCUEventDispatchAckType;
(function (SCUEventDispatchAckType) {
    SCUEventDispatchAckType[SCUEventDispatchAckType["pending"] = 0] = "pending";
    SCUEventDispatchAckType[SCUEventDispatchAckType["ack"] = 1] = "ack";
    SCUEventDispatchAckType[SCUEventDispatchAckType["nack"] = 2] = "nack";
})(SCUEventDispatchAckType || (SCUEventDispatchAckType = {}));
export class SCUEventDispatchUnit {
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventDispatchUnit(ParseUtils.getString(o, 'id'), ParseUtils.getString(o, 'name')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventDispatchUnit model: ${e.message}`);
        }
    }
}
export class SCUEventDispatch extends SCUEvent {
    constructor(id, scuId, ts, level, job, ackTime, status, unit) {
        super(id, scuId, ts, level, SCUEventType.dispatch);
        this.job = job;
        this.ackTime = ackTime;
        this.status = status;
        this.unit = unit;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventDispatch(id, scuId, ts, level, ParseUtils.getSubobject(o, 'job', SCUEventDispatchJob.parse), ParseUtils.getUNIXTimestampDateOrNull(o, 'ackTime'), ParseUtils.getEnum(o, 'status', SCUEventDispatchAckType), ParseUtils.getSubobject(o, 'unit', SCUEventDispatchUnit.parse)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventDispatch Model: ${e.message}`);
        }
    }
}
export class SCUEventLight extends SCUEvent {
    constructor(id, scuId, ts, level, description, cause) {
        super(id, scuId, ts, level, SCUEventType.light);
        this.description = description;
        this.cause = cause;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventLight(id, scuId, ts, level, ParseUtils.getString(o, 'description'), ParseUtils.getStringOrNull(o, 'cause')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventLight Model: ${e.message}`);
        }
    }
}
export class SCUEventTone extends SCUEvent {
    constructor(id, scuId, ts, level, tone, cause) {
        super(id, scuId, ts, level, SCUEventType.tone);
        this.tone = tone;
        this.cause = cause;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventTone(id, scuId, ts, level, ParseUtils.getString(o, 'tone'), ParseUtils.getStringOrNull(o, 'cause')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventTone Model: ${e.message}`);
        }
    }
}
export class SCUEventTts extends SCUEvent {
    constructor(id, scuId, ts, level, text, cause) {
        super(id, scuId, ts, level, SCUEventType.tts);
        this.text = text;
        this.cause = cause;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventTts(id, scuId, ts, level, ParseUtils.getString(o, 'text'), ParseUtils.getStringOrNull(o, 'cause')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventTts Model: ${e.message}`);
        }
    }
}
export class SCUEventGPIO extends SCUEvent {
    constructor(id, scuId, ts, level, cause, on, relay, device) {
        super(id, scuId, ts, level, SCUEventType.gpio);
        this.cause = cause;
        this.on = on;
        this.relay = relay;
        this.device = device;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventGPIO(id, scuId, ts, level, ParseUtils.getStringOrNull(o, 'cause'), ParseUtils.getBoolean(o, 'on'), ParseUtils.getNumber(o, 'relay'), ParseUtils.getString(o, 'device')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventGPIO Model: ${e.message}`);
        }
    }
}
export class SCUEventPrint extends SCUEvent {
    constructor(id, scuId, ts, level, job, printer, copies) {
        super(id, scuId, ts, level, SCUEventType.print);
        this.job = job;
        this.printer = printer;
        this.copies = copies;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventPrint(id, scuId, ts, level, ParseUtils.getSubobject(o, 'job', SCUEventDispatchJob.parse), ParseUtils.getString(o, 'printer'), ParseUtils.getNumber(o, 'copies')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventPrint Model: ${e.message}`);
        }
    }
}
export class SCUEventGenericInfo extends SCUEvent {
    constructor(id, scuId, ts, level, message) {
        super(id, scuId, ts, level, SCUEventType.genericInfo);
        this.message = message;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventGenericInfo(id, scuId, ts, level, ParseUtils.getString(o, 'message')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventGenericInfo Model: ${e.message}`);
        }
    }
}
export class SCUEventBooted extends SCUEvent {
    constructor(id, scuId, ts, level) {
        super(id, scuId, ts, level, SCUEventType.booted);
    }
    static parseEvent(id, scuId, ts, level) {
        return ParseUtils.parseSuccess(new SCUEventBooted(id, scuId, ts, level));
    }
}
export class SCUEventPrimaryNetwork extends SCUEvent {
    constructor(id, scuId, ts, level) {
        super(id, scuId, ts, level, SCUEventType.primaryNetwork);
    }
    static parseEvent(id, scuId, ts, level) {
        return ParseUtils.parseSuccess(new SCUEventPrimaryNetwork(id, scuId, ts, level));
    }
}
export class SCUEventBackupNetwork extends SCUEvent {
    constructor(id, scuId, ts, level) {
        super(id, scuId, ts, level, SCUEventType.backupNetwork);
    }
    static parseEvent(id, scuId, ts, level) {
        try {
            return ParseUtils.parseSuccess(new SCUEventBackupNetwork(id, scuId, ts, level));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventBackupNetwork Model: ${e.message}`);
        }
    }
}
export class SCUEventNetworkUp extends SCUEvent {
    constructor(id, scuId, ts, level, networkType) {
        super(id, scuId, ts, level, SCUEventType.networkUp);
        this.networkType = networkType;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventNetworkUp(id, scuId, ts, level, ParseUtils.getEnum(o, 'networkType', SCUNetworkType)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventNetworkUp Model: ${e.message}`);
        }
    }
}
export class SCUEventNetworkDown extends SCUEvent {
    constructor(id, scuId, ts, level, networkType) {
        super(id, scuId, ts, level, SCUEventType.networkDown);
        this.networkType = networkType;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventNetworkDown(id, scuId, ts, level, ParseUtils.getEnum(o, 'networkType', SCUNetworkType)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventNetworkDown Model: ${e.message}`);
        }
    }
}
export var SCUEventDeviceType;
(function (SCUEventDeviceType) {
    SCUEventDeviceType[SCUEventDeviceType["hueBridge"] = 0] = "hueBridge";
    SCUEventDeviceType[SCUEventDeviceType["bryxLedController"] = 1] = "bryxLedController";
    SCUEventDeviceType[SCUEventDeviceType["denonMixer"] = 2] = "denonMixer";
    SCUEventDeviceType[SCUEventDeviceType["bryxSpeakerMatrix"] = 3] = "bryxSpeakerMatrix";
    SCUEventDeviceType[SCUEventDeviceType["evokGpio"] = 4] = "evokGpio";
    SCUEventDeviceType[SCUEventDeviceType["doorbirdDoorbell"] = 5] = "doorbirdDoorbell";
})(SCUEventDeviceType || (SCUEventDeviceType = {}));
export class SCUEventDeviceConnectionRestored extends SCUEvent {
    constructor(id, scuId, ts, level, deviceId, deviceType) {
        super(id, scuId, ts, level, SCUEventType.deviceConnectionRestored);
        this.deviceId = deviceId;
        this.deviceType = deviceType;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventDeviceConnectionRestored(id, scuId, ts, level, ParseUtils.getString(o, 'deviceId'), ParseUtils.getEnum(o, 'deviceType', SCUEventDeviceType)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventDeviceConnectionRestored Model: ${e.message}`);
        }
    }
}
export class SCUEventGenericError extends SCUEvent {
    constructor(id, scuId, ts, level, message) {
        super(id, scuId, ts, level, SCUEventType.genericError);
        this.message = message;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventGenericError(id, scuId, ts, level, ParseUtils.getString(o, 'message')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventGenericError Model: ${e.message}`);
        }
    }
}
export class SCUEventAudioFailure extends SCUEvent {
    constructor(id, scuId, ts, level, message) {
        super(id, scuId, ts, level, SCUEventType.audioFailure);
        this.message = message;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventAudioFailure(id, scuId, ts, level, ParseUtils.getString(o, 'message')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventAudioFailure Model: ${e.message}`);
        }
    }
}
export class SCUEventTimeOutOfSync extends SCUEvent {
    constructor(id, scuId, ts, level) {
        super(id, scuId, ts, level, SCUEventType.timeOutOfSync);
    }
    static parseEvent(id, scuId, ts, level) {
        return ParseUtils.parseSuccess(new SCUEventTimeOutOfSync(id, scuId, ts, level));
    }
}
export class SCUEventHueAuthFailure extends SCUEvent {
    constructor(id, scuId, ts, level, message) {
        super(id, scuId, ts, level, SCUEventType.hueAuthFailure);
        this.message = message;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventHueAuthFailure(id, scuId, ts, level, ParseUtils.getString(o, 'message')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventHueAuthFailure Model: ${e.message}`);
        }
    }
}
export class SCUEventAudioRecorderFailure extends SCUEvent {
    constructor(id, scuId, ts, level, message) {
        super(id, scuId, ts, level, SCUEventType.audioRecorderFailure);
        this.message = message;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventAudioRecorderFailure(id, scuId, ts, level, ParseUtils.getString(o, 'message')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventAudioRecorderFailure Model: ${e.message}`);
        }
    }
}
export class SCUEventTtsFailure extends SCUEvent {
    constructor(id, scuId, ts, level, message) {
        super(id, scuId, ts, level, SCUEventType.ttsFailure);
        this.message = message;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventTtsFailure(id, scuId, ts, level, ParseUtils.getString(o, 'message')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventTtsFailure Model: ${e.message}`);
        }
    }
}
export class SCUEventLowDiskSpace extends SCUEvent {
    constructor(id, scuId, ts, level, percent) {
        super(id, scuId, ts, level, SCUEventType.lowDiskSpace);
        this.percent = percent;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventLowDiskSpace(id, scuId, ts, level, ParseUtils.getNumber(o, 'percent')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventLowDiskSpace Model: ${e.message}`);
        }
    }
}
export class SCUEventPrintingFailure extends SCUEvent {
    constructor(id, scuId, ts, level, job, printer) {
        super(id, scuId, ts, level, SCUEventType.printingFailure);
        this.job = job;
        this.printer = printer;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventPrintingFailure(id, scuId, ts, level, ParseUtils.getSubobject(o, 'job', SCUEventDispatchJob.parse), ParseUtils.getString(o, 'printer')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventPrintingFailure Model: ${e.message}`);
        }
    }
}
export class SCUEventDeviceConnectionLost extends SCUEvent {
    constructor(id, scuId, ts, level, deviceId, deviceType) {
        super(id, scuId, ts, level, SCUEventType.deviceConnectionLost);
        this.deviceId = deviceId;
        this.deviceType = deviceType;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventDeviceConnectionLost(id, scuId, ts, level, ParseUtils.getString(o, 'deviceId'), ParseUtils.getEnum(o, 'deviceType', SCUEventDeviceType)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventDeviceConnectionLost Model: ${e.message}`);
        }
    }
}
export var SCURuleDoCancelAlertType;
(function (SCURuleDoCancelAlertType) {
    SCURuleDoCancelAlertType[SCURuleDoCancelAlertType["current"] = 0] = "current";
    SCURuleDoCancelAlertType[SCURuleDoCancelAlertType["queued"] = 1] = "queued";
    SCURuleDoCancelAlertType[SCURuleDoCancelAlertType["all"] = 2] = "all";
})(SCURuleDoCancelAlertType || (SCURuleDoCancelAlertType = {}));
export class SCUEventAlertingCanceled extends SCUEvent {
    constructor(id, scuId, ts, level, cancelType) {
        super(id, scuId, ts, level, SCUEventType.alertingCanceled);
        this.cancelType = cancelType;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventAlertingCanceled(id, scuId, ts, level, ParseUtils.getEnum(o, 'cancelType', SCURuleDoCancelAlertType)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventAlertingCanceled Model: ${e.message}`);
        }
    }
}
export var ScuEventBatteryLevel;
(function (ScuEventBatteryLevel) {
    ScuEventBatteryLevel[ScuEventBatteryLevel["critical"] = 0] = "critical";
    ScuEventBatteryLevel[ScuEventBatteryLevel["warning"] = 1] = "warning";
    ScuEventBatteryLevel[ScuEventBatteryLevel["nominal"] = 2] = "nominal";
})(ScuEventBatteryLevel || (ScuEventBatteryLevel = {}));
export class SCUEventBattery extends SCUEvent {
    constructor(id, scuId, ts, level, charge, batteryLevel) {
        super(id, scuId, ts, level, SCUEventType.battery);
        this.charge = charge;
        this.batteryLevel = batteryLevel;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventBattery(id, scuId, ts, level, ParseUtils.getNumber(o, 'charge'), ParseUtils.getEnum(o, 'batteryLevel', ScuEventBatteryLevel)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventBattery model ${e.message}`);
        }
    }
}
export class SCUEventDoorbell extends SCUEvent {
    constructor(id, scuId, ts, level, doorbell) {
        super(id, scuId, ts, level, SCUEventType.doorbellPressed);
        this.doorbell = doorbell;
    }
    static parseEvent(id, scuId, ts, level, o) {
        try {
            return ParseUtils.parseSuccess(new SCUEventDoorbell(id, scuId, ts, level, ParseUtils.getString(o, 'doorbell')));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUEventDoorbell model ${e.message}`);
        }
    }
}
