import { ParseUtils } from "@bryxinc/lunch";
export class AuthAgency {
    constructor(id, name, units, dispatcher, scuManager) {
        this.id = id;
        this.name = name;
        this.units = units;
        this.dispatcher = dispatcher;
        this.scuManager = scuManager;
    }
    static parse(o) {
        try {
            const dispatcher = ParseUtils.getBoolean(o, "dispatcher");
            const scuManager = ParseUtils.getBoolean(o, "scuManager");
            if (!dispatcher && !scuManager) {
                return ParseUtils.parseFailure(`Invalid Agency: neither dispatcher nor scuManager`);
            }
            return ParseUtils.parseSuccess(new AuthAgency(ParseUtils.getString(o, "_id"), ParseUtils.getString(o, "name"), ParseUtils.getArrayOfSubobjects(o, "units", AuthUnit.parse, "throw"), dispatcher, scuManager));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Agency: ${e.message}`);
        }
    }
}
AuthAgency.notifyReplace = () => null;
export class AuthUnit {
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new AuthUnit(ParseUtils.getString(o, "id"), ParseUtils.getString(o, "name")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Unit: ${e.message}`);
        }
    }
}
export class Auth {
    constructor(apiKey, showEula, allowNotifications, agencies) {
        this.apiKey = apiKey;
        this.showEula = showEula;
        this.allowNotifications = allowNotifications;
        this.agencies = agencies;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new Auth(ParseUtils.getString(o, "apiKey"), ParseUtils.getBoolean(o, "showEula"), ParseUtils.getBoolean(o, "receivePush"), ParseUtils.getArrayOfSubobjects(o, "agencies", AuthAgency.parse, "throw")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Auth Model: ${e.message}`);
        }
    }
}
