import { ParseUtils } from "@bryxinc/lunch";
export var MapClientType;
(function (MapClientType) {
    MapClientType[MapClientType["user"] = 0] = "user";
    MapClientType[MapClientType["apparatus"] = 1] = "apparatus";
})(MapClientType || (MapClientType = {}));
export class MapClient {
    constructor(id, info, location, lastUpdated) {
        this.id = id;
        this.info = info;
        this.location = location;
        this.lastUpdated = lastUpdated;
    }
    get name() {
        return this.info.commonName;
    }
    get initials() {
        return this.info.initials;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new MapClient(ParseUtils.getString(o, "id"), {
                type: ParseUtils.getEnum(o, "type", MapClientType),
                commonName: ParseUtils.getString(o, "name"),
                initials: ParseUtils.getString(o, "initials"),
            }, o["location"], ParseUtils.getUNIXTimestampDate(o["location"]["properties"]["lastUpdated"], "sec")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid MapClient Model: ${e.message}`);
        }
    }
}
