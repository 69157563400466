import { ParseUtils } from "@bryxinc/lunch";
import * as i18n from "i18next";
import { JobType } from "./jobTypeInformation";
import { SCULogLevel } from "./scuEvent";
export var BryxNotificationType;
(function (BryxNotificationType) {
    BryxNotificationType[BryxNotificationType["job"] = 0] = "job";
    BryxNotificationType[BryxNotificationType["supplemental"] = 1] = "supplemental";
    BryxNotificationType[BryxNotificationType["message"] = 2] = "message";
    BryxNotificationType[BryxNotificationType["scu"] = 3] = "scu";
})(BryxNotificationType || (BryxNotificationType = {}));
export var BryxNotificationAlertType;
(function (BryxNotificationAlertType) {
    BryxNotificationAlertType[BryxNotificationAlertType["none"] = 0] = "none";
    BryxNotificationAlertType[BryxNotificationAlertType["sound"] = 1] = "sound";
})(BryxNotificationAlertType || (BryxNotificationAlertType = {}));
function parseBryxNotificationAlert(o) {
    try {
        const type = ParseUtils.getEnum(o, "type", BryxNotificationAlertType);
        switch (type) {
            case BryxNotificationAlertType.none:
                return ParseUtils.parseSuccess({
                    type: BryxNotificationAlertType.none,
                });
            case BryxNotificationAlertType.sound:
                return ParseUtils.parseSuccess({
                    type: BryxNotificationAlertType.sound,
                    soundId: ParseUtils.getString(o, "soundId"),
                });
            default:
                return ParseUtils.parseFailure("Case not possible");
        }
    }
    catch (e) {
        return ParseUtils.parseFailure(`Invalid BryxNotificationAlert Model: ${e.message}`);
    }
}
export class BryxNotification {
    constructor(id, date, expirationDate, content, title, message, alert) {
        this.id = id;
        this.date = date;
        this.expirationDate = expirationDate;
        this.content = content;
        this.title = title;
        this.message = message;
        this.alert = alert;
    }
    static parse(o) {
        try {
            const type = ParseUtils.getEnum(o, "type", BryxNotificationType);
            let title = ParseUtils.getString(o, "title");
            let content;
            switch (type) {
                case BryxNotificationType.job:
                    content = {
                        type: BryxNotificationType.job,
                        jobId: ParseUtils.getString(o["content"], "id"),
                        jobType: ParseUtils.getEnum(o["content"], "type", JobType),
                    };
                    break;
                case BryxNotificationType.supplemental:
                    if (title == "") {
                        title = i18n.t("notifications.supplementalUpdate");
                    }
                    content = {
                        type: BryxNotificationType.supplemental,
                        jobId: ParseUtils.getString(o["content"], "id"),
                    };
                    break;
                case BryxNotificationType.message:
                    content = {
                        type: BryxNotificationType.message,
                        groupId: ParseUtils.getString(o["content"], "id"),
                    };
                    break;
                case BryxNotificationType.scu:
                    content = {
                        type: BryxNotificationType.scu,
                        scuId: ParseUtils.getString(o["content"], "id"),
                        level: ParseUtils.getEnum(o["content"], "level", SCULogLevel),
                    };
                    break;
                default:
                    return ParseUtils.parseFailure("Case not possible");
            }
            return ParseUtils.parseSuccess(new BryxNotification(ParseUtils.getString(o, "id"), ParseUtils.getUNIXTimestampDate(o, "ts"), ParseUtils.getUNIXTimestampDate(o, "expirationTs"), content, title, ParseUtils.getString(o, "message"), ParseUtils.getSubobject(o, "alert", parseBryxNotificationAlert)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid BryxNotification Model: ${e.message}`);
        }
    }
    static compare(n1, n2) {
        return n2.date.getTime() - n1.date.getTime() || n1.id.localeCompare(n2.id);
    }
}
