import * as base64 from 'base-64';
import * as utf8 from 'utf8';
import { config } from "../config";
import { BryxLocal } from "./bryxLocal";
import { DeviceUtils } from "./deviceUtils";
export class SupportUtils {
    static bryxItemsAttachment() {
        return SupportUtils.utf8Base64Encode(JSON.stringify(BryxLocal.getBryxItems(), null, "    "));
    }
    static logsAttachment() {
        return SupportUtils.utf8Base64Encode(config.log.exportToArray().join("\n"));
    }
    static deviceInfoAttachment() {
        return SupportUtils.utf8Base64Encode(JSON.stringify(DeviceUtils.deviceInfo, null, "    "));
    }
    static utf8Base64Encode(plaintext) {
        const bytes = utf8.encode(plaintext);
        return base64.encode(bytes);
    }
}
