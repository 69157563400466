import * as React from "react";
import { Redirect } from "react-router";
import { Card, Loader, Message } from "semantic-ui-react";
import { DateUtils } from "../../utils/dateUtils";
import { SCUManager } from "../../utils/scuManager";
import { SCUCard } from "./scuCard";
import { StationSheet } from "./stationSheet";
import VideoFeedCard from "./videoFeeds/videoFeedCard";
import VideoFeedGroup from "./videoFeeds/videoFeedGroup";
export class StationsPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.timerId = null;
        this.state = {
            scusListStatus: SCUManager.shared.scusListsStatus,
            now: DateUtils.bryxNow(),
            hiddenVideoFeeds: [],
        };
    }
    componentDidMount() {
        SCUManager.shared.registerListObserver(this);
        this.timerId = window.setInterval(() => this.setState({ now: DateUtils.bryxNow() }), 5000);
    }
    componentWillUnmount() {
        SCUManager.shared.unregisterListObserver(this);
        if (this.timerId != null) {
            window.clearInterval(this.timerId);
        }
    }
    scuManagerDidUpdateScus(status) {
        this.setState({ scusListStatus: status });
    }
    render() {
        const { scusListStatus, hiddenVideoFeeds, now } = this.state;
        const { selectedAgencyId, dispatcherAgencies } = this.props;
        const selectedStationId = this.props.match.params.stationId;
        let selectedScu;
        if (scusListStatus.key == "active" && selectedStationId != null) {
            selectedScu = scusListStatus.scus.filter(s => s.id == selectedStationId)[0];
            if (selectedScu == null) {
                return React.createElement(Redirect, { to: "/stations" });
            }
        }
        else {
            selectedScu = null;
        }
        let scus = scusListStatus.key == "active" ? scusListStatus.scus : [];
        if (selectedAgencyId != null) {
            scus = scus.filter(scu => scu.agencyIds.indexOf(selectedAgencyId) > -1);
        }
        const dispatcherAgencyIds = dispatcherAgencies.map(a => a.id);
        const dismissFeed = (id) => {
            this.setState({
                hiddenVideoFeeds: [...hiddenVideoFeeds, id],
            });
        };
        const videoFeeds = scusListStatus.key == "active" ? scusListStatus.scuVideoFrames : [];
        return (React.createElement("div", { id: "jobsPage", className: "pageContent" },
            scusListStatus.key == "failed" ? React.createElement(Message, { negative: true, content: scusListStatus.message }) : null,
            scusListStatus.key == "loading" ? React.createElement(Loader, { active: true }) : null,
            React.createElement(Card.Group, { style: { margin: "20px" } }, scusListStatus.key == "active" ?
                scus.map(scu => (React.createElement(SCUCard, { key: scu.id, scu: scu, now: now, showDispatch: dispatcherAgencyIds.some(id => scu.agencyIds.indexOf(id) > -1), onClick: () => this.props.history.push(`/stations/${scu.id}`), onDispatchClick: () => this.props.onStationDispatch(scu.agencyIds.filter(i => dispatcherAgencyIds.indexOf(i) > -1), scu.units.map(u => u.groupId)) }))) : null),
            React.createElement(StationSheet, { viewStatus: selectedScu != null ? { key: "shown", scu: selectedScu } : { key: "hidden" }, onClose: () => this.props.history.replace("/stations") }),
            React.createElement(VideoFeedGroup, null, videoFeeds.map((feed) => (!hiddenVideoFeeds.includes(feed.scuId) && feed.frame && React.createElement(VideoFeedCard, { onDismiss: () => dismissFeed(feed.scuId), title: `Doorbell: ${feed.scuName}`, imgData: feed.frame, key: feed.scuId }))))));
    }
}
