import Qty from "js-quantities";
export function range(start, stop, step) {
    if (stop == undefined) {
        stop = start;
        start = 0;
    }
    if (step == undefined) {
        step = 1;
    }
    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }
    const result = [];
    for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }
    return result;
}
export function nullIfBlank(s) {
    return s == "" ? null : s;
}
export function toTitleCase(s) {
    return s.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}
export function arraysEqual(a, b, equalityTest) {
    if (a === b) {
        return true;
    }
    if (a.length != b.length) {
        return false;
    }
    for (let i = 0; i < a.length; ++i) {
        if (!equalityTest(a[i], b[i])) {
            return false;
        }
    }
    return true;
}
export function normalizedQuantity(q, preferences) {
    if (preferences.useMetricUnits) {
        if (q.lt(Qty("500 m"))) {
            return q.to("m").toPrec("1 m");
        }
        else {
            return q.to("km").toPrec("0.1 km");
        }
    }
    else {
        if (q.lt(Qty("1000 ft"))) {
            return q.to("ft").toPrec("1 ft");
        }
        else {
            return q.to("mi").toPrec("0.1 mi");
        }
    }
}
export function enumNames(e) {
    return Object.keys(e).filter(key => !isNaN(Number(e[key])));
}
export function enumItems(e) {
    return Object.keys(e).filter(key => !isNaN(Number(e[key]))).map(n => ({
        name: n,
        value: e[n],
    }));
}
export function parseNumberStatus(numberString) {
    if (numberString == null) {
        return { key: "empty" };
    }
    const num = parseFloat(numberString);
    return !isNaN(num) ? { key: "ok", value: num } : { key: "error" };
}
/** NotificationUtils class. */
export class NotificationUtils {
}
// This function is set/unset by the singleton instance of the main component
NotificationUtils.notify = () => { };
export function safelyUnescapeTTS(str) {
    // this prevents an XSS, just in case
    return (new DOMParser()).parseFromString(str, "text/html").documentElement.textContent || '';
}
