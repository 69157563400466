import { config } from "../config";
import { SCUEvent } from "../models/scuEvent";
import { BryxApi } from "./bryxApi";
import { BryxWebSocket } from "./bryxWebSocket";
export class SCUManager {
    constructor() {
        this.scusListsStatus = { key: "loading" };
        this.activeScuStatus = { key: "notSelected" };
        this.listObservers = [];
        this.activeScuObservers = [];
    }
    static specificSCUSubscriptionKey(scuId) {
        return `scuManager-scu@${scuId}`;
    }
    startLoadingScus() {
        BryxApi.subscribeToSCUs(SCUManager.scuListSubscriptionKey, result => {
            if (result.success) {
                switch (result.value.key) {
                    case "replace":
                        this.updateScusListsStatus({
                            key: "active",
                            scus: result.value.scus,
                            scuVideoFrames: this.scusListsStatus.key == "active" ? this.scusListsStatus.scuVideoFrames : [],
                        });
                        break;
                    case "update":
                        if (this.scusListsStatus.key != "active") {
                            config.error(`Failed to apply SCU list updates, list status = ${this.scusListsStatus.key}`);
                            return;
                        }
                        const scuUpdate = result.value;
                        const targetScu = this.scusListsStatus.scus.filter(s => s.id == scuUpdate.update.scuId)[0];
                        if (targetScu == null) {
                            config.warn(`Got SCU status update for SCU that is not in the list: ${scuUpdate.update.scuId}`);
                            return;
                        }
                        switch (scuUpdate.update.key) {
                            case "statusUpdate":
                                targetScu.status = scuUpdate.update.status;
                                break;
                            case "unitUpdate":
                                targetScu.units = scuUpdate.update.units;
                                break;
                            case "reconnect":
                                BryxWebSocket.shared.reconnect(`wss://${scuUpdate.update.cluster}/api/2.2`);
                                setTimeout(() => this.subscribeToScu(scuUpdate.update.scuId, false), 1000);
                                break;
                            case "videoFeedStart":
                                this.scusListsStatus.scuVideoFrames.push({
                                    scuId: scuUpdate.update.scuId,
                                    scuName: this.scusListsStatus.scus.find(s => s.id == scuUpdate.update.scuId)?.name ?? "UNKNOWN SCU",
                                });
                                // the WS manager will ignore double subscriptions, so we can blindly do this
                                this.subscribeToScu(scuUpdate.update.scuId, false);
                                // make sure that we blow this away in case we don't get a videoFeedStop message
                                setTimeout(() => {
                                    if (this.scusListsStatus.key == "active") {
                                        this.scusListsStatus.scuVideoFrames = this.scusListsStatus.scuVideoFrames.filter(o => o.scuId != scuUpdate.update.scuId);
                                        if (this.activeScuStatus.key != "selected" || this.activeScuStatus.scuId != scuUpdate.update.scuId) {
                                            // only unsubscribe if it isn't the active SCU
                                            BryxApi.unsubscribe(SCUManager.specificSCUSubscriptionKey(scuUpdate.update.scuId));
                                        }
                                    }
                                }, 15000);
                                break;
                            case "videoFeedStop":
                                this.scusListsStatus.scuVideoFrames = this.scusListsStatus.scuVideoFrames.filter(d => d.scuId != scuUpdate.update.scuId);
                                if (this.activeScuStatus.key != "selected" || this.activeScuStatus.scuId != scuUpdate.update.scuId) {
                                    // only unsubscribe if it isn't the active SCU
                                    BryxApi.unsubscribe(SCUManager.specificSCUSubscriptionKey(scuUpdate.update.scuId));
                                }
                                break;
                        }
                        this.updateScusListsStatus(this.scusListsStatus);
                        break;
                }
            }
            else {
                config.warn(`Scus list websocket failed: ${result.debugMessage}`);
                this.updateScusListsStatus({ key: "failed", message: result.message });
            }
        });
    }
    updateScusListsStatus(status) {
        this.scusListsStatus = status;
        this.listObservers.forEach(o => o.scuManagerDidUpdateScus(this.scusListsStatus));
    }
    stopLoadingScus() {
        BryxApi.unsubscribe(SCUManager.scuListSubscriptionKey);
    }
    subscribeToScu(scuId, isActiveScu) {
        BryxApi.subscribeToSCU(SCUManager.specificSCUSubscriptionKey(scuId), scuId, result => {
            if (result.success) {
                switch (result.value.key) {
                    case "replace":
                        if (this.activeScuStatus.key == "selected") {
                            this.activeScuStatus.loadStatus = {
                                key: "events",
                                events: result.value.events.sort(SCUEvent.compare),
                            };
                        }
                        break;
                    case "update":
                        switch (result.value.update.key) {
                            case "newEvent":
                                if (this.activeScuStatus.key == "selected" && this.activeScuStatus.loadStatus.key == "events") {
                                    const event = result.value.update.event;
                                    this.activeScuStatus.loadStatus.events = this.activeScuStatus.loadStatus.events.concat(event).sort(SCUEvent.compare).slice(0, 100);
                                }
                                break;
                            case "videoFeedFrame":
                                if (this.scusListsStatus.key == "active") {
                                    const data = this.scusListsStatus.scuVideoFrames.find(d => d.scuId == scuId);
                                    if (data) {
                                        data.frame = result.value.update.frame;
                                        const existingData = this.scusListsStatus.scuVideoFrames.filter(d => d.scuId != scuId);
                                        existingData.push(data);
                                        this.scusListsStatus.scuVideoFrames = existingData;
                                    }
                                    else {
                                        this.scusListsStatus.scuVideoFrames.push({
                                            scuId,
                                            scuName: this.scusListsStatus.scus.find(s => s.id == scuId)?.name ?? "UNKNOWN SCU",
                                            frame: result.value.update.frame,
                                        });
                                    }
                                }
                                else {
                                    config.warn("Received video frame message but SCU list status is not yet active.");
                                }
                                this.listObservers.forEach(o => o.scuManagerDidUpdateScus(this.scusListsStatus));
                                break;
                        }
                        break;
                }
            }
            else {
                config.warn(`Scu websocket failed: ${result.debugMessage}`);
                if (this.activeScuStatus.key == "selected") {
                    this.activeScuStatus.loadStatus = { key: "failed", message: result.message };
                }
            }
            if (isActiveScu) {
                this.activeScuObservers.forEach(o => o.scuManagerDidUpdateActiveScu(this.activeScuStatus));
            }
        });
    }
    setActiveScu(scuId) {
        if (this.activeScuStatus.key != "notSelected") {
            return;
        }
        this.activeScuStatus = {
            key: "selected",
            scuId: scuId,
            loadStatus: { key: "loading" },
        };
        this.activeScuObservers.forEach(o => o.scuManagerDidUpdateActiveScu(this.activeScuStatus));
        this.subscribeToScu(scuId, true);
    }
    clearActiveScu(scuId) {
        if (this.activeScuStatus.key != "selected" || this.activeScuStatus.scuId != scuId) {
            config.warn("Could not clear active scu; not set or ID did not match");
            return;
        }
        BryxApi.unsubscribe(SCUManager.specificSCUSubscriptionKey(scuId));
        this.activeScuStatus = { key: "notSelected" };
        this.activeScuObservers.forEach(o => o.scuManagerDidUpdateActiveScu(this.activeScuStatus));
    }
    reset() {
        this.updateScusListsStatus({ key: "loading" });
        this.stopLoadingScus();
    }
    // SCUManagerObservers
    registerListObserver(observer) {
        if (this.listObservers.filter(o => o === observer).length == 0) {
            this.listObservers.push(observer);
        }
    }
    unregisterListObserver(observer) {
        const observerIndex = this.listObservers.indexOf(observer);
        if (observerIndex != -1) {
            this.listObservers.splice(observerIndex, 1);
        }
    }
    registerActiveScuObserver(observer) {
        if (this.activeScuObservers.filter(o => o === observer).length == 0) {
            this.activeScuObservers.push(observer);
        }
    }
    unregisterActiveScuObserver(observer) {
        const observerIndex = this.activeScuObservers.indexOf(observer);
        if (observerIndex != -1) {
            this.activeScuObservers.splice(observerIndex, 1);
        }
    }
}
SCUManager.shared = new SCUManager();
SCUManager.scuListSubscriptionKey = "scuManager-list";
