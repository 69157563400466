import { ParseUtils } from "@bryxinc/lunch";
import { apiFailure, apiSuccess } from "../utils/bryxApi";
import { getParseFunction, LayerType, MapLayer } from "./map";
export function parseMapUpdate(message) {
    try {
        switch (message.key) {
            case "serverUpdate":
                const type = ParseUtils.getString(message.data, "type");
                const layer = ParseUtils.getEnum(message.data, "layer", LayerType);
                switch (type) {
                    case "update":
                        return apiSuccess({
                            key: "add",
                            layer: layer,
                            item: ParseUtils.getSubobject(message.data, "item", getParseFunction(layer)),
                        });
                    case "remove":
                        return apiSuccess({
                            key: "remove",
                            layer: layer,
                            id: ParseUtils.getString(message.data, "id"),
                        });
                    default:
                        return apiFailure(null, `Invalid MapUpdate subscribe response type: ${type}`);
                }
            case "subscribeResponse":
                if (message.successful == false) {
                    return apiFailure(message.errorReason, message.errorReason);
                }
                return apiSuccess({
                    key: "replace",
                    layers: ParseUtils.getArrayOfSubobjects(message, "initialData", MapLayer.parse, "throw"),
                });
            default:
                return apiSuccess(null);
        }
    }
    catch (e) {
        return apiFailure(null, `Invalid MapUpdate Model: ${e.message}`);
    }
}
