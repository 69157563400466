import * as React from "react";
import { Card } from "semantic-ui-react";
export const VideoFeedGroup = ({ children }) => (React.createElement(Card.Group, { style: {
        position: "absolute",
        bottom: 0,
        right: 0,
        minWidth: "500px",
        zIndex: 9999999,
    } }, children));
export default VideoFeedGroup;
