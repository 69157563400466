import { ParseUtils } from "@bryxinc/lunch";
import { config } from "../config";
import { BryxApi } from "./bryxApi";
import { BryxLocal } from "./bryxLocal";
function parseWebsocketMessage(o) {
    try {
        const type = ParseUtils.getNumber(o, "type");
        switch (type) {
            case PayloadType.subscribeResponse: // Subscribe Response
                return ParseUtils.parseSuccess({
                    key: "subscribeResponse",
                    successful: ParseUtils.getBoolean(o, "ok"),
                    topic: ParseUtils.getString(o, "topic"),
                    initialData: o["initialData"],
                    errorReason: ParseUtils.getStringOrNull(o, "reason"),
                });
            case PayloadType.unsubscribeResponse: // Unsubscribe Response
                return ParseUtils.parseSuccess({
                    key: "unsubscribeResponse",
                    successful: ParseUtils.getBoolean(o, "ok"),
                    topic: ParseUtils.getString(o, "topic"),
                    errorReason: ParseUtils.getStringOrNull(o, "reason"),
                });
            case PayloadType.serverUpdate: // Server Update
                return ParseUtils.parseSuccess({
                    key: "serverUpdate",
                    topic: ParseUtils.getString(o, "topic"),
                    data: o["data"],
                });
            case PayloadType.acknowledgement: // Server Acknowledgement
                return ParseUtils.parseSuccess({
                    key: "acknowledgement",
                    successful: ParseUtils.getBoolean(o, "ok"),
                    topic: ParseUtils.getString(o, "topic"),
                    id: ParseUtils.getNumber(o, "replyTo"),
                    errorReason: ParseUtils.getStringOrNull(o, "reason"),
                });
            case PayloadType.pingResponse: // Ping Response
                return ParseUtils.parseSuccess({
                    key: "pingResponse",
                    successful: ParseUtils.getBoolean(o, "ok"),
                    id: ParseUtils.getNumber(o, "replyTo"),
                    errorReason: ParseUtils.getStringOrNull(o, "reason"),
                });
            default:
                return ParseUtils.parseFailure(`Invalid BryxWebSocketMessage type: ${type}`);
        }
    }
    catch (e) {
        return ParseUtils.parseFailure(`Invalid BryxWebSocketMessage: ${e.message}`);
    }
}
export var BryxWebSocketState;
(function (BryxWebSocketState) {
    BryxWebSocketState[BryxWebSocketState["normal"] = 0] = "normal";
    BryxWebSocketState[BryxWebSocketState["reconnecting"] = 1] = "reconnecting";
})(BryxWebSocketState || (BryxWebSocketState = {}));
export var PayloadType;
(function (PayloadType) {
    PayloadType[PayloadType["subscribe"] = 0] = "subscribe";
    PayloadType[PayloadType["subscribeResponse"] = 1] = "subscribeResponse";
    PayloadType[PayloadType["unsubscribe"] = 2] = "unsubscribe";
    PayloadType[PayloadType["unsubscribeResponse"] = 3] = "unsubscribeResponse";
    PayloadType[PayloadType["serverUpdate"] = 5] = "serverUpdate";
    PayloadType[PayloadType["update"] = 6] = "update";
    PayloadType[PayloadType["acknowledgement"] = 7] = "acknowledgement";
    PayloadType[PayloadType["pingResponse"] = 9] = "pingResponse";
})(PayloadType || (PayloadType = {}));
export class BryxWebSocket {
    constructor() {
        this.wsUrl = null;
        this.websocketConnection = null;
        this.pingTimerId = null;
        this.awaitingPingResponse = false;
        this.keysToCallbacks = {};
        this.ackCallbacks = {};
        this.topics = [];
        this.retryWaitTime = 0;
        this.identifierCount = 0;
        this.stateObservers = [];
        this.suspended = false;
        this.state = BryxWebSocketState.normal;
    }
    newIdentifier() {
        return ++this.identifierCount;
    }
    open() {
        const apiKey = BryxLocal.getApiKey();
        if (apiKey != null && this.websocketConnection == null && !this.suspended && Object.keys(this.keysToCallbacks).length > 0) {
            this.websocketConnection = new WebSocket(`${this.wsUrl || BryxApi.wsUrl}/ws?bryxType=dispatch&apiKey=${apiKey}`);
            this.websocketConnection.onopen = this.onOpen.bind(this);
            this.websocketConnection.onclose = this.onClose.bind(this);
            this.websocketConnection.onmessage = this.onMessage.bind(this);
            this.websocketConnection.onerror = this.onError.bind(this);
            this.pingTimerId = window.setInterval(() => {
                if (this.awaitingPingResponse) {
                    config.warn("Missed ping, attempting reconnect...");
                    this.updateState(BryxWebSocketState.reconnecting);
                    this.close();
                    return;
                }
                this.awaitingPingResponse = true;
                this.sendJSON({
                    id: this.newIdentifier(),
                    type: 8,
                });
            }, BryxWebSocket.pingTimeInterval);
        }
    }
    clearPingTimer() {
        if (this.pingTimerId != null) {
            clearInterval(this.pingTimerId);
            this.pingTimerId = null;
            this.awaitingPingResponse = false;
        }
    }
    close() {
        const websocketConnection = this.websocketConnection;
        if (websocketConnection != null) {
            websocketConnection.close();
            this.websocketConnection = null;
            this.clearPingTimer();
        }
    }
    reconnect(wsUrl = BryxApi.wsUrl) {
        config.info("Reconnecting to new WebSocket endpoint");
        this.wsUrl = wsUrl;
        this.close(); // wait for the reconnect handler to handle it
    }
    suspend() {
        this.suspended = true;
        this.close();
    }
    resume() {
        this.suspended = false;
        this.open();
    }
    reset() {
        this.keysToCallbacks = {};
        this.ackCallbacks = {};
        this.topics = [];
        this.identifierCount = 0;
        this.close();
    }
    toggleFakeDisconnect() {
        if (!this.suspended) {
            this.updateState(BryxWebSocketState.reconnecting);
            this.suspend();
        }
        else {
            this.resume();
        }
    }
    addSubscriber(key, topicString, onUpdate, version, params = {}) {
        if (this.keysToCallbacks[key] != null) {
            // Ignore double subscriptions
            return;
        }
        this.addTopicIfRequired(topicString, version, params);
        this.keysToCallbacks[key] = { topicString: topicString, onUpdate: onUpdate };
        if (this.websocketConnection == null) {
            config.info("Got first subscriber, opening WebSocket");
            this.open();
        }
    }
    changeSubscription(key, topicString, params = {}, resubscribe = true) {
        const existingTopic = this.keysToCallbacks[key];
        if (existingTopic == null) {
            return;
        }
        if (topicString != existingTopic.topicString) {
            config.error("Trying to change subscription, provided subscription key for the wrong topic");
            return;
        }
        const topic = this.requestForTopic(topicString);
        if (topic == null) {
            return;
        }
        if (params != null) {
            topic.params = params;
        }
        if (resubscribe) {
            this.sendTopicRequest(topic, PayloadType.subscribe);
        }
    }
    removeSubscriber(key) {
        const subscription = this.keysToCallbacks[key];
        delete this.keysToCallbacks[key];
        if (subscription != null) {
            this.removeTopicIfRequired(subscription.topicString);
        }
        if (Object.keys(this.keysToCallbacks).length == 0 && this.websocketConnection != null) {
            config.info("No more subscribers, closing");
            this.close();
        }
    }
    addTopicIfRequired(topic, version, params) {
        if (this.requestForTopic(topic) != null) {
            return;
        }
        const request = {
            topic: topic,
            version: version != null ? version : null,
            params: params != null ? params : null,
        };
        this.sendTopicRequest(request, PayloadType.subscribe);
        this.topics.push(request);
    }
    removeTopicIfRequired(topic) {
        const matchedRequest = this.requestForTopic(topic);
        if (matchedRequest == null) {
            // We aren't tracking this topic
            return;
        }
        const remainingSubscribers = Object.keys(this.keysToCallbacks)
            .map(k => this.keysToCallbacks[k])
            .filter(subscription => subscription.topicString == topic);
        if (remainingSubscribers.length != 0) {
            // There are still subscribers for this topic
            return;
        }
        this.sendTopicRequest(matchedRequest, PayloadType.unsubscribe);
        this.topics.splice(this.topics.indexOf(matchedRequest));
    }
    sendUpdate(topic, data, completion) {
        const matchedRequest = this.requestForTopic(topic);
        if (matchedRequest == null) {
            config.warn("Not sending update because we are no longer subscribed");
            return;
        }
        const id = this.newIdentifier();
        this.sendJSON({
            type: PayloadType.update,
            topic: topic,
            id: id,
            data: data,
        });
        this.ackCallbacks[id] = completion;
    }
    requestForTopic(topic) {
        const matchedTopics = this.topics.filter(t => t.topic == topic);
        if (matchedTopics.length != 0) {
            return matchedTopics[0];
        }
        else {
            return null;
        }
    }
    sendJSON(json) {
        const websocketConnection = this.websocketConnection;
        if (websocketConnection != null && websocketConnection.readyState == WebSocket.OPEN) {
            websocketConnection.send(JSON.stringify(json));
        }
    }
    sendTopicRequest(request, type) {
        if (type != PayloadType.subscribe && type != PayloadType.unsubscribe) {
            config.error(`Cannot send topic request with payload type: ${type}`);
            return;
        }
        const topicRequest = {
            type: type,
            topic: request.topic,
            id: this.newIdentifier(),
        };
        if (request.params != null && type == PayloadType.subscribe) {
            topicRequest["params"] = request.params;
        }
        if (request.version != null && type == PayloadType.subscribe) {
            topicRequest["version"] = request.version;
        }
        this.sendJSON(topicRequest);
    }
    updateState(state) {
        config.info(`WebSocket state changed: ${BryxWebSocketState[state]}`);
        this.state = state;
        this.stateObservers.forEach(observer => observer.websocketStateDidChange(state));
    }
    reconnectIfRequired() {
        // null the connection so it can't be used
        this.websocketConnection = null;
        // Stop the ping timer
        this.clearPingTimer();
        // Don't reconnect if the websocket was manually suspended
        if (this.suspended) {
            return;
        }
        // Don't reconnect if there are no subscribed clients
        if (Object.keys(this.keysToCallbacks).length == 0) {
            this.updateState(BryxWebSocketState.normal);
            return;
        }
        this.updateState(BryxWebSocketState.reconnecting);
        setTimeout(() => {
            if (this.state != BryxWebSocketState.normal && this.retryWaitTime != 0) {
                this.open();
            }
        }, this.retryWaitTime * 1000);
        // Don't sleep the first time, then start exponential growth.
        // Keep growing until sleep time exceeds maximum.
        if (this.retryWaitTime == 0) {
            this.retryWaitTime = BryxWebSocket.retryInitialWaitTime;
        }
        else if (this.retryWaitTime <= BryxWebSocket.maxRetryWaitTime) {
            this.retryWaitTime *= BryxWebSocket.retryWaitTimeGrowthFactor;
        }
    }
    handleMessage(message) {
        switch (message.key) {
            case "subscribeResponse":
                Object.keys(this.keysToCallbacks).map(k => this.keysToCallbacks[k]).forEach(subscription => {
                    if (subscription.topicString == message.topic) {
                        subscription.onUpdate(message);
                    }
                });
                break;
            case "unsubscribeResponse":
                // Ignore
                break;
            case "serverUpdate":
                Object.keys(this.keysToCallbacks).map(k => this.keysToCallbacks[k]).forEach(subscription => {
                    if (subscription.topicString == message.topic) {
                        subscription.onUpdate(message);
                    }
                });
                break;
            case "acknowledgement":
                const callback = this.ackCallbacks[message.id];
                if (callback == null) {
                    return;
                }
                if (message.successful) {
                    callback({ success: true, value: null });
                }
                else {
                    callback({ success: false, message: message.errorReason || "", debugMessage: message.errorReason });
                }
                delete this.ackCallbacks[message.id];
                break;
            case "pingResponse":
                this.awaitingPingResponse = false;
                break;
        }
    }
    // WebSocket Handler Functions
    onOpen(event) {
        config.info(`WebSocket connected - onOpen at ${event.timeStamp}`);
        this.retryWaitTime = 0;
        this.updateState(BryxWebSocketState.normal);
        this.topics.forEach(topic => this.sendTopicRequest(topic, PayloadType.subscribe));
    }
    onClose(event) {
        config.info(`WebSocket onClose (${event.code}) ${event.reason}`, event);
        this.reconnectIfRequired();
    }
    onMessage(event) {
        const websocketPayload = JSON.parse(event.data);
        const websocketMessage = parseWebsocketMessage(websocketPayload);
        if (websocketMessage.success == true) {
            this.handleMessage(websocketMessage.value);
        }
        else {
            config.error(`Failed to parse WebSocket message: ${websocketMessage.justification}`);
        }
    }
    onError(event) {
        config.info(`WebSocket onError (${event.type})`);
        this.reconnectIfRequired();
    }
    // State Observers
    registerStateObserver(observer) {
        if (this.stateObservers.filter(o => o === observer).length == 0) {
            this.stateObservers.push(observer);
        }
    }
    unregisterStateObserver(observer) {
        const observerIndex = this.stateObservers.indexOf(observer);
        if (observerIndex != -1) {
            this.stateObservers.splice(observerIndex, 1);
        }
    }
}
BryxWebSocket.retryInitialWaitTime = 2.0;
BryxWebSocket.retryWaitTimeGrowthFactor = 2.0;
BryxWebSocket.maxRetryWaitTime = 60.0;
BryxWebSocket.pingTimeInterval = 20 * 1000; // 20 seconds
BryxWebSocket.shared = new BryxWebSocket();
