import { ParseUtils } from "@bryxinc/lunch";
import { apiFailure, apiSuccess } from "../utils/bryxApi";
import { SCU, SCUUnit, SCUUtils } from "./scu";
export class ScusListUtils {
    static parseScusListServerUpdate(o) {
        try {
            const type = ParseUtils.getString(o, "type");
            switch (type) {
                case "statusUpdate":
                    return ParseUtils.parseSuccess({
                        key: "statusUpdate",
                        scuId: ParseUtils.getString(o, "scuId"),
                        status: ParseUtils.getSubobject(o, "status", SCUUtils.parseSCUStatus),
                    });
                case "unitUpdate":
                    return ParseUtils.parseSuccess({
                        key: "unitUpdate",
                        scuId: ParseUtils.getString(o, "scuId"),
                        units: ParseUtils.getArrayOfSubobjects(o, "units", SCUUnit.parse, "warn"),
                    });
                case "reconnect":
                    return ParseUtils.parseSuccess({
                        key: "reconnect",
                        scuId: ParseUtils.getString(o, "scuId"),
                        cluster: ParseUtils.getString(o, "cluster"),
                    });
                case "videoFeedStart":
                    return ParseUtils.parseSuccess({
                        key: "videoFeedStart",
                        scuId: ParseUtils.getString(o, "scuId"),
                    });
                case "videoFeedStop":
                    return ParseUtils.parseSuccess({
                        key: "videoFeedStop",
                        scuId: ParseUtils.getString(o, "scuId"),
                    });
                default:
                    return ParseUtils.parseFailure(`Invalid ScusListServerUpdate type: ${type}`);
            }
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid ScusListServerUpdate Model: ${e.message}`);
        }
    }
    static parseScusListUpdate(message) {
        try {
            switch (message.key) {
                case "serverUpdate":
                    return apiSuccess({
                        key: "update",
                        update: ParseUtils.getSubobject({ data: message.data }, "data", ScusListUtils.parseScusListServerUpdate),
                    });
                case "subscribeResponse":
                    if (message.successful == false) {
                        return apiFailure(message.errorReason, message.errorReason);
                    }
                    return apiSuccess({
                        key: "replace",
                        scus: ParseUtils.getArrayOfSubobjects(message, "initialData", SCU.parse, "warn"),
                    });
                default:
                    return apiSuccess(null);
            }
        }
        catch (e) {
            return apiFailure(null, `Invalid ScusListUpdate Model: ${e.message}`);
        }
    }
}
