import * as i18n from "i18next";
import * as React from "react";
import { Checkbox, Form, Header, Icon, Loader, Message, Segment } from "semantic-ui-react";
import { ZeroStateView } from "../../components/zeroStateView";
import { config } from "../../config";
import { AlertSettingType, ShiftStatus } from "../../models/shift";
import { AudioUtils } from "../../utils/audioUtils";
import { BryxApi } from "../../utils/bryxApi";
export class AlertsTab extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.currentlyPlayingAudio = null;
        this.state = {
            status: { key: "initializing" },
        };
    }
    componentDidMount() {
        BryxApi.getShifts(result => {
            if (result.success == true) {
                this.setState({ status: { key: "ready", shifts: result.value, selectedShift: null, updating: false } });
            }
            else {
                config.warn(`Failed to load shifts: ${result.debugMessage}`);
                this.setState({ status: { key: "failed", message: result.message } });
            }
        });
    }
    componentWillUnmount() {
        this.stopCurrentAudio();
    }
    stopCurrentAudio() {
        if (this.currentlyPlayingAudio != null) {
            this.currentlyPlayingAudio.pause();
            this.currentlyPlayingAudio.currentTime = 0;
        }
    }
    previewSound(soundId) {
        this.stopCurrentAudio();
        const newAudio = AudioUtils.resourceFromSoundId(soundId);
        newAudio.play();
        this.currentlyPlayingAudio = newAudio;
    }
    setUpdating() {
        this.setState(prevState => {
            const prevStatus = prevState.status;
            if (prevStatus.key == "ready") {
                return {
                    status: {
                        key: "ready",
                        selectedShift: prevStatus.selectedShift,
                        shifts: prevStatus.shifts,
                        updating: true,
                    },
                };
            }
            else {
                return prevState;
            }
        });
    }
    render() {
        const { status } = this.state;
        if (status.key == "initializing") {
            return (React.createElement("div", { className: "alertSettingsContent" },
                React.createElement(Loader, null)));
        }
        else if (status.key == "failed") {
            return (React.createElement("div", { className: "alertSettingsContent" },
                React.createElement(Message, { negative: true, content: status.message })));
        }
        else if (status.shifts.length == 0) {
            return (React.createElement("div", { className: "alertSettingsContent flexCenteredContainer" },
                React.createElement(ZeroStateView, { header: i18n.t("settings.alerts.noAlertSettings.title"), subheader: i18n.t("settings.alerts.noAlertSettings.body") })));
        }
        return (React.createElement("div", { className: "alertSettingsContent" }, status.shifts.map(s => (React.createElement(ShiftItem, { key: s.id, shift: s, selected: status.selectedShift != null && status.selectedShift.id == s.id, loading: status.selectedShift != null && status.selectedShift.id == s.id && status.updating, onClick: () => this.setState(prevState => {
                const prevStatus = prevState.status;
                if (prevStatus.key == "ready") {
                    return {
                        status: {
                            key: "ready",
                            selectedShift: prevStatus.selectedShift != null && prevStatus.selectedShift.id == s.id ? null : s,
                            shifts: prevStatus.shifts,
                            updating: prevStatus.updating,
                        },
                    };
                }
                else {
                    return prevState;
                }
            }), onChangeShiftStatus: newStatus => {
                this.setUpdating();
                BryxApi.changeShiftStatus(s, newStatus, result => {
                    if (result.success == true) {
                        this.setState(prevState => {
                            const prevStatus = prevState.status;
                            if (prevStatus.key == "ready") {
                                const changedShift = prevStatus.shifts.filter(target => target.id == s.id)[0];
                                changedShift.status = newStatus;
                                prevStatus.updating = false;
                            }
                        });
                    }
                    else {
                        config.warn(`Failed to update shift status: ${result.debugMessage}`);
                        this.setState({ status: { key: "failed", message: result.message } });
                    }
                });
            }, onChangeAlertSetting: (filter, newSetting) => {
                this.setUpdating();
                if (newSetting.type == AlertSettingType.audio) {
                    this.previewSound(newSetting.soundId);
                }
                BryxApi.changeAlertSetting(s, filter, newSetting, result => {
                    if (result.success == true) {
                        this.setState(prevState => {
                            const prevStatus = prevState.status;
                            if (prevStatus.key == "ready") {
                                const changedShift = prevStatus.shifts.filter(target => target.id == s.id)[0];
                                const changedFilter = changedShift.filters.filter(target => target.id == filter.id)[0];
                                changedFilter.alert = newSetting;
                                prevStatus.updating = false;
                            }
                        });
                    }
                    else {
                        config.warn(`Failed to update alert setting: ${result.debugMessage}`);
                        this.setState({ status: { key: "failed", message: result.message } });
                    }
                });
            } })))));
    }
}
export class ShiftItem extends React.Component {
    render() {
        const { shift, selected, onClick, loading, onChangeShiftStatus, onChangeAlertSetting } = this.props;
        const alertIds = ["none", "silent"].concat(AudioUtils.supportedSoundIds);
        const alertOptions = alertIds.map(id => ({
            key: id,
            value: id,
            text: i18n.t(`sounds.${id}`),
        }));
        return (React.createElement("div", { className: "shiftItem" },
            React.createElement(Segment, { className: "clickableSegment", attached: selected ? "top" : undefined, style: { boxShadow: "none" }, onClick: onClick },
                React.createElement("div", { className: "shiftHeaderContainer", style: {
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    } },
                    React.createElement(Header, { as: "h4", className: "truncate", content: shift.name, style: { margin: 0 } }),
                    React.createElement("div", { style: { display: "flex", flexDirection: "row", alignItems: "center", pointerEvents: "none" } },
                        React.createElement(Checkbox, { toggle: true, className: "bryxToggle", disabled: loading, checked: shift.status != ShiftStatus.forceOff, onChange: (event, data) => {
                                event.stopPropagation();
                                const newStatus = (data.checked || false) ? ShiftStatus.forceOn : ShiftStatus.forceOff;
                                onChangeShiftStatus(newStatus);
                            }, style: { pointerEvents: "auto" } }),
                        React.createElement(Icon, { color: "grey", name: selected ? "chevron down" : "chevron right", style: { marginLeft: "10px" } })))),
            selected ? (React.createElement(Segment, { attached: "bottom", style: { backgroundColor: "#fafafa" } },
                React.createElement(Form, null, shift.filters.map(f => {
                    let selectedValue;
                    switch (f.alert.type) {
                        case AlertSettingType.none:
                            selectedValue = "none";
                            break;
                        case AlertSettingType.silent:
                            selectedValue = "silent";
                            break;
                        case AlertSettingType.audio:
                            selectedValue = f.alert.soundId;
                            break;
                        default:
                            config.error("Impossible case");
                            return null;
                    }
                    return (React.createElement(Form.Select, { key: f.id, label: f.description, options: alertOptions, value: selectedValue, onChange: (e, d) => {
                            const newValue = d.value || "none";
                            switch (newValue) {
                                case "none":
                                    onChangeAlertSetting(f, { type: AlertSettingType.none });
                                    break;
                                case "silent":
                                    onChangeAlertSetting(f, { type: AlertSettingType.silent });
                                    break;
                                default:
                                    onChangeAlertSetting(f, {
                                        type: AlertSettingType.audio,
                                        soundId: newValue,
                                    });
                                    break;
                            }
                        } }));
                })))) : null));
    }
}
