import { ParseUtils } from "@bryxinc/lunch";
export class BasicAgencyGroup {
    constructor(id, name, agencyId) {
        this.id = id;
        this.name = name;
        this.agencyId = agencyId;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new BasicAgencyGroup(ParseUtils.getString(o, "id"), ParseUtils.getString(o, "name"), ParseUtils.getString(o, "agencyId")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid BasicAgencyGroup: ${e.message}`);
        }
    }
    static compare(a, b) {
        return a.name.localeCompare(b.name);
    }
}
