import { ParseUtils } from "@bryxinc/lunch";
export class ServerTime {
    constructor(time) {
        this.time = time;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new ServerTime(ParseUtils.getUNIXTimestampDate(o, "ts")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid ServerTime Model: ${e.message}`);
        }
    }
}
