import * as i18n from 'i18next';
import * as React from "react";
import { initReactI18next } from "react-i18next";
import { Redirect } from "react-router";
import { Button, Loader, Modal } from 'semantic-ui-react';
import { ZeroStateView } from "../components/zeroStateView";
import { config } from "../config";
import { Main } from "../index";
import { BryxApi } from "../utils/bryxApi";
import { BryxLocal } from "../utils/bryxLocal";
import XHR from 'i18next-xhr-backend';
import { BryxColors } from "../utils/bryxColors";
export class EulaPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        if (BryxLocal.getShowEula() == true) {
            i18n
                .use(XHR)
                .use(initReactI18next)
                .init({
                lng: BryxLocal.getItem("locale") || "en-US",
                backend: {
                    loadPath: "/resources/locales/{{lng}}.json",
                },
                fallbackLng: "en",
            }, () => {
                this.setState({
                    i18nLoaded: true,
                });
            });
        }
        this.state = {
            status: { key: "loading" },
            i18nLoaded: false,
        };
    }
    componentDidMount() {
        BryxApi.getEula(result => {
            if (result.success == true) {
                this.setState({ status: { key: "ready", eula: result.value.eula } });
            }
            else {
                config.error(`Failed to load EULA: ${result.debugMessage}`);
                this.setState({ status: { key: "error", message: result.message } });
            }
        });
    }
    acceptEula() {
        this.setState({ status: { key: "sending" } });
        BryxApi.acceptEula(result => {
            if (result.success == true) {
                this.setState({ status: { key: "accepted" } });
            }
            else {
                config.warn(`User failed to accept EULA: ${result.debugMessage}`);
                this.setState({ status: { key: "error", message: result.message } });
            }
        });
    }
    render() {
        if (BryxLocal.getShowEula() != true) {
            return React.createElement(Redirect, { to: "/" });
        }
        let content = null;
        if (this.state.status.key == "loading" || this.state.i18nLoaded == false) {
            content = React.createElement(Loader, null);
        }
        else if (this.state.status.key == "error") {
            content = (React.createElement("div", { className: "flexCenteredContainer", style: { width: "100%", height: "100%" } },
                React.createElement(ZeroStateView, { header: i18n.t("eula.errorHeader"), subheader: i18n.t("eula.error"), maxWidth: "50%" })));
        }
        else if (this.state.status.key == "ready") {
            content = this.state.status.eula;
        }
        else if (this.state.status.key == "accepted") {
            if (this.props.location.state && this.props.location.state.from) {
                return React.createElement(Redirect, { to: this.props.location.state.from });
            }
            else {
                return React.createElement(Redirect, { to: "/" });
            }
        }
        else if (this.state.status.key == "denied") {
            return React.createElement(Redirect, { to: "/login" });
        }
        return (React.createElement("div", { style: { height: "100%", width: "100%", backgroundColor: BryxColors.brandRed } },
            React.createElement(Modal, { open: true, dimmer: false, style: { display: "flex", flexDirection: "column" } },
                React.createElement(Modal.Header, null, i18n.t("eula.header")),
                React.createElement(Modal.Content, { style: { flex: 1, display: "flex", flexDirection: "column", whiteSpace: "pre-wrap", height: "500px", padding: 0 } },
                    React.createElement("div", { style: { flex: 1, overflowY: "auto", padding: "15px 20px" } }, content)),
                React.createElement(Modal.Actions, null,
                    React.createElement(Button, { positive: true, loading: this.state.status.key == "sending", onClick: () => this.acceptEula(), content: i18n.t("eula.accept") }),
                    React.createElement(Button, { negative: true, onClick: () => {
                            Main.resetManagers();
                            this.setState({ status: { key: "denied" } });
                        }, disabled: this.state.status.key == "sending", content: i18n.t("eula.deny") })))));
    }
}
