import { ParseUtils } from "@bryxinc/lunch";
export var ShiftStatus;
(function (ShiftStatus) {
    ShiftStatus[ShiftStatus["on"] = 0] = "on";
    ShiftStatus[ShiftStatus["forceOn"] = 1] = "forceOn";
    ShiftStatus[ShiftStatus["forceOff"] = 2] = "forceOff";
})(ShiftStatus || (ShiftStatus = {}));
export class Shift {
    constructor(id, name, status, filters) {
        this.id = id;
        this.name = name;
        this.status = status;
        this.filters = filters;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new Shift(ParseUtils.getString(o, "id"), ParseUtils.getString(o, "name"), ParseUtils.getEnum(o, "scheduleMode", ShiftStatus), ParseUtils.getArrayOfSubobjects(o, "filters", Filter.parse, "warn")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Shift Model: ${e.message}`);
        }
    }
    static compare(s1, s2) {
        return s1.name.localeCompare(s2.name) || s1.id.localeCompare(s2.id);
    }
}
export class Filter {
    constructor(id, type, value, alert, description) {
        this.id = id;
        this.type = type;
        this.value = value;
        this.alert = alert;
        this.description = description;
    }
    static parse(o) {
        try {
            let alert;
            const alertType = ParseUtils.getString(o["alert"], "type");
            switch (alertType) {
                case "audio":
                    alert = {
                        type: AlertSettingType.audio,
                        soundId: ParseUtils.getString(o["alert"], "fileName"),
                    };
                    break;
                case "silence":
                    alert = { type: AlertSettingType.silent };
                    break;
                case "none":
                    alert = { type: AlertSettingType.none };
                    break;
                default:
                    return ParseUtils.parseFailure(`Invalid Filter Model: 'alert.type' was ${alertType}`);
            }
            return ParseUtils.parseSuccess(new Filter(ParseUtils.getString(o, "id"), ParseUtils.getString(o, "type"), ParseUtils.getString(o, "value"), alert, ParseUtils.getString(o, "description")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Filter Model: ${e.message}`);
        }
    }
}
export var AlertSettingType;
(function (AlertSettingType) {
    AlertSettingType[AlertSettingType["none"] = 0] = "none";
    AlertSettingType[AlertSettingType["silent"] = 1] = "silent";
    AlertSettingType[AlertSettingType["audio"] = 2] = "audio";
})(AlertSettingType || (AlertSettingType = {}));
