import * as i18n from "i18next";
import * as React from 'react';
import { Icon } from "semantic-ui-react";
import { BryxNotificationType } from "../models/bryxNotification";
import { SCULogLevel } from "../models/scuEvent";
import { DateUtils } from "../utils/dateUtils";
export class NotificationItem extends React.Component {
    render() {
        const notification = this.props.notification;
        const timeDuration = DateUtils.duration(notification.date, this.props.currentTime).humanize(true);
        let iconName;
        let typeString;
        let iconMargin;
        let iconSize = "big";
        let eventClass = null;
        switch (notification.content.type) {
            case BryxNotificationType.job:
                typeString = i18n.t("notifications.type.job");
                iconName = "marker";
                iconMargin = "0 12px 0 0";
                iconSize = "big";
                break;
            case BryxNotificationType.supplemental:
                typeString = i18n.t("notifications.type.supplemental");
                iconName = "info circle";
                iconMargin = "0 12px 0 0";
                iconSize = "big";
                break;
            case BryxNotificationType.message:
                typeString = i18n.t("notifications.type.message");
                iconName = "mail";
                iconMargin = "0 16px 0 4px";
                iconSize = "large";
                break;
            case BryxNotificationType.scu:
                typeString = i18n.t("notifications.type.scu");
                iconName = "server";
                iconMargin = "0 16px 0 4px";
                iconSize = "large";
                eventClass = `event-${SCULogLevel[notification.content.level]}`;
                break;
            default:
                // Impossible case
                typeString = i18n.t("notifications.type.message");
                iconName = "mail";
                iconMargin = "0 16px 0 4px";
                iconSize = "large";
                break;
        }
        return (React.createElement("div", { className: ["notificationItem", eventClass].filter(c => c != null).join(" "), onMouseDown: this.props.onClick },
            React.createElement("div", { style: { display: "flex", flexDirection: "row", padding: "12px" } },
                React.createElement("div", { style: { display: "flex", flexDirection: "row", alignItems: "center" } },
                    React.createElement(Icon, { name: iconName, size: iconSize, color: "grey", style: { margin: iconMargin } })),
                React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                    React.createElement("span", { className: "title" }, notification.title),
                    React.createElement("span", { className: "message" }, notification.message),
                    React.createElement("span", { className: "info" },
                        typeString,
                        " \u2022 ",
                        timeDuration)),
                React.createElement("div", { style: { flex: 1, display: "flex", flexDirection: "row", justifyContent: "flex-end" } },
                    React.createElement(Icon, { className: "notificationItemX", name: "x", link: true, color: "grey", style: { margin: 0 }, onMouseDown: (e) => {
                            e.stopPropagation();
                            this.props.onClear();
                        } })))));
    }
}
