import * as moment from "moment";
import { config } from "../config";
import { BryxDateFormat } from "./preferenceManager";
export class DateUtils {
    static setOffset(offset) {
        config.info(`Updating system clock offset to ${offset} ms`);
        DateUtils.clockOffset = offset;
    }
    static duration(date1, date2) {
        return moment.duration(moment(date1).diff(date2));
    }
    static preferredTimeFormat(preferences, includeSeconds = true) {
        if (includeSeconds) {
            return preferences.use24HourTime ? "HH:mm:ss" : "hh:mm:ssA";
        }
        else {
            return preferences.use24HourTime ? "HH:mm" : "hh:mm A";
        }
    }
    static preferredDateFormat(preferences, length = "short", includeYear = true) {
        if (length == "short") {
            switch (preferences.preferredDateFormat) {
                case BryxDateFormat.monthDayYear:
                    return `MM-DD${includeYear ? "-YYYY" : ""}`;
                case BryxDateFormat.dayMonthYear:
                    return `DD-MM${includeYear ? "-YYYY" : ""}`;
                case BryxDateFormat.yearMonthDay:
                    return `${includeYear ? "YYYY-" : ""}MM-DD`;
                default:
                    throw Error("Impossible case");
            }
        }
        else {
            switch (preferences.preferredDateFormat) {
                case BryxDateFormat.monthDayYear:
                    return `MMM D${includeYear ? " YYYY" : ""}`;
                case BryxDateFormat.dayMonthYear:
                    return `D MMM${includeYear ? " YYYY" : ""}`;
                case BryxDateFormat.yearMonthDay:
                    return `${includeYear ? "YYYY " : ""}MMM D`;
                default:
                    throw Error("Impossible case");
            }
        }
    }
    static formatDateTime(date, preferences) {
        return moment(date).format(`${DateUtils.preferredTimeFormat(preferences)} [on] ${DateUtils.preferredDateFormat(preferences)}`);
    }
    static formatTime(date, preferences) {
        return moment(date).format(DateUtils.preferredTimeFormat(preferences));
    }
    static formatApproximateDateTime(date, preferences) {
        const target = moment(date);
        const now = moment(DateUtils.bryxNow());
        if (target.isSameOrAfter(now.subtract(moment.duration(24, "hours")))) {
            return target.format(DateUtils.preferredTimeFormat(preferences, false));
        }
        else if (target.isSameOrAfter(now.subtract(moment.duration(7, "days")))) {
            return target.format("ddd");
        }
        else if (target.year() == now.year()) {
            return target.format(DateUtils.preferredDateFormat(preferences, "medium", false));
        }
        else {
            return target.format(DateUtils.preferredDateFormat(preferences, "medium"));
        }
    }
    static formatMessageDateTime(date, preferences) {
        const target = moment(date);
        const now = moment(DateUtils.bryxNow());
        if (target.isSameOrAfter(now.subtract(moment.duration(24, "hours")))) {
            return target.format(DateUtils.preferredTimeFormat(preferences));
        }
        else if (target.isSameOrAfter(now.subtract(moment.duration(7, "days")))) {
            return target.format(`ddd ${DateUtils.preferredTimeFormat(preferences)}`);
        }
        else if (target.year() == now.year()) {
            return target.format(`${DateUtils.preferredDateFormat(preferences, "medium", false)} ${DateUtils.preferredTimeFormat(preferences)}`);
        }
        else {
            return target.format(`${DateUtils.preferredDateFormat(preferences, "medium")} ${DateUtils.preferredTimeFormat(preferences)}`);
        }
    }
    static bryxNow() {
        const systemNow = new Date();
        return new Date(systemNow.getTime() + DateUtils.clockOffset);
    }
}
DateUtils.clockOffset = 0;
