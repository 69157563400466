import { ParseUtils } from "@bryxinc/lunch";
import { JobTypeInformation } from "./jobTypeInformation";
import { MapClient, MapClientType } from "./mapClient";
export var LayerType;
(function (LayerType) {
    LayerType[LayerType["hydrants"] = 0] = "hydrants";
    LayerType[LayerType["users"] = 1] = "users";
    LayerType[LayerType["apparatus"] = 2] = "apparatus";
    LayerType[LayerType["self"] = 3] = "self";
    LayerType[LayerType["jobs"] = 4] = "jobs";
    LayerType[LayerType["stations"] = 5] = "stations";
    LayerType[LayerType["agencies"] = 6] = "agencies";
})(LayerType || (LayerType = {}));
export class MapLayer {
    constructor(type, result) {
        this.type = type;
        this.result = result;
    }
    static parse(o) {
        try {
            const type = ParseUtils.getEnum(o, "type", LayerType);
            const success = ParseUtils.getBoolean(o, "success");
            if (success) {
                const parseFunction = getParseFunction(type);
                return ParseUtils.parseSuccess(new MapLayer(type, { key: "success", items: ParseUtils.getArrayOfSubobjects(o, "items", parseFunction, "throw") }));
            }
            else {
                return ParseUtils.parseSuccess(new MapLayer(type, { key: "failure", reason: ParseUtils.getString(o, "reason") }));
            }
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid MapLayer Model: ${e.message}`);
        }
    }
}
export class HydrantLayerItem {
    constructor(id, location, mainSize, color) {
        this.id = id;
        this.location = location;
        this.mainSize = mainSize;
        this.color = color;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new HydrantLayerItem(ParseUtils.getString(o, "id"), o["location"], ParseUtils.getNumberOrNull(o, "mainSize"), ParseUtils.getString(o, "color")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid HydrantLayerItem Model: ${e.message}`);
        }
    }
}
export class ClientLayerItem {
    constructor(id, location, initials, name, ts, type) {
        this.id = id;
        this.location = location;
        this.initials = initials;
        this.name = name;
        this.ts = ts;
        this.type = type;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new ClientLayerItem(ParseUtils.getString(o, "id"), o["location"], ParseUtils.getString(o, "initials"), ParseUtils.getString(o, "name"), ParseUtils.getUNIXTimestampDate(o, "ts"), ParseUtils.getEnum(o, "type", MapClientType, MapClientType.user)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid ClientLayerItem Model: ${e.message}`);
        }
    }
    toMapClient() {
        return new MapClient(this.id, {
            type: this.type,
            commonName: this.name,
            initials: this.initials,
        }, this.location, this.ts);
    }
}
export class JobLayerItem {
    constructor(id, location, synopsis, unitShortNames, locationOfIncident, ts, type) {
        this.id = id;
        this.location = location;
        this.synopsis = synopsis;
        this.unitShortNames = unitShortNames;
        this.locationOfIncident = locationOfIncident;
        this.ts = ts;
        this.type = type;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new JobLayerItem(ParseUtils.getString(o, "id"), o["location"], ParseUtils.getString(o, "synopsis"), ParseUtils.getArray(o, "unitShortNames"), ParseUtils.getString(o, "locationOfIncident"), ParseUtils.getUNIXTimestampDate(o, "ts"), ParseUtils.getSubobject(o, "type", JobTypeInformation.parse)));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid JobLayerItem Model: ${e.message}`);
        }
    }
}
export class StationLayerItem {
    constructor(id, location, name) {
        this.id = id;
        this.location = location;
        this.name = name;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new StationLayerItem(ParseUtils.getString(o, "id"), o["location"], ParseUtils.getString(o, "name")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid StationLayerItem Model: ${e.message}`);
        }
    }
}
export class AgencyLayerItem {
    constructor(id, location, name) {
        this.id = id;
        this.location = location;
        this.name = name;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new AgencyLayerItem(ParseUtils.getString(o, "id"), o["location"], ParseUtils.getString(o, "name")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid AgencyLayerItem Model: ${e.message}`);
        }
    }
}
export function getParseFunction(layerType) {
    switch (layerType) {
        case LayerType.hydrants:
            return HydrantLayerItem.parse;
        case LayerType.users:
        case LayerType.apparatus:
        case LayerType.self:
            return ClientLayerItem.parse;
        case LayerType.jobs:
            return JobLayerItem.parse;
        case LayerType.stations:
            return StationLayerItem.parse;
        case LayerType.agencies:
            return AgencyLayerItem.parse;
    }
}
