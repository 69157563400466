import { ParseUtils } from "@bryxinc/lunch";
import { MinimalJob } from "./job";
export class SCUUnit {
    constructor(id, name, groupId) {
        this.id = id;
        this.name = name;
        this.groupId = groupId;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new SCUUnit(ParseUtils.getString(o, "id"), ParseUtils.getString(o, "name"), ParseUtils.getString(o, "groupId")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUUnit Model: ${e.message}`);
        }
    }
}
export class SCUAction {
    constructor(id, agencyId, name, icon, // semantic icon
    description, order) {
        this.id = id;
        this.agencyId = agencyId;
        this.name = name;
        this.icon = icon;
        this.description = description;
        this.order = order;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new SCUAction(ParseUtils.getString(o, "id"), ParseUtils.getString(o, "agencyId"), ParseUtils.getString(o, "name"), ParseUtils.getStringOrNull(o, "icon"), ParseUtils.getString(o, "description"), ParseUtils.getNumber(o, "order")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUAction Model: ${e.message}`);
        }
    }
}
export class SCU {
    constructor(id, agencyIds, name, status, units, swVersion, actions) {
        this.id = id;
        this.agencyIds = agencyIds;
        this.name = name;
        this.status = status;
        this.units = units;
        this.swVersion = swVersion;
        this.actions = actions;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new SCU(ParseUtils.getString(o, "id"), ParseUtils.getArray(o, "agencyIds"), ParseUtils.getString(o, "name"), ParseUtils.getSubobject(o, "status", SCUUtils.parseSCUStatus), ParseUtils.getArrayOfSubobjects(o, "units", SCUUnit.parse, "warn"), ParseUtils.getStringOrNull(o, 'swVersion'), ParseUtils.getArrayOfSubobjects(o, "actions", SCUAction.parse, "warn")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCU Model: ${e.message}`);
        }
    }
    hasImplicitAlert() {
        return this.checkVersion('4.1.15');
    }
    checkVersion(requiredVersion) {
        if (this.swVersion == null) {
            return false;
        }
        const currentTokens = this.swVersion.split('.');
        const requiredTokens = requiredVersion.split('.');
        for (let i = 0; i < requiredTokens.length; i++) {
            const required = parseInt(requiredTokens[i], 10);
            const current = parseInt(currentTokens[i], 10);
            if (required > current) {
                return false;
            }
            if (required < current) {
                return true;
            }
        }
        return false;
    }
    hasActions() {
        return this.actions.length > 0;
    }
}
export class SCUUtils {
    static parseSCUStatus(o) {
        try {
            const statusType = ParseUtils.getString(o, "type");
            switch (statusType) {
                case "idle":
                    return ParseUtils.parseSuccess({ type: "idle" });
                case "audio":
                    return ParseUtils.parseSuccess({
                        type: "audio",
                        tone: ParseUtils.getString(o, "tone"),
                        tts: ParseUtils.getArray(o, "tts"),
                        ssml: ParseUtils.getString(o, "ssml"),
                    });
                case "turnout":
                    return ParseUtils.parseSuccess({
                        type: "turnout",
                        jobs: ParseUtils.getArrayOfSubobjects(o, "jobs", MinimalJob.parse, "warn"),
                    });
                case "offline":
                    return ParseUtils.parseSuccess({
                        type: "offline",
                        lastUpdatedTs: ParseUtils.getUNIXTimestampDateOrNull(o, "lastUpdatedTs"),
                    });
                default:
                    return ParseUtils.parseFailure(`Invalid SCUStatus Model: Invalid type ${statusType}`);
            }
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SCUStatus Model: ${e.message}`);
        }
    }
    static getJobString(job) {
        return [
            job.unitShortNames.join(", "),
            job.typeInformation.code,
            job.synopsis,
            job.address && job.address.original,
        ].filter(t => t != null).join(" - ");
    }
}
