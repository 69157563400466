import { v4 as uuidgen } from "uuid";
import { config } from "../config";
export class BryxLocal {
    static get(key, prefix = BryxLocal.prefix) {
        const item = localStorage.getItem(prefix + key);
        return item ? JSON.parse(item) : null;
    }
    static set(key, value, prefix = BryxLocal.prefix) {
        localStorage.setItem(prefix + key, JSON.stringify(value));
    }
    static remove(key, prefix = BryxLocal.prefix) {
        localStorage.removeItem(prefix + key);
    }
    static getBryxItems() {
        const bryxItems = {};
        Object.keys(localStorage)
            .filter(key => key.indexOf(BryxLocal.prefix) == 0 || key.indexOf(BryxLocal.globalPrefix) == 0)
            .forEach(key => { bryxItems[key] = localStorage.getItem(key); });
        bryxItems.appVersion = config.version;
        return bryxItems;
    }
    static getItem(key) {
        return this.get(key);
    }
    static setItem(key, value) {
        return this.set(key, value);
    }
    static clear() {
        Object.keys(localStorage)
            .filter(key => key.indexOf(BryxLocal.prefix) == 0)
            .forEach(key => { localStorage.removeItem(key); });
    }
    static initializeFromAuthModel(authModel) {
        this.set('apiKey', authModel.apiKey);
        this.set('showEula', authModel.showEula);
        this.set('allowNotifications', authModel.allowNotifications);
    }
    static getDeviceId() {
        let currentId = this.get("deviceId", BryxLocal.globalPrefix);
        if (currentId == null) {
            currentId = uuidgen();
            this.set("deviceId", currentId, BryxLocal.globalPrefix);
        }
        return currentId;
    }
    static getForceDeviceSupport() {
        return this.get("forceDeviceSupport");
    }
    static setForceDeviceSupport(force) {
        this.set("forceDeviceSupport", force);
    }
    static getEmail() {
        return this.get('email');
    }
    static getName() {
        return this.get('name');
    }
    static getApiKey() {
        return this.get('apiKey');
    }
    static getAllowNotifications() {
        return this.get('allowNotifications');
    }
    static setAllowNotifications(allowNotifications) {
        return this.set('allowNotifications', allowNotifications);
    }
    static getShowEula() {
        return this.get('showEula');
    }
    static getShowReleaseNotes() {
        return this.get("showReleaseNotes");
    }
    static setShowReleaseNotes(show) {
        this.set("showReleaseNotes", show);
    }
    static getCurrentBaseLayerId() {
        return this.get("currentBaseLayerId");
    }
    static setCurrentBaseLayerId(layerId) {
        this.set("currentBaseLayerId", layerId);
    }
    static getSupplementalSortOrder() {
        return this.get("supplementalSortOrder");
    }
    static setSupplementalSortOrder(order) {
        this.set("supplementalSortOrder", order);
    }
    static setShowEula(show) {
        this.set('showEula', show);
    }
    static isSignedIn() {
        return this.requiredKeysForSignIn.every(k => BryxLocal.get(k) != null);
    }
    static isApparatus() {
        return this.get('clientType') == 'apparatus';
    }
    static getPreferredAgencyId() {
        return this.get('agencyId');
    }
    static setPreferredAgencyId(agencyId) {
        if (agencyId == null) {
            this.remove('agencyId');
        }
        else {
            this.set('agencyId', agencyId);
        }
    }
    static getCreateJobType() {
        return this.get('createJobType');
    }
    static setCreateJobType(jobType) {
        this.set('createJobType', jobType);
    }
}
BryxLocal.prefix = `com.bryx.`;
BryxLocal.globalPrefix = "global.bryx.";
BryxLocal.requiredKeysForSignIn = ['apiKey'];
