import { withContext } from "@bryxinc/lunch/context";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { Button, Divider, Form, Grid, Input, Message, Popup } from "semantic-ui-react";
import { ContactSupportModal } from "./components/contactSupportModal";
import { ForgotPasswordModal } from "./components/forgotPasswordModal";
import { config } from "./config";
import { BryxApi } from "./utils/bryxApi";
import { BryxLocal } from "./utils/bryxLocal";
;
export class Login extends React.Component {
    constructor(props, context) {
        super(props, context);
        let status;
        if (BryxLocal.isSignedIn()) {
            status = { key: "success", redirectLocation: this.getRedirectLocation() };
        }
        else if (this.props.location.state) {
            switch (this.props.location.state.type) {
                case "forced":
                    status = { key: "ready", messageContent: "forceSignedOut" };
                    break;
                case "passwordChanged":
                    status = { key: "ready", messageContent: "passwordChanged" };
                    break;
                default:
                    status = { key: "ready", messageContent: "none" };
            }
        }
        else {
            status = { key: "ready", messageContent: "none" };
        }
        this.state = {
            email: "",
            password: "",
            overlayContent: "none",
            status: status,
        };
    }
    getRedirectLocation() {
        if (this.props.location.state && this.props.location.state.from) {
            return this.props.location.state.from;
        }
        else {
            return "/stations";
        }
    }
    onSubmit(e) {
        e.preventDefault();
        this.submitCredentials();
    }
    submitCredentials() {
        this.setState({
            status: { key: "loading" },
        });
        const { email, password } = this.state;
        if (email == null || email == "" || password == null || password == "") {
            this.setState({
                status: {
                    key: "error",
                    alertMessage: this.props.t("login.blankEmailOrPassword"),
                },
            });
            return;
        }
        BryxApi.signIn(email, password, null, (result) => {
            if (result.success == true) {
                const redirectLocation = this.getRedirectLocation();
                this.setState({
                    status: {
                        key: "success",
                        redirectLocation: redirectLocation,
                    },
                });
                config.info(redirectLocation);
                config.info(`User successfully signed in, redirecting to ${redirectLocation}`);
            }
            else {
                this.setState({
                    status: {
                        key: "error",
                        alertMessage: result.message,
                    },
                });
                config.warn(`User failed to sign in: ${result.debugMessage}`);
            }
        });
    }
    onChangeEmail(event) {
        this.setState({
            email: event.currentTarget.value,
        });
    }
    onChangePassword(event) {
        this.setState({
            password: event.currentTarget.value,
        });
    }
    render() {
        if (this.state.status.key == "success") {
            return React.createElement(Redirect, { to: this.state.status.redirectLocation });
        }
        let alertString = null;
        let alertType = null;
        if (this.state.status.key == "error") {
            alertString = this.state.status.alertMessage;
            alertType = "negative";
        }
        else if (this.state.status.key == "ready") {
            switch (this.state.status.messageContent) {
                case "forceSignedOut":
                    alertString = this.props.t("login.forceSignedOut");
                    alertType = "negative";
                    break;
                case "passwordChanged":
                    alertString = this.props.t("login.passwordChanged");
                    alertType = "warning";
                    break;
            }
        }
        const alertMessage = alertString && alertType ? (React.createElement(Message, { negative: alertType == "negative", warning: alertType == "warning", content: alertString })) : null;
        const emailInputHtml = (React.createElement(Input, { required: true, autoFocus: true, autoComplete: false, autoCorrect: false, autoCapitalize: false, spellCheck: false, type: "username", placeholder: this.props.t("login.email"), value: this.state.email || "", disabled: this.state.status.key == "loading", onChange: this.onChangeEmail.bind(this) }));
        const pwInputHtml = (React.createElement(Input, { required: true, type: "password", placeholder: this.props.t("login.password"), value: this.state.password || "", disabled: this.state.status.key == "loading", onChange: this.onChangePassword.bind(this) }));
        const inputForm = (React.createElement(Form, { onSubmit: this.onSubmit.bind(this) },
            React.createElement(Form.Field, null, emailInputHtml),
            React.createElement(Form.Field, null, pwInputHtml),
            React.createElement(Form.Field, null,
                React.createElement(Grid, null,
                    React.createElement(Grid.Row, { columns: 2, centered: true },
                        React.createElement(Grid.Column, { style: { paddingRight: "0.5rem" } },
                            React.createElement(Popup, { position: "left center", on: "click", style: { textAlign: "center" }, content: this.props.t("login.forgotPasswordSuccess"), open: this.state.status.key == "ready" && this.state.status.messageContent == "forgotPasswordSent", onClose: () => this.setState({
                                    status: {
                                        key: "ready",
                                        messageContent: "none",
                                    },
                                }), trigger: React.createElement(Button, { className: "btn", inverted: true, color: "grey", type: "button", style: { letterSpacing: "1px" }, onClick: () => this.setState({
                                        overlayContent: "forgotPassword",
                                    }) }, this.props.t("login.forgotPassword").toUpperCase()) })),
                        React.createElement(Grid.Column, { style: { paddingLeft: "0.5rem" } },
                            React.createElement(Button, { id: "signinButton", className: "btn", type: "submit", loading: this.state.status.key == "loading", style: { color: "#C61A1B", letterSpacing: "1px" } }, this.props.t("login.signIn").toUpperCase())))))));
        return (React.createElement("div", null,
            React.createElement("div", { className: "logoAndText" },
                React.createElement("img", { src: "/resources/assets/logo_white.png", style: { width: "30px" } }),
                React.createElement("span", { className: "siteName" }, this.props.t("branding.siteName"))),
            React.createElement("div", { id: "contactSupportDiv" },
                React.createElement("a", { id: "contactSupportLink", style: { textDecoration: "none" }, onClick: () => this.setState({
                        overlayContent: "contactSupport",
                    }) }, this.props.t("contactSupport.header"))),
            React.createElement("div", { className: "login-modal" },
                React.createElement(Grid, { verticalAlign: "middle", style: { height: "100%" } },
                    React.createElement(Grid.Row, { centered: true },
                        React.createElement(Grid.Column, { textAlign: "left", id: "loginForm" },
                            React.createElement("img", { id: "signinLogo", src: "/resources/assets/brand_logo.png" }),
                            inputForm,
                            alertMessage,
                            React.createElement(Divider, { className: "grayDivider", style: { margin: "2rem 0 1.5rem 0" } }),
                            React.createElement("a", { id: "joinLink", target: "_blank", style: { textDecoration: "none" }, href: BryxApi.joinUrl }, this.props.t("login.joinLink")))))),
            React.createElement(ContactSupportModal, { open: this.state.overlayContent == "contactSupport", onClose: () => this.setState({
                    overlayContent: "none",
                }), email: this.state.email || undefined }),
            React.createElement(ForgotPasswordModal, { open: this.state.overlayContent == "forgotPassword", email: this.state.email, onEmailChange: newEmail => this.setState({ email: newEmail }), onCancel: () => this.setState({
                    overlayContent: "none",
                }), onSuccess: () => this.setState({
                    overlayContent: "none",
                    status: {
                        key: "ready",
                        messageContent: "forgotPasswordSent",
                    },
                }) })));
    }
}
export default withContext(Login, 'i18n');
