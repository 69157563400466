import * as i18n from "i18next";
import * as React from "react";
import { Header, Icon, Menu, Message, Segment } from "semantic-ui-react";
import { Sheet } from "../../components/sheet";
import { SCUUtils } from "../../models/scu";
import { SCUManager } from "../../utils/scuManager";
import { ScuEventsTable } from "./scuEventsTable";
import { SCUUnitTab } from "./scuUnitsTab";
export class StationSheet extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = StationSheet.getDefaultState();
    }
    static getDefaultState() {
        return {
            activeScuStatus: SCUManager.shared.activeScuStatus,
            tab: "status",
        };
    }
    componentDidMount() {
        SCUManager.shared.registerActiveScuObserver(this);
        if (this.props.viewStatus.key == "shown") {
            const newScu = this.props.viewStatus.scu.id;
            this.setState(StationSheet.getDefaultState(), () => SCUManager.shared.setActiveScu(newScu));
        }
    }
    componentWillReceiveProps(nextProps, nextContext) {
        const opening = nextProps.viewStatus.key == "shown" && this.props.viewStatus.key == "hidden";
        const switching = nextProps.viewStatus.key == "shown" && this.props.viewStatus.key == "shown" && nextProps.viewStatus.scu.id != this.props.viewStatus.scu.id;
        const closing = nextProps.viewStatus.key == "hidden" && this.props.viewStatus.key == "shown";
        const oldScu = this.props.viewStatus.key == "shown" ? this.props.viewStatus.scu.id : null;
        const newScu = nextProps.viewStatus.key == "shown" ? nextProps.viewStatus.scu.id : null;
        if ((switching || closing) && oldScu != null) {
            SCUManager.shared.clearActiveScu(oldScu);
        }
        if ((switching || opening) && newScu != null) {
            this.setState(StationSheet.getDefaultState(), () => SCUManager.shared.setActiveScu(newScu));
        }
    }
    scuManagerDidUpdateActiveScu(status) {
        this.setState({ activeScuStatus: status });
    }
    componentWillUnmount() {
        SCUManager.shared.unregisterActiveScuObserver(this);
    }
    getComputedLoadStatus() {
        return this.state.activeScuStatus.key == "selected" ? this.state.activeScuStatus.loadStatus : { key: "loading" };
    }
    static renderStatusSegment(scu) {
        let subline;
        let subtext = null;
        switch (scu.status.type) {
            case "idle":
                subline = {
                    icon: { name: "check circle", color: "green" },
                    text: i18n.t("stations.stationsList.idle"),
                };
                break;
            case "audio":
                subline = {
                    icon: { name: "volume up", color: "orange" },
                    text: i18n.t("stations.stationsList.alertingAudioWithTone", { tone: scu.status.tone }),
                };
                subtext = scu.status.tts.map(t => t.toUpperCase()).join(" • ");
                break;
            case "turnout":
                subline = {
                    icon: { name: "clock", color: "yellow" },
                    text: i18n.t("stations.stationsList.turningOut"),
                };
                subtext = scu.status.jobs.length == 1 ? SCUUtils.getJobString(scu.status.jobs[0]) : i18n.t("stations.stationsList.xJobs", { count: scu.status.jobs.length });
                break;
            case "offline":
                subline = {
                    icon: { name: "warning circle", color: "red" },
                    text: i18n.t("stations.stationsList.offline"),
                };
                subtext = scu.status.lastUpdatedTs != null
                    ? i18n.t("stations.stationsList.lastContact", {
                        duration: scu.status.lastUpdatedTs.toLocaleString(),
                        interpolation: { escapeValue: false },
                    })
                    : i18n.t("stations.stationsList.lastContactUnknown");
                break;
            default:
                return null;
        }
        return (React.createElement(Segment, null,
            React.createElement(Icon, { ...subline.icon, size: "large" }),
            React.createElement("span", null, subline.text),
            subtext != null ? React.createElement("div", { style: { marginTop: "0.4em" } }, subtext) : null));
    }
    renderContent() {
        if (this.props.viewStatus.key == "hidden") {
            return null;
        }
        const scu = this.props.viewStatus.scu;
        const loadStatus = this.getComputedLoadStatus();
        let content;
        switch (loadStatus.key) {
            case "events":
                content = (React.createElement(ScuEventsTable, { events: loadStatus.events }));
                break;
            case "failed":
                content = (React.createElement(Message, { negative: true, content: loadStatus.message }));
                break;
            case "loading":
                content = (React.createElement(ScuEventsTable, { events: [] }));
                break;
        }
        const statusElement = StationSheet.renderStatusSegment(this.props.viewStatus.scu);
        return (React.createElement("div", { style: { height: "100%", display: "flex", flexDirection: "column" } },
            React.createElement(Header, { size: "large", content: scu.name }),
            React.createElement(Menu, { pointing: true, secondary: true },
                React.createElement(Menu.Item, { active: this.state.tab == "status", name: i18n.t("stations.specific.tabs.status"), onClick: (e, d) => this.setState({ tab: "status" }) }),
                React.createElement(Menu.Item, { active: this.state.tab == "units", name: i18n.t("stations.specific.tabs.units"), onClick: (e, d) => this.setState({ tab: "units" }) })),
            statusElement,
            this.state.tab == "status" ? content : (React.createElement(SCUUnitTab, { scuId: scu.id }))));
    }
    render() {
        const { viewStatus, onClose } = this.props;
        return (React.createElement(Sheet, { open: viewStatus.key == "shown", onClose: onClose }, this.renderContent()));
    }
}
