import { ParseUtils } from "@bryxinc/lunch";
export class Eula {
    constructor(eulaVersion, eula) {
        this.eulaVersion = eulaVersion;
        this.eula = eula;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new Eula(ParseUtils.getNumber(o, "eulaVersion"), ParseUtils.getString(o, "eula")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Eula Model: ${e.message}`);
        }
    }
}
