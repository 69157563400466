export class Debouncer {
    constructor(onApply, debounceTime) {
        this.onApply = onApply;
        this.debounceTime = 300;
        this.debounceTimer = null;
        if (debounceTime != null) {
            this.debounceTime = debounceTime;
        }
    }
    postUpdate() {
        if (this.debounceTimer != null) {
            clearTimeout(this.debounceTimer);
        }
        this.debounceTimer = window.setTimeout(() => {
            this.onApply();
        }, this.debounceTime);
    }
}
