import * as i18n from "i18next";
import * as React from "react";
import { Checkbox, Form, Icon, Message, Popup } from "semantic-ui-react";
import { config } from "../../config";
import { Theme } from "../../models/theme";
import { BryxApi } from "../../utils/bryxApi";
import { BryxLocal } from "../../utils/bryxLocal";
import { enumItems } from "../../utils/functions";
import { NotificationManager, } from "../../utils/notificationManager";
import { PreferenceManager } from "../../utils/preferenceManager";
export class GeneralTab extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            preferences: PreferenceManager.shared.preferences,
            notificationPermission: NotificationManager.shared.permission,
            allowNotificationsState: {
                allowNotifications: BryxLocal.getAllowNotifications() || false,
                subState: { key: "ready" },
            },
            preferredAgencyState: { key: "disabled" },
        };
    }
    componentDidMount() {
        NotificationManager.shared.registerPermissionObserver(this);
    }
    componentWillUnmount() {
        NotificationManager.shared.unregisterPermissionObserver(this);
    }
    // NotificationPermissionObserver Functions
    notificationManagerDidUpdateNotificationPermission(permission) {
        this.setState({ notificationPermission: permission });
    }
    render() {
        const prefs = this.state.preferences;
        let additionalInfoLine = null;
        if (NotificationManager.shared.permission == "unsupported") {
            additionalInfoLine = i18n.t("settings.general.webNotificationsDescription.unsupported");
        }
        else if (NotificationManager.shared.permission == "denied") {
            additionalInfoLine = i18n.t("settings.general.webNotificationsDescription.denied");
        }
        const pushNotificationsLabel = (React.createElement(Popup, { trigger: React.createElement(Icon, { name: "help circle", style: { marginLeft: "5px" } }) },
            React.createElement(Popup.Header, null, i18n.t("settings.general.receivePushNotificationsHeader")),
            React.createElement(Popup.Content, null,
                React.createElement("p", null, i18n.t("settings.general.receivePushNotificationsDescription")),
                additionalInfoLine != null ? React.createElement("p", null, additionalInfoLine) : null)));
        const desktopNotificationsLabel = (React.createElement(Popup, { trigger: React.createElement(Icon, { name: "help circle", style: { marginLeft: "5px" } }) },
            React.createElement(Popup.Header, null, i18n.t("settings.general.webNotificationsHeader")),
            React.createElement(Popup.Content, null,
                React.createElement("p", null, i18n.t("settings.general.webNotificationsDescription.basic")),
                additionalInfoLine != null ? React.createElement("p", null, additionalInfoLine) : null)));
        const preferredAgencyLabel = (React.createElement(Popup, { trigger: React.createElement(Icon, { name: "help circle", style: { marginLeft: "5px" } }) },
            React.createElement(Popup.Header, null, i18n.t("settings.general.preferredAgencyHeader")),
            React.createElement(Popup.Content, null,
                React.createElement("p", null, i18n.t("settings.general.preferredAgencyDescription")))));
        return (React.createElement(Form, { style: { minWidth: "300px", width: "40%", margin: "20px", padding: "1rem" } },
            React.createElement(Form.Field, null,
                React.createElement(Checkbox, { label: i18n.t("settings.general.preferences.preferredAgency"), checked: prefs.preferredAgencyId != null, onChange: (e, d) => {
                        const enabledPreferredAgency = d.checked || false;
                        const firstAgency = this.props.agencies[0].id;
                        this.setState({
                            preferredAgencyState: enabledPreferredAgency ? { key: "selected", agencyId: firstAgency } : { key: "disabled" },
                        }, () => {
                            if (!enabledPreferredAgency) {
                                PreferenceManager.shared.updatePreferences({ preferredAgencyId: null });
                            }
                            else {
                                PreferenceManager.shared.updatePreferences({ preferredAgencyId: firstAgency });
                            }
                        });
                    } }),
                preferredAgencyLabel),
            prefs.preferredAgencyId != null ? (React.createElement(Form.Dropdown, { selection: true, value: prefs.preferredAgencyId, options: this.props.agencies.map(i => ({
                    key: i.id,
                    value: i.id,
                    text: i.name,
                })), onChange: (e, d) => {
                    const agencyId = d.value;
                    this.setState({
                        preferredAgencyState: {
                            key: "selected",
                            agencyId,
                        },
                    });
                    PreferenceManager.shared.updatePreferences({ preferredAgencyId: agencyId });
                } })) : null,
            React.createElement(Form.Dropdown, { selection: true, value: prefs.theme, label: i18n.t("settings.general.preferences.theme"), options: enumItems(Theme).map(i => ({
                    key: i.name,
                    value: i.value,
                    text: i18n.t(`settings.general.preferences.themes.${i.name}`),
                })), onChange: (e, d) => {
                    const newTheme = d.value;
                    PreferenceManager.shared.updatePreferences({ theme: newTheme });
                    this.setState(prevState => {
                        prevState.preferences.theme = newTheme;
                        return prevState;
                    });
                } }),
            React.createElement(Form.Field, null,
                React.createElement(Checkbox, { label: i18n.t("settings.general.preferences.receivePushNotifications"), checked: this.state.allowNotificationsState.allowNotifications, disabled: this.state.allowNotificationsState.subState.key == "loading", onChange: (e, d) => {
                        const allowNotifications = d.checked || false;
                        this.setState(prevState => ({
                            allowNotificationsState: {
                                allowNotifications: prevState.allowNotificationsState.allowNotifications,
                                subState: { key: "loading" },
                            },
                        }));
                        BryxApi.setAllowNotifications(allowNotifications, result => {
                            if (result.success == true) {
                                BryxLocal.setAllowNotifications(allowNotifications);
                                this.setState({
                                    allowNotificationsState: {
                                        allowNotifications: allowNotifications,
                                        subState: { key: "ready" },
                                    },
                                });
                            }
                            else {
                                config.warn(`Failed to enable device push notifications: ${result.debugMessage}`);
                                this.setState(prevState => ({
                                    allowNotificationsState: {
                                        allowNotifications: prevState.allowNotificationsState.allowNotifications,
                                        subState: { key: "failed", message: result.message },
                                    },
                                }));
                            }
                        });
                    } }),
                pushNotificationsLabel),
            React.createElement(Form.Field, null,
                React.createElement(Checkbox, { label: i18n.t("settings.general.preferences.webNotifications"), checked: this.state.notificationPermission == "granted" && prefs.useWebNotifications, disabled: this.state.notificationPermission == "denied" || this.state.notificationPermission == "unsupported", onChange: (e, d) => {
                        const useWebNotifications = d.checked || false;
                        PreferenceManager.shared.updatePreferences({
                            useWebNotifications: useWebNotifications,
                        });
                        this.setState((prevState => {
                            prevState.preferences.useWebNotifications = useWebNotifications;
                            return prevState;
                        }));
                        if (useWebNotifications && NotificationManager.shared.permission == "default") {
                            Notification.requestPermission().then(permission => {
                                this.setState({ notificationPermission: permission });
                            });
                        }
                    } }),
                desktopNotificationsLabel),
            this.state.allowNotificationsState.subState.key == "failed" ? (React.createElement(Message, { negative: true, header: i18n.t("settings.general.receivePushNotificationsError"), content: this.state.allowNotificationsState.subState.message })) : null));
    }
}
