import * as i18n from "i18next";
import XHR from "i18next-xhr-backend";
import * as React from "react";
import { Redirect } from "react-router";
import { Button, Header } from "semantic-ui-react";
import { config } from "../config";
import { BryxColors } from "../utils/bryxColors";
import { BryxLocal } from "../utils/bryxLocal";
import { DeviceUtils } from "../utils/deviceUtils";
import { UpdateManager } from "../utils/updateManager";
export class BadBrowser extends React.Component {
    constructor(props, context) {
        super(props, context);
        if (!DeviceUtils.deviceIsSupported) {
            i18n.use(XHR).init({
                lng: BryxLocal.getItem("locale") || "en-US",
                backend: {
                    loadPath: "/resources/locales/{{lng}}.json",
                },
                fallbackLng: "en",
            }, () => {
                this.i18nLoaded();
            });
        }
        this.state = {
            loading: true,
        };
    }
    i18nLoaded() {
        this.setState({
            loading: false,
        });
    }
    render() {
        if (DeviceUtils.deviceIsSupported) {
            return React.createElement(Redirect, { to: "/login" });
        }
        return (React.createElement("div", { style: { backgroundColor: "#AB1A1C", height: "100%", width: "100%" } },
            React.createElement("div", { className: "flexCenteredContainer", style: {
                    height: "100%",
                    width: "100%",
                    backgroundColor: BryxColors.darkRed,
                } },
                React.createElement("div", { style: { textAlign: "center" } },
                    React.createElement("img", { id: "signinLogo", src: "/resources/assets/logo_white.png" }),
                    !this.state.loading ? (React.createElement("div", { className: "unsupported-browser-content" },
                        React.createElement(Header, { style: {
                                fontSize: "32px",
                                color: "#f1f1f1",
                                textAlign: "center",
                                letterSpacing: "1px",
                            } }, i18n.t("badBrowser.unsupportedBrowser.statement")))) : undefined,
                    config.serverType == "dev" ? (React.createElement(Button, { style: { marginTop: "20px" }, onClick: () => {
                            BryxLocal.setForceDeviceSupport(true);
                            UpdateManager.shared.update();
                        } }, i18n.t("badBrowser.proceedAnyway"))) : null))));
    }
}
