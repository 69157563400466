import * as i18n from "i18next";
import * as React from 'react';
import { Button, Form, Header, Input, Message, Modal } from 'semantic-ui-react';
import { config } from "../config";
import { BryxApi } from "../utils/bryxApi";
export class ApparatusSignOutModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            status: { key: "ready" },
            password: "",
        };
    }
    onPasswordChange(e, inputProps) {
        this.setState({
            password: inputProps.value,
        });
    }
    onSubmitSignOut(e) {
        e.preventDefault();
        this.signOutApparatus();
    }
    signOutApparatus() {
        this.setState({
            status: {
                key: "loading",
            },
        });
        BryxApi.signOutApparatus(this.state.password, result => {
            if (result.success == true) {
                config.info("Apparatus successfully signed out");
                this.props.onSignOut();
            }
            else {
                config.warn(`Apparatus failed to sign out: ${result.debugMessage}`);
                this.setState({
                    status: { key: "error", message: result.message },
                });
            }
        });
    }
    render() {
        return (React.createElement(Modal, { size: "small", open: this.props.open, onClose: this.props.onCancel },
            React.createElement(Header, { icon: "unlock alternate", content: i18n.t("nav.apparatusSignOut.title") }),
            React.createElement(Modal.Content, { style: { minHeight: "145px" } },
                React.createElement(Form, { onSubmit: this.onSubmitSignOut.bind(this) },
                    React.createElement(Form.Field, null,
                        React.createElement(Input, { placeholder: i18n.t("nav.apparatusSignOut.passwordPlaceholder"), autoFocus: true, type: "password", loading: this.state.status.key == "loading", onChange: this.onPasswordChange.bind(this) }))),
                this.state.status.key == "error" && React.createElement(Message, { negative: true, content: this.state.status.message })),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { color: "blue", content: i18n.t("nav.apparatusSignOut.signOutButton"), onClick: this.signOutApparatus.bind(this), disabled: this.state.status.key == "loading" }),
                React.createElement(Button, { color: "red", content: i18n.t("general.cancel"), disabled: this.state.status.key == "loading", onClick: this.props.onCancel }))));
    }
}
