import * as i18n from "i18next";
import * as React from "react";
import { Button, Menu, Modal } from "semantic-ui-react";
import { config } from "../../config";
import { BryxColors } from "../../utils/bryxColors";
import { BryxLocal } from "../../utils/bryxLocal";
import { AlertsTab } from "./alertsTab";
import { DevToolsTab } from "./devToolsTab";
import { GeneralTab } from "./generalTab";
import { PasswordTab } from "./passwordTab";
export var SettingsTabType;
(function (SettingsTabType) {
    SettingsTabType[SettingsTabType["general"] = 0] = "general";
    SettingsTabType[SettingsTabType["alerts"] = 1] = "alerts";
    SettingsTabType[SettingsTabType["password"] = 2] = "password";
    SettingsTabType[SettingsTabType["devTools"] = 3] = "devTools";
})(SettingsTabType || (SettingsTabType = {}));
export class SettingsModal extends React.Component {
    static allTabs() {
        // Alerts removed until support is added
        const tabs = [SettingsTabType.general, SettingsTabType.password];
        if (config.serverType != "prod") {
            tabs.push(SettingsTabType.devTools);
        }
        return tabs;
    }
    render() {
        const currentTab = this.props.currentTab;
        let currentTabContent;
        switch (currentTab) {
            case SettingsTabType.general:
                currentTabContent = React.createElement(GeneralTab, { agencies: this.props.agencies });
                break;
            case SettingsTabType.alerts:
                currentTabContent = React.createElement(AlertsTab, null);
                break;
            case SettingsTabType.password:
                currentTabContent = React.createElement(PasswordTab, { onPasswordChanged: this.props.onPasswordChanged });
                break;
            case SettingsTabType.devTools:
                currentTabContent = React.createElement(DevToolsTab, null);
                break;
        }
        return (React.createElement(Modal, { open: this.props.open, size: "small", onClose: this.props.onClose },
            React.createElement(Modal.Header, { style: { width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" } },
                i18n.t("settings.title"),
                React.createElement("span", { style: {
                        color: BryxColors.gray,
                        fontWeight: "normal",
                        fontSize: "16px",
                    } }, BryxLocal.getEmail())),
            React.createElement(Modal.Content, { style: { flex: 1, display: "flex", flexDirection: "column", height: "500px", padding: 0 } },
                React.createElement(Menu, { pointing: true, secondary: true, style: { marginBottom: 0, marginTop: "1rem" } }, SettingsModal.allTabs().map(t => (React.createElement(Menu.Item, { key: t, name: i18n.t(`settings.tabs.${SettingsTabType[t]}`), active: currentTab == t, onClick: () => this.props.onTabChange(t) })))),
                React.createElement("div", { style: { flex: 1, overflowY: "auto" } }, currentTabContent)),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { content: i18n.t("general.done"), onClick: this.props.onClose }))));
    }
}
