import { ParseUtils } from "@bryxinc/lunch";
import { HttpClient, ResponseStatus } from "./spoonClient";
import * as uuid from "uuid";
import { config } from "../config";
import { AgencyRegion } from "../models/agency";
import { Auth as AuthModel } from "../models/auth";
import { ApiError } from "../models/bryxTypes";
import { Eula } from "../models/eula";
import { Geocode } from "../models/geocode";
import { BasicAgencyGroup } from "../models/group";
import { ListJob } from "../models/job";
import { parseJobsListUpdate } from "../models/jobsListUpdate";
import { JobType } from "../models/jobTypeInformation";
import { LayerType } from "../models/map";
import { MapClient } from "../models/mapClient";
import { parseMapUpdate } from "../models/mapUpdate";
import { parseNotificationUpdate } from "../models/notificationUpdate";
import { SCUUnitOptions } from "../models/scuConfig";
import { ScusListUtils } from "../models/scusListUpdate";
import { SpecificSCUUtils } from "../models/scuUpdate";
import { ServerTime } from "../models/serverTime";
import { AlertSettingType, Shift, ShiftStatus } from "../models/shift";
import { SiteSurvey } from "../models/siteSurvey";
import { parseSpecificJobUpdate } from "../models/specificJobUpdate";
import { BryxLocal } from "./bryxLocal";
import { BryxWebSocket } from "./bryxWebSocket";
import { DateUtils } from "./dateUtils";
import { DeviceUtils } from "./deviceUtils";
import { JobListType } from "./jobManager";
import { SupportUtils } from "./supportUtils";
import i18n from "i18next";
export function apiSuccess(value) {
    return { success: true, value: value };
}
export function apiFailure(message, debugMessage) {
    return { success: false, message: message || i18n.t("general.genericError"), debugMessage: debugMessage };
}
// noinspection JSUnusedLocalSymbols
function nullParser(o) {
    return ParseUtils.parseSuccess(null);
}
function apiArrayResultFromParse(response, parseFunction, failBehavior) {
    if (response.status == ResponseStatus.Success) {
        const itemsObject = { items: response.responseJson };
        return apiSuccess(ParseUtils.getArrayOfSubobjects(itemsObject, 'items', parseFunction, failBehavior));
    }
    else {
        return apiFailureWithResponse(response);
    }
}
function apiResultFromParse(response, parseFunction) {
    if (response.status == ResponseStatus.Success) {
        const parseResult = parseFunction(response.responseJson);
        if (parseResult.success == true) {
            return apiSuccess(parseResult.value);
        }
        else {
            return apiFailure(null, parseResult.justification);
        }
    }
    else {
        return apiFailureWithResponse(response);
    }
}
function apiFailureWithResponse(response) {
    if (response.status == ResponseStatus.ConnectionFailure) {
        return apiFailure(i18n.t("general.connectionFailure"), "Connection failure");
    }
    const errorResult = ApiError.parse(response.responseJson);
    if (errorResult.success == true) {
        return apiFailure(errorResult.value.message, errorResult.value.realCause || errorResult.value.message);
    }
    else {
        return apiFailure(null, errorResult.justification);
    }
}
export class BryxApi {
    static unauthenticatedCallback(callback) {
        return (request, response) => {
            if (response.status == ResponseStatus.Unauthorized && BryxApi.onUnauthenticated != null) {
                BryxApi.onUnauthenticated();
            }
            callback(request, response);
        };
    }
    static signOut(callback) {
        const wrappedCallback = (request, response) => {
            if (response.status == ResponseStatus.Success) {
                callback(apiSuccess(null));
            }
            else {
                callback(apiFailureWithResponse(response));
            }
        };
        BryxApi.http.del("/authorization/", null, wrappedCallback);
    }
    static signOutApparatus(password, callback) {
        const wrappedCallback = (request, response) => {
            if (response.status == ResponseStatus.Success) {
                callback(apiSuccess(null));
            }
            else {
                callback(apiFailureWithResponse(response));
            }
        };
        const urlParams = {
            password: password,
        };
        BryxApi.http.del("/authorization/", urlParams, wrappedCallback);
    }
    // noinspection JSUnusedLocalSymbols
    static authCallback(request, response, email, callback) {
        if (response.status == ResponseStatus.Success) {
            const parseResult = AuthModel.parse(response.responseJson);
            if (parseResult.success == true) {
                BryxLocal.initializeFromAuthModel(parseResult.value);
                callback(apiSuccess(parseResult.value));
            }
            else {
                callback(apiFailure(null, parseResult.justification));
            }
        }
        else {
            callback(apiFailureWithResponse(response));
        }
    }
    static updateOffset(callback) {
        BryxApi.http.get("/ts", null, (request, response) => {
            const apiResult = apiResultFromParse(response, ServerTime.parse);
            if (apiResult.success == true) {
                DateUtils.setOffset(apiResult.value.time.getTime() - new Date().getTime());
                callback(apiSuccess(null));
            }
            else {
                config.warn(`Failed to update time offset: ${apiResult.debugMessage}`);
                callback(apiResult);
            }
        });
    }
    static changePassword(currentPass, newPass, callback) {
        const body = {
            oldPassword: currentPass,
            newPassword: newPass,
        };
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, nullParser));
        };
        BryxApi.http.put("/users/me/password", null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static getEula(callback) {
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, Eula.parse));
        };
        BryxApi.http.get("/eula", null, wrappedCallback);
    }
    static acceptEula(callback) {
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, nullParser));
        };
        BryxApi.http.put("/users/me/eula", null, null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static signIn(email, password, token, callback) {
        const authBody = {
            email: email,
            password: password,
            token: token,
            deviceId: BryxLocal.getDeviceId(),
            deviceName: DeviceUtils.deviceName,
            canUseForLocation: false,
            deviceType: "dispatch",
            // Push token is only generated on sign in and doesn't need to be recorded.
            pushToken: uuid.v4(),
            appVersion: config.version,
        };
        BryxApi.http.post("/authorization/", null, authBody, (request, response) => {
            BryxApi.authCallback(request, response, email, callback);
        });
    }
    static session(callback) {
        const authBody = {
            appVersion: config.version,
        };
        BryxApi.http.put("/authorization/", null, authBody, BryxApi.unauthenticatedCallback((request, response) => {
            BryxApi.authCallback(request, response, null, result => {
                // noinspection JSUnusedLocalSymbols
                BryxApi.updateOffset(offsetResult => {
                    callback(result);
                });
            });
        }));
    }
    // Jobs
    static loadJobs(after, limit, type, callback) {
        const params = {
            limit: limit,
            archived: type == JobListType.closed,
            model: "list",
        };
        if (after != null) {
            params.time = Math.floor(after.getTime() / 1000);
        }
        const wrappedCallback = (request, response) => {
            callback(apiArrayResultFromParse(response, ListJob.parse, "warn"));
        };
        BryxApi.http.get("/jobs", params, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static respondToJob(jobId, responseOption, callback) {
        // TODO: Use responseId when available
        const requestBody = {
            response: responseOption.text,
        };
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, nullParser));
        };
        BryxApi.http.put(`/jobs/${jobId}/responders`, null, requestBody, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static loadHistoricalJobs(jobId, callback) {
        const wrappedCallback = (request, response) => {
            callback(apiArrayResultFromParse(response, ListJob.parse, "warn"));
        };
        BryxApi.http.get(`/jobs/${jobId}/historical`, null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static loadSiteSurvey(jobId, callback) {
        const wrappedCallback = (request, response) => {
            if (response.status == ResponseStatus.Success && response.responseJson == undefined) {
                callback(apiResultFromParse(response, nullParser));
            }
            else {
                callback(apiResultFromParse(response, SiteSurvey.parse));
            }
        };
        BryxApi.http.get(`/jobs/${jobId}/legacy-site-survey`, null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static sendSupportTicket(from, subject, type, body, image, callback) {
        const sendRequest = (imageData) => {
            const attachments = [
                {
                    contentType: "text/plain",
                    fileName: "localStorage.txt",
                    data: SupportUtils.bryxItemsAttachment(),
                },
                {
                    contentType: "text/plain",
                    fileName: "logs.txt",
                    data: SupportUtils.logsAttachment(),
                },
                {
                    contentType: "text/plain",
                    fileName: "deviceInfo.txt",
                    data: SupportUtils.deviceInfoAttachment(),
                },
            ];
            if (imageData != null) {
                attachments.push({
                    fileName: imageData.fileName,
                    contentType: imageData.contentType,
                    data: imageData.base64Data,
                });
            }
            const requestBody = {
                body: body,
                email: from,
                subject: subject,
                type: type,
                platform: "universal",
                attachments: attachments,
            };
            const wrappedCallback = (request, response) => {
                callback(apiResultFromParse(response, nullParser));
            };
            BryxApi.http.post("/support", null, requestBody, BryxApi.unauthenticatedCallback(wrappedCallback));
        };
        if (image != null) {
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = () => {
                const splitParts = reader.result.split(";base64,");
                const contentType = splitParts[0].replace("data:", "");
                const imageBase64Data = splitParts[1];
                sendRequest({ fileName: image.name, base64Data: imageBase64Data, contentType: contentType });
            };
            reader.onerror = () => callback(apiFailure(null, `Unable to load image file: ${reader.error}`));
        }
        else {
            sendRequest(null);
        }
    }
    static forgotPassword(email, captchaResponse, callback) {
        const body = {
            email: email,
            validationType: "recaptchaV2",
            response: captchaResponse,
        };
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, nullParser));
        };
        BryxApi.http.post("/password-reset", null, body, wrappedCallback);
    }
    // Routes
    static getRoute(startLocation, endLocation, callback) {
        const params = {
            startLong: startLocation.coordinates[0],
            startLat: startLocation.coordinates[1],
            endLong: endLocation.coordinates[0],
            endLat: endLocation.coordinates[1],
        };
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, (o) => {
                try {
                    const geometry = o.routes[0].geometry;
                    if (geometry == null) {
                        return ParseUtils.parseFailure("Invalid LineString Model: Missing `geometry`");
                    }
                    return ParseUtils.parseSuccess(geometry);
                }
                catch (e) {
                    return ParseUtils.parseFailure(`Invalid GeoJSONLineString Model: ${e.message}`);
                }
            }));
        };
        BryxApi.http.get("/osrm", params, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    // Shifts
    static setAllowNotifications(allowNotifications, callback) {
        const body = {
            receivePush: allowNotifications,
        };
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, nullParser));
        };
        BryxApi.http.put("/devices", null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static getShifts(callback) {
        const wrappedCallback = (request, response) => {
            callback(apiArrayResultFromParse(response, Shift.parse, "warn"));
        };
        BryxApi.http.get("/users/me/shifts", null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static changeShiftStatus(shift, shiftStatus, callback) {
        const body = {
            scheduleMode: ShiftStatus[shiftStatus],
        };
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, nullParser));
        };
        BryxApi.http.put(`/users/me/shifts/${shift.id}`, null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static changeAlertSetting(shift, filter, setting, callback) {
        let body;
        switch (setting.type) {
            case AlertSettingType.none:
                body = { alert: { type: "none" } };
                break;
            case AlertSettingType.silent:
                body = { alert: { type: "silence" } };
                break;
            case AlertSettingType.audio:
                body = { alert: { type: "audio", fileName: setting.soundId } };
                break;
        }
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, nullParser));
        };
        BryxApi.http.put(`/users/me/shifts/${shift.id}/filters/${filter.id}`, null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    // Map
    static getMapClients(callback) {
        const wrappedCallback = (request, response) => {
            callback(apiArrayResultFromParse(response, MapClient.parse, "warn"));
        };
        BryxApi.http.get("/users/me/team/locations", null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    // WebSocket Functions
    static subscribeToSCUs(key, onUpdate) {
        BryxWebSocket.shared.addSubscriber(key, "scus", message => {
            const update = ScusListUtils.parseScusListUpdate(message);
            if (update.success == true) {
                // Ignore `null` updates
                if (update.value != null) {
                    onUpdate(apiSuccess(update.value));
                }
            }
            else {
                onUpdate(update);
            }
        }, 0);
    }
    static subscribeToSCU(key, scuId, onUpdate) {
        BryxWebSocket.shared.addSubscriber(key, `scus/${scuId}`, message => {
            const update = SpecificSCUUtils.parseScuUpdate(message);
            if (update.success == true) {
                // Ignore `null` updates
                if (update.value != null) {
                    onUpdate(apiSuccess(update.value));
                }
            }
            else {
                onUpdate(update);
            }
        }, 0);
    }
    static subscribeToNewJobs(key, fastForwardMode, onUpdate) {
        const params = {
            fastForwardMode: fastForwardMode,
        };
        BryxWebSocket.shared.addSubscriber(key, "jobs", message => {
            const update = parseJobsListUpdate(message);
            if (update.success == true) {
                // Ignore `null` updates
                if (update.value != null) {
                    onUpdate(apiSuccess(update.value));
                }
            }
            else {
                onUpdate(update);
            }
        }, 0, params);
    }
    static subscribeToJob(key, jobId, onUpdate) {
        BryxWebSocket.shared.addSubscriber(key, `jobs/${jobId}`, message => {
            const update = parseSpecificJobUpdate(message);
            if (update.success == true) {
                // Ignore `null` updates
                if (update.value != null) {
                    onUpdate(apiSuccess(update.value));
                }
            }
            else {
                onUpdate(update);
            }
        }, 1);
    }
    static subscribeToMap(key, topRightBounds, bottomLeftBounds, layers, onUpdate) {
        BryxWebSocket.shared.addSubscriber(key, "maps", message => {
            const update = parseMapUpdate(message);
            if (update.success == true) {
                // Ignore `null` updates
                if (update.value != null) {
                    onUpdate(apiSuccess(update.value));
                }
            }
            else {
                onUpdate(update);
            }
        }, 0, {
            ne: topRightBounds,
            sw: bottomLeftBounds,
            layers: layers.map(l => LayerType[l]),
        });
    }
    static changeMapSubscription(key, topRightBounds, bottomLeftBounds, layers) {
        BryxWebSocket.shared.changeSubscription(key, "maps", {
            ne: topRightBounds,
            sw: bottomLeftBounds,
            layers: layers.map(l => LayerType[l]),
        }, true);
    }
    static changeJobListSubscription(key, fastForwardMode, resubscribe) {
        const params = {
            fastForwardMode: fastForwardMode,
        };
        BryxWebSocket.shared.changeSubscription(key, "jobs", params, resubscribe);
    }
    static acknowledgeJobsListUpdates(updateIds, completion) {
        const data = {
            type: "ack",
            updateIds: updateIds,
        };
        BryxWebSocket.shared.sendUpdate("jobs", data, completion);
    }
    static subscribeToNotifications(key, onUpdate) {
        BryxWebSocket.shared.addSubscriber(key, "notifications", message => {
            const update = parseNotificationUpdate(message);
            if (update.success == true) {
                // Ignore `null` updates
                if (update.value != null) {
                    onUpdate(apiSuccess(update.value));
                }
            }
            else {
                onUpdate(update);
            }
        }, 0);
    }
    static markNotificationRead(notificationIds, completion) {
        const data = {
            notificationIds: notificationIds,
        };
        BryxWebSocket.shared.sendUpdate("notifications", data, completion);
    }
    static unsubscribe(key) {
        BryxWebSocket.shared.removeSubscriber(key);
    }
    static getDispatchGroups(callback) {
        const wrappedCallback = (request, response) => {
            callback(apiArrayResultFromParse(response, BasicAgencyGroup.parse, "warn"));
        };
        BryxApi.http.get(`/dispatchers/me/groups`, {}, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static getAgencyRegion(agencyId, callback) {
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, AgencyRegion.parse));
        };
        BryxApi.http.get(`/agencies/${agencyId}/region`, null, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static geocode(agencyId, location, city, state, callback) {
        const geocodeBody = {
            locationDesc: location,
            city: city,
            state: state,
        };
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, Geocode.parse));
        };
        BryxApi.http.get(`/agencies/${agencyId}/geocode`, geocodeBody, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static createJob(synopsis, type, address, city, state, latitude, longitude, dispatchGroupIds, typeId, incidentId, callback) {
        const body = {
            synopsis: synopsis,
            type: JobType[type],
            typeId: typeId,
            incidentId: incidentId,
            address: address,
            city: city,
            state: state,
            latitude: latitude,
            longitude: longitude,
            dispatchGroupIds: dispatchGroupIds,
        };
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, nullParser));
        };
        BryxApi.http.post('/jobs', null, body, wrappedCallback);
    }
    static triggerAgencyAction(agencyId, actionId, callback) {
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, nullParser));
        };
        BryxApi.http.post(`/agencies/${agencyId}/actions/${actionId}/trigger`, null, null, wrappedCallback);
    }
    static scuAlertAll(scuId, callback) {
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, nullParser));
        };
        BryxApi.http.post(`/scus/${scuId}/alert`, null, null, wrappedCallback);
    }
    static updateScuZone(scuId, zoneId, units, callback) {
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, nullParser));
        };
        const body = {
            units: units,
        };
        BryxApi.http.put(`/scus/${scuId}/config/zones/${zoneId}`, null, body, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    static getScuUnitOptions(scuId, callback) {
        const wrappedCallback = (request, response) => {
            callback(apiResultFromParse(response, SCUUnitOptions.parse));
        };
        BryxApi.http.get(`/scus/${scuId}/dispatch-options`, {}, BryxApi.unauthenticatedCallback(wrappedCallback));
    }
    ;
}
BryxApi.apiRoot = `https://${config.baseUrl}/api/2.2`;
BryxApi.joinUrl = `https://${config.baseUrl}/join`;
BryxApi.wsUrl = `wss://${config.baseUrl}/api/2.2`;
BryxApi.http = (() => {
    const httpClientConfig = {
        baseUrl: BryxApi.apiRoot,
        transformRequest: (request) => {
            const headers = request.headers || {};
            headers["X-BRYX-TYPE"] = "dispatch";
            const currentApiKey = BryxLocal.getApiKey();
            if (currentApiKey != null) {
                headers["X-API-KEY"] = currentApiKey;
            }
            if (request.body != null) {
                headers["content-type"] = "application/json";
            }
            request.headers = headers;
            return request;
        },
    };
    return new HttpClient(httpClientConfig);
})();
