import * as i18n from "i18next";
import * as React from "react";
import { Button, Header, Message, Modal } from "semantic-ui-react";
import { NotificationManager } from "../utils/notificationManager";
import { PreferenceManager } from "../utils/preferenceManager";
export class PermissionsModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        // Here's we use `useWebNotifications` directly from the PreferenceManager and there's no need to register for updates.
        // At this stage in the app, we are the only ones who can modify preferences.
        this.state = {
            activeStage: PermissionsModal.stages.filter(s => !PermissionsModal.stageIsComplete(s))[0],
            readyState: { key: "ready" },
        };
    }
    static webNotificationsDefault() {
        return NotificationManager.shared.permission == "default" && PreferenceManager.shared.preferences.useWebNotifications;
    }
    static anyIncompleteStages() {
        return PermissionsModal.stages.filter(s => !PermissionsModal.stageIsComplete(s)).length > 0;
    }
    static stageIsComplete(stage) {
        switch (stage) {
            case "notifications":
                return !PermissionsModal.webNotificationsDefault();
        }
    }
    static nextStage(stage) {
        const stages = PermissionsModal.stages;
        return stages.slice(stages.indexOf(stage) + 1).filter(s => !PermissionsModal.stageIsComplete(s))[0];
    }
    onNext() {
        switch (this.state.activeStage) {
            case "notifications":
                this.setState({ readyState: { key: "loading" } });
                NotificationManager.shared.requestPermission(permission => {
                    if (permission == "denied") {
                        this.setState({ readyState: { key: "error", message: i18n.t("permissions.notifications.denied") } });
                        return;
                    }
                    const nextStage = PermissionsModal.nextStage(this.state.activeStage);
                    if (nextStage == null) {
                        this.props.onClose();
                    }
                    else {
                        this.setState(prevState => ({
                            readyState: { key: "ready" },
                            activeStage: nextStage,
                        }));
                    }
                });
                break;
        }
    }
    onSkip() {
        switch (this.state.activeStage) {
            case "notifications":
                PreferenceManager.shared.updatePreferences({ useWebNotifications: false });
                break;
        }
        const nextStage = PermissionsModal.nextStage(this.state.activeStage);
        if (nextStage == null) {
            this.props.onClose();
        }
        else {
            this.setState(prevState => ({
                readyState: { key: "ready" },
                activeStage: nextStage,
            }));
        }
    }
    render() {
        const { activeStage, readyState } = this.state;
        let iconName;
        let title;
        switch (activeStage) {
            case "notifications":
                iconName = "bell";
                title = i18n.t("permissions.notifications.stageTitle");
                break;
        }
        return (React.createElement(Modal, { open: this.props.open },
            React.createElement(Header, { icon: iconName, content: title }),
            React.createElement(Modal.Content, null,
                React.createElement("p", null, i18n.t(`permissions.${activeStage}.body1`)),
                React.createElement("p", null, i18n.t(`permissions.${activeStage}.body2`)),
                readyState.key == "error" ? React.createElement(Message, { error: true, content: readyState.message }) : null),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { content: i18n.t("permissions.skip"), disabled: readyState.key == "loading", onClick: this.onSkip.bind(this) }),
                React.createElement(Button, { primary: true, loading: readyState.key == "loading", disabled: readyState.key == "loading", content: i18n.t("permissions.enable"), onClick: this.onNext.bind(this) }))));
    }
}
PermissionsModal.stages = ["notifications"];
