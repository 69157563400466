import * as React from "react";
import { Icon, Segment, TransitionablePortal } from "semantic-ui-react";
import { ThemeUtils } from "../models/theme";
import { PreferenceManager } from "../utils/preferenceManager";
export class Sheet extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            preferences: PreferenceManager.shared.preferences,
        };
    }
    componentDidMount() {
        PreferenceManager.shared.registerObserver(this);
    }
    componentWillUnmount() {
        PreferenceManager.shared.unregisterObserver(this);
    }
    preferencesManagerDidUpdatePreferences(newPrefs) {
        this.setState({ preferences: newPrefs });
    }
    render() {
        return (React.createElement(TransitionablePortal, { className: ThemeUtils.getClassName(this.state.preferences.theme), closeOnDocumentClick: false, open: this.props.open, onClose: this.props.onClose, transition: {
                animation: "fade up",
                duration: 200,
            } },
            React.createElement(Segment, { className: "sheet", style: {
                    width: "100%",
                    height: "calc(100% - 54px)",
                    position: 'fixed',
                    padding: "30px 30px 0",
                    left: 0,
                    bottom: 0,
                    zIndex: 1000,
                    ...this.props.style,
                } },
                React.createElement(Icon, { name: "x", link: true, color: "grey", size: "big", style: { position: "absolute", top: "10px", right: "5px" }, onClick: this.props.onClose }),
                this.props.children)));
    }
}
