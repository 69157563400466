export var ResponseStatus;
(function (ResponseStatus) {
    ResponseStatus[ResponseStatus["Success"] = 0] = "Success";
    ResponseStatus[ResponseStatus["Unauthorized"] = 1] = "Unauthorized";
    ResponseStatus[ResponseStatus["ClientFailure"] = 2] = "ClientFailure";
    ResponseStatus[ResponseStatus["ServerFailure"] = 3] = "ServerFailure";
    ResponseStatus[ResponseStatus["ConnectionFailure"] = 4] = "ConnectionFailure";
})(ResponseStatus || (ResponseStatus = {}));
export class HttpResponse {
    constructor(status, statusCode, responseJson, responseText, response) {
        this.status = status;
        this.statusCode = statusCode;
        this.responseJson = responseJson;
        this.responseText = responseText;
        this.response = response;
    }
    static responseFromRequest(request) {
        let responseText = null;
        let responseJson = null;
        if (request.responseType == '' || request.responseType == 'text') {
            responseText = request.responseText;
            try {
                responseJson = JSON.parse(request.responseText);
            }
            catch (e) {
                if (e instanceof TypeError) {
                    // Error in parsing the response
                    responseJson = null;
                }
            }
        }
        const status = (() => {
            if (request.status == 0) {
                // Internet or URL failure
                return ResponseStatus.ConnectionFailure;
            }
            if (request.status >= 200 && request.status < 300) {
                // Success!
                return ResponseStatus.Success;
            }
            if (request.status >= 400 && request.status < 500) {
                // Client Failure!!!!!
                if (request.status == 401) {
                    return ResponseStatus.Unauthorized;
                }
                return ResponseStatus.ClientFailure;
            }
            if (request.status >= 500 && request.status < 600) {
                // Server Failure!!!!!
                return ResponseStatus.ServerFailure;
            }
            console.error('¯\_(ツ)_/¯');
            return ResponseStatus.ServerFailure;
        })();
        return new HttpResponse(status, request.status, responseJson, responseText, request.response);
    }
}
export class HttpRequest {
    // Remains a class to allow for in-place changing
    constructor(method, url, urlParams, body, headers, responseType = null) {
        this.method = method;
        this.url = url;
        this.urlParams = urlParams;
        this.body = body;
        this.headers = headers;
        this.responseType = responseType;
    }
}
export class HttpClient {
    constructor(config) {
        this.config = config;
    }
    static objectsToParams(data) {
        return Object.keys(data).map((key) => `${key}=${encodeURIComponent(String(data[key]))}`).join('&');
    }
    buildUrl(httpRequest) {
        let requestUrl = (() => {
            if (this.config.baseUrl == null) {
                return httpRequest.url;
            }
            const urlBase = this.config.baseUrl.replace(/\/($)/g, '$1');
            const providedUrl = httpRequest.url.replace(/(^)\//g, '$1');
            return `${urlBase}/${providedUrl}`;
        })();
        if (httpRequest.urlParams != null && Object.keys(httpRequest.urlParams).length > 0) {
            requestUrl += `?${HttpClient.objectsToParams(httpRequest.urlParams)}`;
        }
        return requestUrl;
    }
    sendRequest(httpRequest, callback) {
        const request = new XMLHttpRequest();
        httpRequest = this.config.transformRequest ? this.config.transformRequest(httpRequest) : httpRequest;
        request.onreadystatechange = () => {
            if (request.readyState == request.DONE) {
                callback(httpRequest, HttpResponse.responseFromRequest(request));
            }
        };
        if (httpRequest.responseType != null) {
            request.responseType = httpRequest.responseType;
        }
        request.open(httpRequest.method, this.buildUrl(httpRequest), true);
        const headers = httpRequest.headers || {};
        Object.keys(headers).forEach((key) => {
            request.setRequestHeader(key, headers[key]);
        });
        function replacer(key, value) {
            // https://stackoverflow.com/questions/29085197/how-do-you-json-stringify-an-es6-map
            if (value instanceof Map) {
                return Object.fromEntries(value);
            }
            return value;
        }
        if (httpRequest.body != null) {
            try {
                request.send(JSON.stringify(httpRequest.body, replacer));
            }
            catch (e) {
                if (e instanceof TypeError) {
                    callback(httpRequest, new HttpResponse(ResponseStatus.ClientFailure, -1, null, null, null));
                }
            }
        }
        else {
            request.send(null);
        }
    }
    get(url, urlParams, callback, headers, responseType) {
        this.sendRequest(new HttpRequest('GET', url, urlParams, null, headers || null, responseType), callback);
    }
    put(url, urlParams, body, callback, headers, responseType) {
        this.sendRequest(new HttpRequest('PUT', url, urlParams, body, headers || null, responseType), callback);
    }
    post(url, urlParams, body, callback, headers, responseType) {
        this.sendRequest(new HttpRequest('POST', url, urlParams, body, headers || null, responseType), callback);
    }
    del(url, urlParams, callback, headers, responseType) {
        this.sendRequest(new HttpRequest('DELETE', url, urlParams, null, headers || null, responseType), callback);
    }
}
