import { HttpClient, ResponseStatus } from "./spoonClient";
import { config } from "../config";
export class UpdateManager {
    constructor() {
        this.observers = [];
        this.updateTimerId = null;
        this.updateStatus = { key: "upToDate" };
        this.http = new HttpClient({});
    }
    start() {
        if (config.configType == "debug") {
            config.debug("Ignoring UpdateManager.start() on debug");
            return;
        }
        if (this.updateTimerId != null) {
            config.error("Ignoring double-start of UpdateManager timer");
            return;
        }
        config.debug("Starting to check for update");
        this.checkForUpdates();
        this.updateTimerId = setInterval(() => this.checkForUpdates(), UpdateManager.UPDATE_INTERVAL);
    }
    checkForUpdates() {
        this.http.get("/version.txt", null, (request, response) => {
            if (response.status == ResponseStatus.Success && response.responseText != null) {
                const version = response.responseText.trim();
                const isFirstAvailableUpdate = this.updateStatus.key == "upToDate" && config.version != version;
                const isAdditionalUpdate = this.updateStatus.key == "updateAvailable" && this.updateStatus.newVersion != version;
                if (isFirstAvailableUpdate || isAdditionalUpdate) {
                    config.info(`Version ${version} is available`);
                    this.updateStatus = { key: "updateAvailable", newVersion: version };
                    this.observers.forEach(o => o.updateManagerDidChangeUpdateStatus(this.updateStatus));
                }
            }
            else {
                config.warn(`Could not get current version: ${response.responseText}`);
            }
        });
    }
    fakeAvailableUpdate() {
        this.updateStatus = { key: "updateAvailable", newVersion: "FAKE_VERSION" };
        this.observers.forEach(o => o.updateManagerDidChangeUpdateStatus(this.updateStatus));
    }
    update() {
        window.location.reload();
    }
    reset() {
        if (config.configType == "debug") {
            config.debug("Ignoring UpdateManager.reset() on debug");
            return;
        }
        if (this.updateTimerId == null) {
            config.error("Failed to stop UpdateManager timer, already stopped");
            return;
        }
        clearInterval(this.updateTimerId);
        this.updateTimerId = null;
        this.updateStatus = { key: "upToDate" };
        this.observers.forEach(o => o.updateManagerDidChangeUpdateStatus(this.updateStatus));
    }
    // UpdateManagerObservers
    registerObserver(observer) {
        if (this.observers.filter(o => o === observer).length == 0) {
            this.observers.push(observer);
        }
    }
    unregisterObserver(observer) {
        const observerIndex = this.observers.indexOf(observer);
        if (observerIndex != -1) {
            this.observers.splice(observerIndex, 1);
        }
    }
}
UpdateManager.UPDATE_INTERVAL = 10 * 60 * 1000; // 10 minutes
UpdateManager.shared = new UpdateManager();
