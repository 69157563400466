import * as i18n from "i18next";
import * as React from "react";
import { Button, Form } from "semantic-ui-react";
import { BryxLocal } from "../../utils/bryxLocal";
import { BryxWebSocket } from "../../utils/bryxWebSocket";
import { SupportUtils } from "../../utils/supportUtils";
import { UpdateManager } from "../../utils/updateManager";
export class DevToolsTab extends React.Component {
    render() {
        return (React.createElement(Form, { style: { minWidth: "300px", width: "40%", margin: "20px", padding: "1rem" } },
            React.createElement(Form.Field, null,
                React.createElement(Button, { href: `data:text/plain;base64,${SupportUtils.logsAttachment()}`, download: "logs.txt", content: i18n.t("settings.devTools.downloadLogs") })),
            React.createElement(Form.Field, null,
                React.createElement(Button, { href: `data:text/plain;base64,${SupportUtils.bryxItemsAttachment()}`, download: "localStorage.txt", content: i18n.t("settings.devTools.downloadUserData") })),
            React.createElement(Form.Field, null,
                React.createElement(Button, { href: `data:text/plain;base64,${SupportUtils.deviceInfoAttachment()}`, download: "deviceInfo.txt", content: i18n.t("settings.devTools.downloadDeviceInfo") })),
            React.createElement(Form.Field, null,
                React.createElement(Button, { color: "red", content: i18n.t("settings.devTools.clearForceDeviceSupport"), onClick: () => {
                        BryxLocal.setForceDeviceSupport(false);
                        window.location.reload(true);
                    } })),
            React.createElement(Form.Field, null,
                React.createElement(Button, { color: "green", content: i18n.t("settings.devTools.fakeUpdate"), onClick: () => {
                        UpdateManager.shared.fakeAvailableUpdate();
                    } })),
            React.createElement(Form.Field, null,
                React.createElement(Button, { color: "green", content: i18n.t("settings.devTools.toggleFakeDisconnect"), onClick: () => {
                        BryxWebSocket.shared.toggleFakeDisconnect();
                    } }))));
    }
}
