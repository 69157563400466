import * as i18n from "i18next";
import * as React from "react";
import { Card, Form, Message } from "semantic-ui-react";
import { BryxApi } from "../../utils/bryxApi";
export class SCUUnitTab extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            actionStatus: { key: "loading" },
            unitOptions: [],
            onScuUnits: [],
            zones: [],
        };
    }
    loadUnitOptions(newActionStatus = "ready") {
        BryxApi.getScuUnitOptions(this.props.scuId, result => {
            if (result.success == true) {
                this.setState({
                    unitOptions: result.value.unitOptions,
                    zones: result.value.zones,
                    actionStatus: { key: newActionStatus },
                });
            }
            else {
                this.setState({
                    actionStatus: { key: "failed", message: result.message },
                });
            }
        });
    }
    save(zoneId, units) {
        const { scuId } = this.props;
        BryxApi.updateScuZone(scuId, zoneId, units, result => {
            if (result.success) {
                this.loadUnitOptions("success");
                this.setState({
                    actionStatus: { key: "loading" },
                });
            }
            else {
                this.setState({
                    actionStatus: { key: "failed", message: result.message },
                });
            }
        });
    }
    handleDismiss() {
        if (this.state.actionStatus.key == "success") {
            this.setState({ actionStatus: { key: "ready" } });
        }
    }
    componentDidMount() {
        this.setState({
            actionStatus: { key: "loading" },
        });
        this.loadUnitOptions();
    }
    render() {
        const { actionStatus, zones, unitOptions } = this.state;
        return (React.createElement("div", { style: { paddingTop: "15px", flex: 1, height: "100%", overflowY: "scroll", padding: "5px 1rem" } },
            actionStatus.key == "failed" ? (React.createElement(Message, { negative: true, content: actionStatus.message })) : null,
            actionStatus.key == "success" ? (React.createElement(Message, { onDismiss: this.handleDismiss.bind(this), positive: true, content: i18n.t("stations.specific.units.success") })) : null,
            actionStatus.key == "ready" || actionStatus.key == "success" ? (React.createElement(Card.Group, null, zones.map(zone => (React.createElement(Card, { key: zone.id, style: { minWidth: "20%", maxWidth: "500px" } },
                React.createElement(Card.Content, null,
                    React.createElement(Card.Header, null, zone.name),
                    React.createElement(Card.Description, null,
                        React.createElement(Form, null,
                            React.createElement(Form.Field, null,
                                React.createElement(Form.Dropdown, { multiple: true, selection: true, search: true, options: unitOptions
                                        .map((u) => ({ key: u.id, value: u.id, text: u.name })), onChange: (e, d) => {
                                        zone.units = d.value;
                                        zones.forEach((z, i) => {
                                            if (z.id == zone.id) {
                                                zones[i] = zone;
                                            }
                                        });
                                        this.setState({ zones: zones });
                                    }, value: zone.units })),
                            React.createElement(Form.Button, { content: "Save", color: "blue", icon: "save", loading: false, onClick: (e, d) => {
                                    this.save(zone.id, zone.units);
                                } }))))))))) : null));
    }
}
