import { ParseUtils } from "@bryxinc/lunch";
import { apiFailure, apiSuccess } from "../utils/bryxApi";
import { Hydrant } from "./hydrant";
import { FullJob } from "./job";
import { Responder } from "./responder";
import { Supplemental } from "./supplemental";
export function parseSpecificJobUpdate(message) {
    try {
        switch (message.key) {
            case "serverUpdate":
                const type = ParseUtils.getString(message.data, "type");
                switch (type) {
                    case "assignments":
                        return apiSuccess({
                            key: "assignments",
                            unitShortNames: ParseUtils.getArray(message.data, "unitShortNames"),
                        });
                    case "supplementals":
                        return apiSuccess({
                            key: "supplementals",
                            supplementals: ParseUtils.getArrayOfSubobjects(message.data, "supplementals", Supplemental.parse, "warn"),
                        });
                    case "responders":
                        return apiSuccess({
                            key: "responders",
                            responders: ParseUtils.getArrayOfSubobjects(message.data, "responders", Responder.parse, "warn"),
                        });
                    case "hydrants":
                        return apiSuccess({
                            key: "hydrants",
                            hydrants: ParseUtils.getArrayOfSubobjects(message.data, "hydrants", Hydrant.parse, "warn"),
                        });
                    case "patients":
                    default:
                        return apiFailure(null, `Invalid SpecificJobUpdate subscribe response type: ${type}`);
                }
            case "subscribeResponse":
                if (message.successful == false) {
                    return apiFailure(message.errorReason, message.errorReason);
                }
                return apiSuccess({
                    key: "replace",
                    job: ParseUtils.getSubobject(message, "initialData", FullJob.parse),
                });
            default:
                return apiSuccess(null);
        }
    }
    catch (e) {
        return apiFailure(null, `Invalid SpecificJobUpdate Model: ${e.message}`);
    }
}
