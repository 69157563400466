import * as i18n from "i18next";
import * as React from "react";
import { Button, Card, Icon, Loader, Message } from "semantic-ui-react";
import { ZeroStateView } from "../../components/zeroStateView";
import { BryxApi } from "../../utils/bryxApi";
import { NotificationUtils } from "../../utils/functions";
import { SCUManager } from "../../utils/scuManager";
export class SCUActionsPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            scusListStatus: SCUManager.shared.scusListsStatus,
        };
    }
    componentDidMount() {
        SCUManager.shared.registerListObserver(this);
    }
    componentWillUnmount() {
        SCUManager.shared.unregisterListObserver(this);
    }
    scuManagerDidUpdateScus(status) {
        this.setState({ scusListStatus: status });
    }
    performSCUAction(agencyId, id, scuName, actionName) {
        BryxApi.triggerAgencyAction(agencyId, id, (result) => {
            if (!result.success) {
                console.error(`Unable to trigger agency action: ${result.message}`);
                NotificationUtils.notify({
                    title: i18n.t("stations.specific.actions.errorPerforming"),
                    message: i18n.t("stations.specific.actions.errorPerformingDesc"),
                    level: "error",
                    autoDismiss: 20,
                });
            }
            else {
                NotificationUtils.notify({
                    title: i18n.t("stations.specific.actions.performed"),
                    message: i18n.t("stations.specific.actions.performedDesc", { scu: scuName, action: actionName }),
                    level: "success",
                    autoDismiss: 10,
                });
            }
        });
    }
    render() {
        const { selectedAgencyId } = this.props;
        const { scusListStatus } = this.state;
        let scus = scusListStatus.key == "active" ? scusListStatus.scus : [];
        if (scus.length == 0) {
            return (React.createElement("div", { style: { flex: 1, display: "flex", alignItems: "center", justifyContent: "center" } },
                React.createElement(ZeroStateView, { header: i18n.t("actions.noActions"), subheader: i18n.t("actions.aboutAgencyActions") })));
        }
        scus = scus.filter(scu => scu.agencyIds.indexOf(selectedAgencyId) > -1 && scu.status.type != "offline");
        const renderedIds = new Set();
        const getScuActions = (scu) => {
            const actions = scu.actions.map(action => {
                if (renderedIds.has(action.id)) {
                    return null;
                }
                renderedIds.add(action.id);
                return {
                    scuId: scu.id,
                    scuName: scu.name,
                    action: action,
                };
            }).filter(a => a != null);
            return actions.sort((a, b) => a.action.order - b.action.order);
        };
        const scuActions = []
            .concat(...scus.map(scu => getScuActions(scu)));
        return (React.createElement("div", { id: "jobsPage", className: "pageContent" },
            scusListStatus.key == "failed" ? React.createElement(Message, { negative: true, content: scusListStatus.message }) : null,
            scusListStatus.key == "loading" ? React.createElement(Loader, { active: true }) : null,
            React.createElement(Card.Group, { style: { margin: "20px" } }, scuActions.map(actionDisplay => {
                return (React.createElement(Card, { key: `${actionDisplay.scuId}:${actionDisplay.action.id}` },
                    React.createElement(Card.Content, null,
                        React.createElement(Card.Header, null,
                            actionDisplay.action.icon ? (React.createElement(Icon, { name: actionDisplay.action.icon })) : null,
                            actionDisplay.action.name)),
                    React.createElement(Card.Content, null,
                        React.createElement(Card.Description, null, actionDisplay.action.description)),
                    React.createElement(Card.Content, { extra: true },
                        React.createElement("div", { style: { display: "flex" } },
                            React.createElement("div", { style: { marginTop: "0.5em" } },
                                React.createElement("span", null, actionDisplay.scuName)),
                            React.createElement("div", { style: { flex: "1 1 auto", textAlign: "right" } },
                                React.createElement(Button, { basic: true, color: "green", onClick: () => this.performSCUAction(selectedAgencyId, actionDisplay.action.id, actionDisplay.scuName, actionDisplay.action.name) }, i18n.t("actions.activate")))))));
            }))));
    }
}
