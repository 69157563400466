import { config } from "../config";
import { BryxNotification, BryxNotificationAlertType, BryxNotificationType } from "../models/bryxNotification";
import { JobType } from "../models/jobTypeInformation";
import { AudioUtils } from "./audioUtils";
import { BryxApi } from "./bryxApi";
import { DateUtils } from "./dateUtils";
import { PreferenceManager } from "./preferenceManager";
export class NotificationManager {
    constructor() {
        this.activeGroupId = null;
        this.notificationObservers = [];
        this.permissionObserver = [];
        this.status = { key: "loading" };
    }
    // Initialization / Deinitialization
    startLoadingNotifications() {
        BryxApi.subscribeToNotifications(NotificationManager.notificationManagerKey, result => {
            if (result.success == true) {
                switch (result.value.key) {
                    case "replace":
                        const newNotifications = result.value.notifications.sort(BryxNotification.compare);
                        config.info(`Received ${newNotifications.length} initial notifications`);
                        this.updateStatus({
                            key: "active",
                            notifications: newNotifications,
                        });
                        break;
                    case "new":
                        if (this.status.key != "active") {
                            config.error(`Received new notification but Status = ${this.status.key}`);
                            return;
                        }
                        config.info(`Received new notification: ${BryxNotificationType[result.value.notification.content.type]}`);
                        const existingIndex = this.status.notifications.map(n => n.id).indexOf(result.value.notification.id);
                        if (existingIndex != -1) {
                            this.status.notifications[existingIndex] = result.value.notification;
                        }
                        else {
                            const notification = result.value.notification;
                            if (notification.content.type == BryxNotificationType.message && this.activeGroupId == notification.content.groupId && !document.hidden) {
                                config.debug("Auto-acknowledging group message notification");
                                this.markNotificationsRead([notification], false);
                                return;
                            }
                            this.status.notifications.push(notification);
                            const now = DateUtils.bryxNow();
                            // Because we're moving things around on the user, take this time to remove any expired notifications
                            this.status.notifications = this.status.notifications.filter(n => n.expirationDate.getTime() >= now.getTime());
                            this.status.notifications.sort(BryxNotification.compare);
                            this.notificationObservers.forEach(o => o.notificationManagerDidUpdateStatus(this.status));
                            this.playAlertForNotification(notification);
                            this.displayNotification(notification);
                        }
                        break;
                }
            }
            else {
                config.warn(`Failed to load notifications: ${result.debugMessage}`);
                this.updateStatus({
                    key: "failed",
                    message: result.message,
                });
            }
        });
    }
    stopLoadingNotifications() {
        BryxApi.unsubscribe(NotificationManager.notificationManagerKey);
    }
    updateStatus(status) {
        this.status = status;
        this.notificationObservers.forEach(o => o.notificationManagerDidUpdateStatus(this.status));
    }
    reset() {
        this.updateStatus({ key: "loading" });
        this.stopLoadingNotifications();
    }
    // Notification Permissions
    get permission() {
        if (!this.notificationsSupported) {
            return "unsupported";
        }
        else {
            // https://github.com/Microsoft/TypeScript/issues/14701
            return Notification.permission;
        }
    }
    get notificationsSupported() {
        return !!window.Notification;
    }
    requestPermission(callback) {
        if (!this.notificationsSupported) {
            callback("unsupported");
            return;
        }
        // `requestPermission` returns a Promise, which is preferred over the old callback syntax.
        // However, Safari 12.1.1 still does not support the Promise.
        Notification.requestPermission(permission => {
            callback(permission);
            this.permissionObserver.forEach(o => o.notificationManagerDidUpdateNotificationPermission(permission));
        });
    }
    // Incoming Notifications
    soundResourceForNotification(notification) {
        if (notification.alert.type == BryxNotificationAlertType.sound) {
            return AudioUtils.resourceFromSoundId(notification.alert.soundId);
        }
        else {
            return null;
        }
    }
    playAlertForNotification(notification) {
        const resource = this.soundResourceForNotification(notification);
        if (resource != null) {
            resource.play();
        }
    }
    static notificationResourceFromJobType(type) {
        return `/resources/assets/notification_icons/job_${JobType[type]}_icon.png`;
    }
    displayNotification(notification) {
        if (!PreferenceManager.shared.preferences.useWebNotifications || this.permission != "granted") {
            return;
        }
        let icon = undefined;
        switch (notification.content.type) {
            case BryxNotificationType.job:
                icon = NotificationManager.notificationResourceFromJobType(notification.content.jobType);
                break;
            case BryxNotificationType.supplemental:
                break;
            case BryxNotificationType.message:
                break;
            case BryxNotificationType.scu:
                break;
        }
        const notificationElement = new Notification(notification.title, { body: notification.message, icon: icon });
        notificationElement.onclick = () => this.notifyClickNotification(notification);
    }
    // Acknowledgement
    markNotificationsRead(readNotifications, notify = true) {
        if (this.status.key != "active") {
            config.error(`Trying to mark notifications as read but Status = ${this.status.key}`);
            return;
        }
        const notifications = this.status.notifications;
        if (readNotifications.length == 0) {
            return;
        }
        readNotifications.forEach(n => {
            const resource = this.soundResourceForNotification(n);
            if (resource != null && !resource.paused) {
                resource.pause();
                resource.currentTime = 0;
            }
        });
        const ids = readNotifications.map(n => n.id);
        BryxApi.markNotificationRead(ids, result => {
            if (result.success == false) {
                config.warn(`Failed to acknowledge [${ids.map(id => `notification@${id}`).join(", ")}]: ${result.debugMessage}`);
            }
        });
        this.status.notifications = notifications.filter(n => ids.indexOf(n.id) == -1);
        if (notify) {
            this.notificationObservers.forEach(o => o.notificationManagerDidUpdateStatus(this.status));
        }
    }
    notifyReadJob(jobId) {
        if (this.status.key != "active") {
            return;
        }
        const matchedJobNotifications = this.status.notifications.filter(n => n.content.type == BryxNotificationType.job && n.content.jobId == jobId);
        const matchedSupplementalNotifications = this.status.notifications.filter(n => n.content.type == BryxNotificationType.supplemental && n.content.jobId == jobId);
        this.markNotificationsRead(matchedJobNotifications.concat(matchedSupplementalNotifications));
    }
    setActiveGroup(groupId) {
        if (groupId == this.activeGroupId) {
            return;
        }
        if (groupId != null) {
            this.activeGroupId = groupId;
            if (this.status.key != "active") {
                return;
            }
            this.markNotificationsRead(this.status.notifications.filter(n => n.content.type == BryxNotificationType.message && n.content.groupId == groupId));
        }
        else {
            this.activeGroupId = null;
        }
    }
    notifyClickNotification(notification) {
        this.markNotificationsRead([notification]);
        this.notificationObservers.forEach(o => o.notificationManagerDidReceiveClick(notification));
    }
    notifyClearNotification(notification) {
        this.markNotificationsRead([notification]);
    }
    notifyClearAll() {
        if (this.status.key != "active") {
            config.error(`Trying to mark notifications as read but Status = ${this.status.key}`);
            return;
        }
        this.markNotificationsRead(this.status.notifications);
    }
    // NotificationManagerObservers
    registerObserver(observer) {
        if (this.notificationObservers.filter(o => o === observer).length == 0) {
            this.notificationObservers.push(observer);
        }
    }
    unregisterObserver(observer) {
        const observerIndex = this.notificationObservers.indexOf(observer);
        if (observerIndex != -1) {
            this.notificationObservers.splice(observerIndex, 1);
        }
    }
    registerPermissionObserver(observer) {
        if (this.permissionObserver.filter(o => o === observer).length == 0) {
            this.permissionObserver.push(observer);
        }
    }
    unregisterPermissionObserver(observer) {
        const observerIndex = this.permissionObserver.indexOf(observer);
        if (observerIndex != -1) {
            this.permissionObserver.splice(observerIndex, 1);
        }
    }
}
NotificationManager.shared = new NotificationManager();
NotificationManager.notificationManagerKey = "notificationManager";
