import { ParseUtils } from "@bryxinc/lunch";
import { toTitleCase } from "../utils/functions";
export class AgencyRegion {
    constructor(agencyId, city, state, bufferedBoundary) {
        this.agencyId = agencyId;
        this.city = city;
        this.state = state;
        this.bufferedBoundary = bufferedBoundary;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new AgencyRegion(ParseUtils.getString(o, "agencyId"), toTitleCase(ParseUtils.getString(o, "city")), ParseUtils.getString(o, "state").toUpperCase(), o["bufferedBoundary"]));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid AgencyRegion: ${e.message}`);
        }
    }
}
