import { ParseUtils } from "@bryxinc/lunch";
export class Geocode {
    constructor(location, centroid) {
        this.location = location;
        this.centroid = centroid;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new Geocode(o["location"], o["centroid"]));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Geocode Object: ${e.message}`);
        }
    }
}
