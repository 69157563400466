import { ParseUtils } from "@bryxinc/lunch";
export class InformationalPair {
    constructor(key, value) {
        this.key = key;
        this.value = value;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new InformationalPair(ParseUtils.getString(o, "key"), ParseUtils.getString(o, "value")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid InformationalPair Model: ${e.message}`);
        }
    }
}
export class SiteSurvey {
    constructor(critical, advisory, informational) {
        this.critical = critical;
        this.advisory = advisory;
        this.informational = informational;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new SiteSurvey(ParseUtils.getStringOrNull(o, "critical"), ParseUtils.getArray(o, "advisory"), ParseUtils.getArrayOfSubobjects(o, "informational", InformationalPair.parse, "warn")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid SiteSurvey Model: ${e.message}`);
        }
    }
}
