import * as React from 'react';
import { withRouter } from "react-router";
import { Dropdown, Menu } from "semantic-ui-react";
class MenuItemWithoutRouter extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleLink = this.handleLink.bind(this);
    }
    handleLink(path) {
        this.props.history.push(path);
    }
    render() {
        const { to, children, location, disabled } = this.props;
        return (React.createElement(Menu.Item, { active: location.pathname.indexOf(to) == 0, disabled: disabled, onClick: () => this.handleLink(to) }, children));
    }
}
class DropdownItemWithoutRouter extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleLink = this.handleLink.bind(this);
    }
    handleLink(path) {
        this.props.history.push(path);
    }
    render() {
        const { to, children, location } = this.props;
        return (React.createElement(Dropdown.Item, { active: location.pathname.indexOf(to) == 0, onClick: () => this.handleLink(to) }, children));
    }
}
export const RouterMenuItem = withRouter(MenuItemWithoutRouter);
export const RouterDropdownItem = withRouter(DropdownItemWithoutRouter);
