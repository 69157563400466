import * as i18n from "i18next";
import * as React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import { Dimmer, Form, Grid, Loader, Message } from "semantic-ui-react";
import { config } from "../config";
import { JobType } from "../models/jobTypeInformation";
import { BryxApi } from "../utils/bryxApi";
import { BryxLocal } from "../utils/bryxLocal";
import { Debouncer } from "../utils/debouncer";
import { enumNames, nullIfBlank, parseNumberStatus } from "../utils/functions";
import { PreferenceManager } from "../utils/preferenceManager";
import { BryxGeoJSONLayer } from "./bryxGeoJSONLayer";
import { BryxMap } from "./bryxMap";
import * as L from "leaflet";
export class DispatchJobModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.geocodeDebouncer = new Debouncer(() => this.onGeocode(), DispatchJobModal.geocodeDebouceTime);
        this.state = this.getInitialState();
    }
    preferencesManagerDidUpdatePreferences(newPrefs) {
        console.log("preferencesManagerDidUpdatePreferences()", newPrefs);
        const preferredAgencyId = newPrefs.preferredAgencyId;
        this.setState({
            selectedAgency: preferredAgencyId ? this.props.agencies.filter(a => a.id == preferredAgencyId)[0] : this.props.agencies[0],
        });
    }
    getDefaultType() {
        const savedJobType = BryxLocal.getCreateJobType();
        return savedJobType != null ? savedJobType : JobType.fire;
    }
    getInitialState() {
        const preferredAgencyId = PreferenceManager.shared.preferences.preferredAgencyId;
        const { agencies, preselectStatus, selectedAgencyId, city, state } = this.props;
        return {
            synopsis: null,
            jobType: this.getDefaultType(),
            address: null,
            latitudeString: null,
            longitudeString: null,
            typeDesc: null,
            incidentId: null,
            dispatchGroupsStatus: { key: "loading" },
            selectedDispatchGroups: preselectStatus.key == "selected" ? preselectStatus.units : [],
            actionStatus: { key: "ready" },
            geocodeStatus: { key: "ready" },
            selectedAgency: agencies.filter(a => a.id == preferredAgencyId || selectedAgencyId)[0],
            city,
            state,
        };
    }
    componentDidMount() {
        PreferenceManager.shared.registerObserver(this);
        this.loadDispatchGroups();
    }
    componentWillUnmount() {
        PreferenceManager.shared.unregisterObserver(this);
    }
    componentDidUpdate(prevProps, prevState, prevContext) {
        if (!prevProps.open && this.props.open) {
            const { agencies, selectedAgencyId, city, state } = this.props;
            this.setState({
                selectedAgency: agencies.filter(a => a.id == selectedAgencyId)[0],
                city,
                state,
            });
            this.loadDispatchGroups();
            // this.loadAgencyRegion();
        }
    }
    getDefaultDispatchGroups(allGroups) {
        if (this.props.preselectStatus.key == "selected") {
            return this.props.preselectStatus.units;
        }
        if (allGroups.length == 1) {
            return allGroups.map(g => g.id);
        }
        return [];
    }
    loadDispatchGroups() {
        this.setState({ dispatchGroupsStatus: { key: "loading" } });
        BryxApi.getDispatchGroups(result => {
            if (result.success == true) {
                const { preselectStatus } = this.props;
                let groups = result.value;
                if (preselectStatus.key == "selected") {
                    groups = groups.filter(g => preselectStatus.agencyIds.indexOf(g.agencyId) > -1);
                }
                else {
                    groups = groups.filter(g => g.agencyId == this.state.selectedAgency.id);
                }
                this.setState({
                    dispatchGroupsStatus: { key: "ready", groups },
                    selectedDispatchGroups: this.getDefaultDispatchGroups(groups),
                });
            }
            else {
                config.warn(`Error getting all dispatch groups for agency: ${this.state.selectedAgency.id}: ${result.debugMessage}`);
                this.setState({
                    dispatchGroupsStatus: {
                        key: "error",
                        message: result.message,
                    },
                });
            }
        });
    }
    getLatitudeStatus() {
        return parseNumberStatus(this.state.latitudeString);
    }
    getLongitudeStatus() {
        return parseNumberStatus(this.state.longitudeString);
    }
    getLocationStatus() {
        const latitudeStatus = this.getLatitudeStatus();
        const longitudeStatus = this.getLongitudeStatus();
        return latitudeStatus.key == "ok" && longitudeStatus.key == "ok" ? {
            key: "ok",
            latitude: latitudeStatus.value,
            longitude: longitudeStatus.value,
        } : { key: "incomplete" };
    }
    getSubmissionStatus() {
        const { synopsis, jobType, address, geocodeStatus, selectedDispatchGroups, typeDesc, incidentId } = this.state;
        const latitudeStatus = this.getLatitudeStatus();
        const longitudeStatus = this.getLongitudeStatus();
        if (synopsis == null || address == null || this.state.city == null || this.state.state == null || selectedDispatchGroups.length == 0 || latitudeStatus.key == "error" || longitudeStatus.key == "error" || geocodeStatus.key == "loading") {
            return { key: "incomplete" };
        }
        return {
            key: "ok",
            synopsis: synopsis,
            jobType: jobType,
            address: address,
            city: this.state.city,
            state: this.state.state,
            latitude: latitudeStatus.key == "ok" ? latitudeStatus.value : null,
            longitude: longitudeStatus.key == "ok" ? longitudeStatus.value : null,
            dispatchGroups: selectedDispatchGroups,
            typeDesc: typeDesc,
            incidentId: incidentId,
        };
    }
    onClickCreate() {
        const submissionStatus = this.getSubmissionStatus();
        if (submissionStatus.key != "ok") {
            return;
        }
        this.setState({ actionStatus: { key: "loading" } });
        BryxApi.createJob(submissionStatus.synopsis, submissionStatus.jobType, submissionStatus.address, submissionStatus.city, submissionStatus.state, submissionStatus.latitude, submissionStatus.longitude, submissionStatus.dispatchGroups, submissionStatus.typeDesc, submissionStatus.incidentId, (result) => {
            if (result.success == true) {
                this.setState(prevState => ({
                    actionStatus: { key: "success" },
                    synopsis: null,
                    jobType: this.getDefaultType(),
                    address: null,
                    city: this.props.city,
                    state: this.props.state,
                    latitudeString: null,
                    longitudeString: null,
                    selectedDispatchGroups: prevState.dispatchGroupsStatus.key == "ready" ? this.getDefaultDispatchGroups(prevState.dispatchGroupsStatus.groups) : [],
                }), () => this.props.loadAgencyRegion(this.state.selectedAgency.id));
            }
            else {
                console.warn(`Error creating job: ${this.state.selectedAgency.id}: ${result.debugMessage}`);
                this.setState({
                    actionStatus: {
                        key: "error",
                        message: result.message,
                    },
                });
            }
        });
    }
    postGeocodeUpdate() {
        if (this.getLocationStatus().key == "ok") {
            return;
        }
        this.setState({
            geocodeStatus: { key: "loading", loadId: new Date().getTime().toString() },
        }, () => this.geocodeDebouncer.postUpdate());
    }
    onGeocode() {
        const { address, geocodeStatus, selectedAgency } = this.state;
        const { city, state } = this.props;
        if (address == null || city == null || state == null || geocodeStatus.key != "loading") {
            return;
        }
        const loadId = geocodeStatus.loadId;
        BryxApi.geocode(selectedAgency.id, address.replace(/^\s+|\s+$/g, ""), city.replace(/^\s+|\s+$/g, ""), state.replace(/^\s+|\s+$/g, ""), result => {
            this.setState((prevState) => {
                if (prevState.geocodeStatus.key != "loading" || prevState.geocodeStatus.loadId != loadId) {
                    // Don't set any values if the user aborted the request
                    return prevState;
                }
                if (result.success == true) {
                    prevState.geocodeStatus = {
                        key: "success",
                        geocodeLocation: result.value,
                    };
                    prevState.latitudeString = result.value.centroid.coordinates[1].toString();
                    prevState.longitudeString = result.value.centroid.coordinates[0].toString();
                }
                else {
                    // State is set but unused, user can choose to enter a different address, type or select the coordinates, or submit without a location
                    console.warn(`Failed to geocode location: ${result.debugMessage || result.message}`);
                    prevState.geocodeStatus = { key: "error", message: result.message };
                }
                return prevState;
            });
        });
    }
    render() {
        const { dispatchGroupsStatus, geocodeStatus, synopsis, jobType, typeDesc, incidentId, address, city, state, latitudeString, longitudeString, selectedDispatchGroups, actionStatus } = this.state;
        const dispatchGroupOptions = this.state.dispatchGroupsStatus.key == "ready" ? (this.state.dispatchGroupsStatus.groups.map(g => ({
            text: g.name,
            value: g.id,
        }))) : [];
        let actionPanel = null;
        if (actionStatus.key == "error") {
            actionPanel = (React.createElement(Message, { negative: true, header: i18n.t("jobs.createJob.failedToCreateJobHeader"), content: actionStatus.message }));
        }
        else if (actionStatus.key == "success") {
            actionPanel = (React.createElement(Message, { positive: true, header: i18n.t("jobs.createJob.successfullyCreatedJobHeader"), content: i18n.t("jobs.createJob.successfullyCreatedJobBody") }));
        }
        const locationStatus = this.getLocationStatus();
        let dimmerContent = null;
        if (geocodeStatus.key == "loading") {
            dimmerContent = (React.createElement(Loader, { active: true },
                React.createElement(Button, { size: "tiny", color: "grey", compact: true, content: i18n.t("jobs.createJob.cancelGeocode"), onClick: () => this.setState({ geocodeStatus: { key: "ready" } }) })));
        }
        let bounds;
        if (locationStatus.key == "ok") {
            bounds = L.latLng([locationStatus.latitude, locationStatus.longitude]).toBounds(300);
        }
        return (React.createElement(Modal, { size: "large", open: this.props.open, onClose: this.props.onClose },
            React.createElement(Header, { content: i18n.t("jobs.createJob.createJob") }),
            React.createElement(Modal.Content, null,
                React.createElement("div", null,
                    React.createElement(Message, { info: true, icon: "info circle", content: i18n.t("jobs.createJob.createJobInfoBody") }),
                    React.createElement(Grid, null,
                        React.createElement(Grid.Row, null,
                            React.createElement(Grid.Column, { width: 8, style: { display: "flex" } },
                                React.createElement(Dimmer.Dimmable, { blurring: true, dimmed: dimmerContent != null, style: { display: "flex", flex: 1 } },
                                    React.createElement(Dimmer, { active: dimmerContent != null, inverted: true }, dimmerContent),
                                    React.createElement(BryxMap, { ...this.props, bounds: bounds, onclick: e => this.setState({
                                            latitudeString: e.latlng.lat.toString(),
                                            longitudeString: e.latlng.lng.toString(),
                                        }) }, locationStatus.key == "ok" ? React.createElement(BryxGeoJSONLayer, { ...this.props, geojson: { type: "Point", coordinates: [locationStatus.longitude, locationStatus.latitude] } }) : null))),
                            React.createElement(Grid.Column, { width: 8 },
                                React.createElement(Form, { style: { marginBottom: "20px" } },
                                    React.createElement(Form.Group, null,
                                        React.createElement(Form.Input, { type: "text", autoFocus: true, width: 10, required: true, label: i18n.t("jobs.createJob.synopsis"), placeholder: i18n.t("jobs.createJob.synopsis"), value: synopsis || "", onChange: (e, d) => this.setState({ synopsis: nullIfBlank(d.value) }) }),
                                        React.createElement(Form.Input, { type: "text", width: 6, label: i18n.t("jobs.createJob.incidentId"), placeholder: i18n.t("jobs.createJob.incidentId"), value: incidentId || "", onChange: (e, d) => this.setState({ incidentId: nullIfBlank(d.value) }) })),
                                    React.createElement(Form.Group, null,
                                        React.createElement(Form.Dropdown, { required: true, width: 6, label: i18n.t("jobs.createJob.jobType"), placeholder: i18n.t("jobs.createJob.jobType"), selection: true, value: JobType[jobType], options: enumNames(JobType).map(typeName => ({
                                                key: typeName,
                                                value: typeName,
                                                text: i18n.t(`jobs.jobTypes.${typeName}`),
                                            })), onChange: (e, d) => {
                                                const newType = JobType[d.value];
                                                BryxLocal.setCreateJobType(newType);
                                                this.setState({
                                                    jobType: newType,
                                                });
                                            } }),
                                        React.createElement(Form.Input, { type: "text", width: 10, label: i18n.t("jobs.createJob.typeDesc"), placeholder: i18n.t("jobs.createJob.typeDesc"), value: typeDesc || "", onChange: (e, d) => this.setState({ typeDesc: nullIfBlank(d.value) }) })),
                                    React.createElement(Form.Group, { widths: "equal" },
                                        React.createElement(Form.Input, { type: "text", required: true, label: i18n.t("jobs.createJob.address"), placeholder: i18n.t("jobs.createJob.address"), value: address || "", onChange: (e, d) => this.setState({
                                                address: nullIfBlank(d.value),
                                            }, () => this.postGeocodeUpdate()) })),
                                    React.createElement(Form.Group, null,
                                        React.createElement(Form.Input, { type: "text", width: 11, required: true, label: i18n.t("jobs.createJob.city"), placeholder: i18n.t("jobs.createJob.city"), value: city || "", onChange: (e, d) => this.setState({
                                                city: nullIfBlank(d.value),
                                            }, () => this.postGeocodeUpdate()) }),
                                        React.createElement(Form.Input, { type: "text", width: 5, required: true, label: i18n.t("jobs.createJob.state"), placeholder: i18n.t("jobs.createJob.state"), value: state || "", onChange: (e, d) => this.setState({
                                                state: nullIfBlank(d.value),
                                            }, () => this.postGeocodeUpdate()) })),
                                    React.createElement(Form.Group, { widths: "equal" },
                                        React.createElement(Form.Input, { type: "text", label: i18n.t("jobs.createJob.latitude"), placeholder: i18n.t("jobs.createJob.latitude"), value: latitudeString || "", error: this.getLatitudeStatus().key == "error", disabled: geocodeStatus.key == "loading", onChange: (e, d) => this.setState({ latitudeString: nullIfBlank(d.value) }) }),
                                        React.createElement(Form.Input, { type: "text", label: i18n.t("jobs.createJob.longitude"), placeholder: i18n.t("jobs.createJob.longitude"), value: longitudeString || "", error: this.getLongitudeStatus().key == "error", disabled: geocodeStatus.key == "loading", onChange: (e, d) => this.setState({ longitudeString: nullIfBlank(d.value) }) })),
                                    React.createElement(Form.Dropdown, { required: true, search: true, upward: true, multiple: true, selection: true, noResultsMessage: i18n.t("jobs.createJob.noOtherDispatchGroups"), label: i18n.t("jobs.createJob.dispatchGroups"), placeholder: dispatchGroupsStatus.key == "loading" ? i18n.t("general.loading") : i18n.t("jobs.createJob.dispatchGroups"), options: dispatchGroupOptions, value: selectedDispatchGroups, loading: dispatchGroupsStatus.key == "loading", onChange: (e, d) => {
                                            const newDispatchGroupIds = d.value;
                                            this.setState((prevState) => {
                                                if (prevState.dispatchGroupsStatus.key == "ready") {
                                                    prevState.selectedDispatchGroups = prevState.dispatchGroupsStatus.groups.filter(g => newDispatchGroupIds.some(ng => ng == g.id)).map(g => g.id);
                                                }
                                                return prevState;
                                            });
                                        } }),
                                    React.createElement(Form.Group, null,
                                        React.createElement(Form.Button, { type: "button", primary: true, disabled: this.getSubmissionStatus().key != "ok", loading: actionStatus.key == "loading", content: i18n.t("general.dispatch"), onClick: () => this.onClickCreate() }),
                                        React.createElement(Form.Button, { type: "button", disabled: geocodeStatus.key != "success", content: i18n.t("jobs.createJob.clearGeocode"), onClick: () => this.setState({ latitudeString: null, longitudeString: null, geocodeStatus: { key: "ready" } }) })))))),
                    dispatchGroupsStatus.key == "error" ? (React.createElement(Message, { negative: true, header: i18n.t("jobs.createJob.failedToLoadDispatchGroupsHeader"), content: dispatchGroupsStatus.message })) : null,
                    actionPanel)),
            React.createElement(Modal.Actions, null,
                React.createElement(Button, { content: i18n.t("general.close"), onClick: this.props.onClose }))));
    }
}
DispatchJobModal.geocodeDebouceTime = 3 * 1000;
