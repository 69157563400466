import { ParseUtils } from "@bryxinc/lunch";
export class Address {
    constructor(original, street, municipality, state, postalCode, locationInfo) {
        this.original = original;
        this.street = street;
        this.municipality = municipality;
        this.state = state;
        this.postalCode = postalCode;
        this.locationInfo = locationInfo;
    }
    get originalWithLocationInfo() {
        let combinedString = this.original;
        if (this.locationInfo != null) {
            combinedString += ` - ${this.locationInfo}`;
        }
        return combinedString;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new Address(ParseUtils.getString(o, "original"), ParseUtils.getString(o, "street"), ParseUtils.getStringOrNull(o, "municipality"), ParseUtils.getStringOrNull(o, "state"), ParseUtils.getStringOrNull(o, "postalCode"), ParseUtils.getStringOrNull(o, "locationInfo")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Address: ${e.message}`);
        }
    }
}
