import { ParseUtils } from "@bryxinc/lunch";
import { ConsoleLogger, DefaultFormatter, LocalStorageLogger, NullLogger } from 'localstorage-logger';
function initConfig() {
    let serverType;
    let configType;
    let logConfig;
    switch (process.env.NODE_ENV) {
        case "debug":
            console.log("Debug config loaded");
            logConfig = {
                logName: 'bryx-dev-log',
                maxLogSizeInBytes: 500 * 1024, // 500KB
            };
            serverType = "dev";
            configType = "debug";
            break;
        case "dev":
            console.log("Dev config loaded");
            logConfig = {
                logName: 'bryx-dev-log',
                maxLogSizeInBytes: 500 * 1024, // 500KB
            };
            serverType = "dev";
            configType = "dev";
            break;
        case "k8s":
            console.log("K8s config loaded");
            logConfig = {
                logName: 'bryx-dev-log',
                maxLogSizeInBytes: 500 * 1024, // 500KB
            };
            serverType = "k8s";
            configType = "dev";
            break;
        case "staging":
            logConfig = {
                logName: 'bryx-staging-log',
                maxLogSizeInBytes: 500 * 1024, // 500KB
            };
            serverType = "staging";
            configType = "prod";
            break;
        case "production":
            logConfig = {
                logName: 'bryx-prod-log',
                maxLogSizeInBytes: 500 * 1024, // 500KB
            };
            serverType = "prod";
            configType = "prod";
            break;
        default:
            throw new Error(`Bad deployment: ${process.env.NODE_ENV} -- No config for this deployment`);
    }
    const formatter = new DefaultFormatter();
    const chainTerminal = new NullLogger();
    // Don't include console logger if config is prod
    const consoleLogChain = configType != "prod" ? new ConsoleLogger(formatter, chainTerminal) : chainTerminal;
    const localStorageLogChain = new LocalStorageLogger(logConfig, consoleLogChain);
    const write = (level, args) => {
        if (configType == "prod" && level <= 1) {
            // Don't store debug messages on prod
            return;
        }
        const time = new Date().toISOString();
        const jsonMessage = JSON.stringify(args);
        const jsonMessageWithoutBrackets = jsonMessage.slice(1, jsonMessage.length - 1);
        localStorageLogChain.log({
            level, time, message: jsonMessageWithoutBrackets,
        });
    };
    const logger = {
        debug(...args) { write(1, args); },
        info(...args) { write(2, args); },
        warn(...args) { write(3, args); },
        error(...args) { write(4, args); },
        exportToArray() { return localStorageLogChain.allEntries().map(entry => formatter.format(entry)); },
    };
    ParseUtils.onWarn = m => logger.error(m);
    return {
        baseUrl: process.env.BASE_URL || '',
        configType: configType,
        serverType: serverType,
        version: VERSION,
        log: logger,
        debug: logger.debug,
        info: logger.info,
        warn: logger.warn,
        error: logger.error,
    };
}
export let config = initConfig();
