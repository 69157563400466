import { ParseUtils } from "@bryxinc/lunch";
export var SupplementalSortOrder;
(function (SupplementalSortOrder) {
    SupplementalSortOrder[SupplementalSortOrder["ascending"] = 0] = "ascending";
    SupplementalSortOrder[SupplementalSortOrder["descending"] = 1] = "descending";
})(SupplementalSortOrder || (SupplementalSortOrder = {}));
export class Supplemental {
    constructor(id, creationTime, text) {
        this.id = id;
        this.creationTime = creationTime;
        this.text = text;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new Supplemental(ParseUtils.getString(o, "id"), ParseUtils.getUNIXTimestampDate(o, "ts"), ParseUtils.getString(o, "text")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Supplemental Model: ${e.message}`);
        }
    }
    static compare(s1, s2) {
        return s1.creationTime.getTime() - s2.creationTime.getTime() || s1.id.localeCompare(s2.id);
    }
    isEqual(supplemental) {
        return this.id == supplemental.id &&
            this.creationTime.getTime() == supplemental.creationTime.getTime() &&
            this.text == supplemental.text;
    }
}
