import { ParseUtils } from "@bryxinc/lunch";
export class Complainant {
    constructor(name, phoneNumber, address) {
        this.name = name;
        this.phoneNumber = phoneNumber;
        this.address = address;
    }
    static parse(o) {
        try {
            return ParseUtils.parseSuccess(new Complainant(ParseUtils.getStringOrNull(o, "name"), ParseUtils.getStringOrNull(o, "phoneNumber"), ParseUtils.getStringOrNull(o, "address")));
        }
        catch (e) {
            return ParseUtils.parseFailure(`Invalid Complainant Model: ${e.message}`);
        }
    }
}
