import { ParseUtils } from "@bryxinc/lunch";
import { apiFailure, apiSuccess } from "../utils/bryxApi";
import { ListJob } from "./job";
function parseJobsListServerUpdate(o) {
    try {
        const type = ParseUtils.getString(o, "type");
        const id = ParseUtils.getString(o, "id");
        switch (type) {
            case "new":
                return ParseUtils.parseSuccess({
                    key: "new",
                    id: id,
                    job: ParseUtils.getSubobject(o, "job", ListJob.parse),
                });
            case "old":
                return ParseUtils.parseSuccess({
                    key: "old",
                    id: id,
                    jobId: ParseUtils.getString(o, "jobId"),
                });
            case "closed":
                return ParseUtils.parseSuccess({
                    key: "closed",
                    id: id,
                    job: ParseUtils.getSubobject(o, "job", ListJob.parse),
                });
            case "hasResponded":
                return ParseUtils.parseSuccess({
                    key: "hasResponded",
                    id: id,
                    jobId: ParseUtils.getString(o, "jobId"),
                });
            case "assignments":
                return ParseUtils.parseSuccess({
                    key: "assignments",
                    id: id,
                    jobId: ParseUtils.getString(o, "jobId"),
                    unitShortNames: ParseUtils.getArray(o, "unitShortNames"),
                });
            default:
                return ParseUtils.parseFailure(`Invalid JobsListServerUpdate type: ${type}`);
        }
    }
    catch (e) {
        return ParseUtils.parseFailure(`Invalid JobsListServerUpdate Model: ${e.message}`);
    }
}
export function parseJobsListUpdate(message) {
    try {
        switch (message.key) {
            case "serverUpdate":
                return apiSuccess({
                    key: "update",
                    update: ParseUtils.getSubobject({ data: message.data }, "data", parseJobsListServerUpdate),
                });
            case "subscribeResponse":
                if (message.successful == false) {
                    return apiFailure(message.errorReason, message.errorReason);
                }
                const type = ParseUtils.getString(message.initialData, "type");
                switch (type) {
                    case "replace":
                        return apiSuccess({
                            key: "replace",
                            openJobs: ParseUtils.getArrayOfSubobjects(message.initialData, "open", ListJob.parse, "warn"),
                            closedJobs: ParseUtils.getArrayOfSubobjects(message.initialData, "closed", ListJob.parse, "warn"),
                        });
                    case "fastForward":
                        return apiSuccess({
                            key: "fastForward",
                            updates: ParseUtils.getArrayOfSubobjects(message.initialData, "updates", parseJobsListServerUpdate, "warn"),
                        });
                    default:
                        return apiFailure(null, `Invalid JobsListUpdate subscribe response type: ${type}`);
                }
            default:
                return apiSuccess(null);
        }
    }
    catch (e) {
        return apiFailure(null, `Invalid JobsListUpdate Model: ${e.message}`);
    }
}
