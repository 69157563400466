import * as i18n from "i18next";
import * as React from "react";
import { Card, Icon } from "semantic-ui-react";
import { Button, ButtonGroup, Dropdown } from "semantic-ui-react";
import { ConfirmationModal } from "../../components/confirmationModal";
import { SCUUtils } from "../../models/scu";
import { BryxApi } from "../../utils/bryxApi";
import { DateUtils } from "../../utils/dateUtils";
import { NotificationUtils, safelyUnescapeTTS } from "../../utils/functions";
export class SCUCard extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            confirmModalOpen: false,
        };
    }
    render() {
        const { scu, now, onClick, onDispatchClick, showDispatch } = this.props;
        let mainIcon;
        let text;
        if (scu.status.type == "offline") {
            mainIcon = { name: "warning circle", color: "red" };
            text = i18n.t("stations.stationsList.offline");
        }
        else {
            mainIcon = { name: "check circle", color: "green" };
            text = i18n.t("stations.stationsList.online");
        }
        let subline;
        let subtext = null;
        switch (scu.status.type) {
            case "idle":
                subline = {
                    icon: { name: "check circle", color: "green" },
                    text: i18n.t("stations.stationsList.idle"),
                };
                break;
            case "audio":
                subline = {
                    icon: { name: "volume up", color: "orange" },
                    text: i18n.t("stations.stationsList.alertingAudioWithTone", { tone: scu.status.tone }),
                };
                subtext = safelyUnescapeTTS(scu.status.tts.map(t => t.toUpperCase()).join(" • "));
                break;
            case "turnout":
                subline = {
                    icon: { name: "clock", color: "yellow" },
                    text: i18n.t("stations.stationsList.turningOut"),
                };
                subtext = scu.status.jobs.length == 1 ? SCUUtils.getJobString(scu.status.jobs[0]) : i18n.t("stations.stationsList.xJobs", { count: scu.status.jobs.length });
                break;
            case "offline":
                subline = null;
                subtext = scu.status.lastUpdatedTs != null
                    ? i18n.t("stations.stationsList.lastContact", { duration: DateUtils.duration(scu.status.lastUpdatedTs, now).humanize(true) })
                    : i18n.t("stations.stationsList.lastContactUnknown");
                break;
            default:
                return null;
        }
        let cardClass = undefined;
        if (scu.status.type == "audio") {
            cardClass = "alerting-audio";
        }
        else if (scu.status.type == "turnout") {
            cardClass = "alerting-turnout";
        }
        const actionDispatchLine = scu.status.type != "offline" && (showDispatch || scu.hasImplicitAlert() || scu.hasActions());
        let scuAlerts = undefined;
        if (scu.hasActions()) {
            scuAlerts = (React.createElement(Dropdown, { trigger: (React.createElement(Button, { basic: true, className: showDispatch ? "dispatchActionButton" : "noDispatchActionButton", inverted: true },
                    React.createElement(React.Fragment, null,
                        !showDispatch ? i18n.t('nav.actions') : null,
                        React.createElement(Icon, { name: "dropdown", style: !showDispatch ? { paddingLeft: "1em" } : { paddingRight: "1em" } })))), style: showDispatch ? undefined : { width: "100%" } },
                React.createElement(Dropdown.Menu, null, scu.actions.sort((a1, a2) => a1.order - a2.order).map(action => (React.createElement(Dropdown.Item, { key: action.id, className: "dropdown-item-bordered", text: action.name, icon: action.icon, description: action.description, onClick: () => {
                        BryxApi.triggerAgencyAction(action.agencyId, action.id, (result) => {
                            if (!result.success) {
                                console.error(`Unable to trigger agency action: ${result.message}`);
                                NotificationUtils.notify({
                                    title: i18n.t("stations.specific.actions.errorPerforming"),
                                    message: i18n.t("stations.specific.actions.errorPerformingDesc"),
                                    level: "error",
                                    autoDismiss: 20,
                                });
                            }
                            else {
                                NotificationUtils.notify({
                                    title: i18n.t("stations.specific.actions.performed"),
                                    message: i18n.t("stations.specific.actions.performedDesc", { scu: scu.name, action: action.name }),
                                    level: "success",
                                    autoDismiss: 10,
                                });
                            }
                        });
                    } }))))));
        }
        return (React.createElement(Card, { className: cardClass, onClick: () => { } },
            React.createElement(Card.Content, { onClick: onClick },
                React.createElement(Card.Header, null, scu.name),
                React.createElement(Card.Description, null,
                    React.createElement("div", null,
                        React.createElement(Icon, { ...mainIcon, size: "big" }),
                        text),
                    subline != null ? (React.createElement("div", { style: { marginTop: "3px" } },
                        subline.icon != null ? (React.createElement(Icon, { ...subline.icon, size: "big" })) : null,
                        subline.text)) : null,
                    React.createElement("div", { style: { marginTop: "5px", color: "#8a8a8a" } }, subtext)),
                actionDispatchLine ? (React.createElement("hr", { style: { marginBottom: "-8px" } })) : null),
            actionDispatchLine ? (React.createElement(Card.Content, { extra: true, onClick: (e) => e.preventDefault() }, scu.hasImplicitAlert() && showDispatch ? (React.createElement(ButtonGroup, { style: { width: "100%" } },
                React.createElement(Button, { basic: true, inverted: true, color: 'red', onClick: () => {
                        this.setState({ confirmModalOpen: true });
                    } }, i18n.t("stations.specific.alerts.alertAll")),
                React.createElement(Button, { basic: true, inverted: true, color: 'yellow', onClick: onDispatchClick },
                    i18n.t("general.dispatch"),
                    "..."),
                scuAlerts))
                : (showDispatch ? (React.createElement(ButtonGroup, { style: { width: "100%" } },
                    React.createElement(Button, { basic: true, inverted: true, color: 'yellow', onClick: onDispatchClick }, i18n.t("general.dispatch")),
                    scuAlerts)) : (scuAlerts != undefined ? (React.createElement(ButtonGroup, { style: { width: "100%" } }, scuAlerts)) : null)))) : null,
            React.createElement(ConfirmationModal, { open: this.state.confirmModalOpen, title: i18n.t("stations.specific.alerts.confirmModal.title"), body: i18n.t("stations.specific.alerts.confirmModal.body"), onConfirm: () => {
                    BryxApi.scuAlertAll(scu.id, (result) => {
                        if (!result.success) {
                            console.error(`Unable to trigger scu alert all: ${result.message}`);
                            NotificationUtils.notify({
                                title: i18n.t("stations.specific.alerts.unableToAlert"),
                                message: i18n.t("stations.specific.alerts.unableToAlertDesc"),
                                level: "error",
                                autoDismiss: 20,
                            });
                        }
                        // No need for positive notification, they will see the SCU change state
                    });
                    this.setState({ confirmModalOpen: false });
                }, onCancel: () => this.setState({ confirmModalOpen: false }) })));
    }
}
